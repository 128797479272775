import Vue from "vue";
import filesize from "filesize";
import { registerComponents } from "utils/register-components";

/**
 * Global Components from Local Folder
 */

import ShouldDisplayOnStoreAlert from "components/src/should-display-on-store-alert.vue";

const components = [
  { History },
  { Date },
  { File },
  { Request },
  { ShouldDisplayOnStoreAlert },
];
registerComponents(Vue, components);

/**
 * Filters
 * TODO -> check if needed globally and used anywhere
 */
Vue.filter("filesize", (value) => {
  if (!value) return "";
  return filesize(value);
});

Vue.filter("number", (value) => {
  if (!value) value = 0;
  return new Intl.NumberFormat(
    window.navigator.userLanguage || window.navigator.language,
    {
      minimumFractionDigits: 2,
    }
  ).format(value.toFixed(2));
});

Vue.prototype.$fbp = (name, payload) => {
  window.fbq?.("track", name, payload);
};
