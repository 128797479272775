<template>
  <contact-list
    type="all"
    :request-payload="{ query: $options.contacts }"
    endpoint="contacts"
    export-endpoint="contact"
    export-title="Contacts"
    exportFeatureKey="CONTACT_EXPORT"
    :feature="feature"
  />
</template>

<script>
import ContactList from "./_list.vue";
import contacts from "graph/contact/collection.gql";

export default {
  contacts,
  props: {
    feature: Object,
  },
  components: {
    ContactList,
  },
};
</script>
