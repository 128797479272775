<template>
  <VpVueForm
    :fields="fields"
    :id="campaignId"
    class="vp-p-6"
    :save="save"
    :get="get"
    width="400px"
    ref="item"
    :track="$options.track"
    cache="campaign"
    track-prefix="Biz Profile"
    feature="CAMPAIGN"
  >
    <template #default="{ state }">
      <VpField rules="required" label="Business Name" name="Business Name">
        <VpInput>
          <VpTextBox v-model="state.name" />
        </VpInput>
      </VpField>

      <VpField rules="required" name="Country" label="Country">
        <VpSelectRequest
          :request="$options.countryOptions"
          v-model="state.countryId"
        />
      </VpField>
    </template>
  </VpVueForm>
</template>

<script>
import { countryOptions } from "utils/presets";
import { mapGetters } from "vuex";

import get from "@/graph/biz/campaign/get.gql";
import upsert from "@/graph/biz/campaign/upsert.gql";

export default {
  countryOptions,

  track: {
    name: "Name",
    desc: "Description",
    language: "Language",
    address: "Address",
    country: {
      key: "Country",
      value: (country) => country?.name ?? "",
    },
  },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      profilePicture: null,
      cover: null,
      fields: ["name", "countryId"],
    };
  },
  computed: {
    ...mapGetters({
      campaignId: "biz/id",
    }),
  },

  methods: {
    get() {
      return this.$query(get, {
        id: this.campaignId,
      }).then(({ data }) => {
        const { campaign } = data;
        return {
          values: Object.assign({}, campaign, {
            countryId: campaign.country?.id,
          }),
          res: data,
        };
      });
    },

    async save(id, data) {
      data.countryId = Number(data.countryId);
      return this.$mutate(upsert, {
        id: Number(this.campaignId),
        input: data,
      }).then(({ data }) => {
        return data;
      });
    },
  },
};
</script>
