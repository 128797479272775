<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M3 7c-.6 0-1 .4-1 1s.4 1 1 1h2V7H3m-1 4c-.6 0-1 .4-1 1s.4 1 1 1h3v-2H2m-1 4c-.6 0-1 .4-1 1s.4 1 1 1h4v-2H1M20 5H9c-1.1 0-2 .9-2 2v14l4-4h9c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiMessageFast",
};
</script>
