<template>
  <Async :req="req">
    <template #pending>
      <div class="vp-space-y-1">
        <div class="vp-h-5 vp-w-96 vp-bg-shimmer vp-rounded vp-animate-pulse" />
        <div class="vp-h-5 vp-w-64 vp-bg-shimmer vp-rounded vp-animate-pulse" />
        <div class="vp-h-5 vp-w-48 vp-bg-shimmer vp-rounded vp-animate-pulse" />
        <div class="vp-h-5 vp-w-32 vp-bg-shimmer vp-rounded vp-animate-pulse" />
        <div class="vp-h-5 vp-w-64 vp-bg-shimmer vp-rounded vp-animate-pulse" />
      </div>
    </template>
    <template #default="{ res }">
      <CheckboxGroup
        :value="value"
        @input="$emit('input', $event)"
        :options="res"
      />
    </template>
  </Async>
</template>

<script>
import CheckboxGroup from "../form/checkbox-group.vue";
import Async from "../async.vue";
import { FUNNELS_GET } from "./gql";

export default {
  props: {
    value: Array,
  },

  components: {
    CheckboxGroup,
    Async,
  },

  methods: {
    req() {
      return this.$query(FUNNELS_GET).then(({ data }) => {
        return data.funnels.data.map((item) => {
          return { label: item.name, value: item.id, color: item.color };
        });
      });
    },
  },
};
</script>
