<template>
  <VpFeature the-key="WHATSAPP_GROUP" #default="{ feature }">
    <contact-list
      endpoint="whatsAppGroupMembers"
      :request-payload="{ query: $options.whatsAppGroupMembers }"
      :list-params="{ groupId: id }"
      export-endpoint="whatsAppGroupMember"
      cache="whatsAppGroupMembers"
      export-title="WhatsApp Group Members"
      :is-action="false"
      export-feature-key="WHATSAPP_GROUP_EXPORT"
      :feature="feature"
    />
  </VpFeature>
</template>

<script>
import ContactList from "components/src/contacts/_list.vue";
import whatsAppGroupMembers from "@/graph/crm/whatsAppGroupMember/collection.gql";

export default {
  whatsAppGroupMembers,
  props: {
    id: [Number, String],
  },
  components: {
    ContactList,
  },
};
</script>
