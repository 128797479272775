<template>
  <ColorButton
    :label="data.name"
    :color="data.color"
    :icon="isIcon && $options.icons.TicketStatus"
    class="button--sm"
  />
</template>

<script>
import { TicketStatus } from "icons/icons.js";
import ColorButton from "./color-button.vue";

export default {
  components: {
    ColorButton,
  },
  icons: {
    TicketStatus,
  },
  props: {
    data: Object,
    isIcon: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
