<template>
  <Async :req="req" class="vp-flex vp-flex-wrap vp-gap-1">
    <!-- Shimmers -->
    <template #pending>
      <div
        class="button button--sm button--rounded vp-bg-shimmer vp-w-24 vp-animate-pulse"
      />
      <div
        class="button button--sm button--rounded vp-bg-shimmer vp-w-32 vp-animate-pulse"
      />
      <div
        class="button button--sm button--rounded vp-bg-shimmer vp-w-16 vp-animate-pulse"
      />
      <div
        class="button button--sm button--rounded vp-bg-shimmer vp-w-12 vp-animate-pulse"
      />
      <div
        class="button button--sm button--rounded vp-bg-shimmer vp-w-32 vp-animate-pulse"
      />
      <div
        class="button button--sm button--rounded vp-bg-shimmer vp-w-24 vp-animate-pulse"
      />
    </template>

    <template #default="{ res }">
      <template v-if="res.length">
        <VyButton
          v-for="(tag, index) in res"
          :key="`${tag.id}-${index}`"
          type="button"
          class="button--sm button--rounded"
          :class="[colorStyle(tag)]"
          :label="tag.name"
          @click.native="onChange(tag)"
        />
      </template>

      <div class="vp-p-4 vp-border vp-rounded-md vp-w-full" v-else>
        <p class="vp-text-xs vp-text-gray-500 vp-mb-1">
          No tags found. Want to add one?
        </p>
        <VyButton
          type="button"
          label="Add Tag"
          class="button--gray button--muted button--sm button--rounded"
          :icon="$options.icons.Add"
          @click.native="$emit('onAddNew')"
        />
      </div>
    </template>
  </Async>
</template>

<script>
import { Lock } from "icons/icons.js";
import Async from "../async.vue";
import colorClass from "static/src/button-color-classes.json";
import mutedColorClass from "static/src/muted-button-color-classes.json";
import { TAGS_GET, CONTACT_TAGS_UPSERT, CONTACT_TAGS_GET } from "./gql";

export default {
  icons: {
    Lock,
  },

  colorClass,

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    type: {
      required: true,
    },
    contactId: [Number, String],
  },

  components: {
    Async,
  },

  created() {
    if (this.contactId) {
      this.getContactValue();
    }
  },

  computed: {
    localValue() {
      return this.value || [];
    },

    /**
     * TODO: Remove this types and only use contact,message & media;
     */
    tagTypeId() {
      switch (this.type) {
        case "contact":
          return 1;
        case "chat":
        case "text":
          return 2;
        case "image":
        case "audio":
        case "video":
        case "pdf":
        case "ptt":
        case "document":
          return 3;
        default:
          return null;
      }
    },
  },

  methods: {
    req() {
      return this.$query(TAGS_GET, {
        filters: {
          status: "active",
          type: this.tagTypeId,
        },
      }).then(({ data }) => data.tags.data);
    },

    onChange(tag) {
      const value = [...this.localValue];
      if (value.includes(tag.id)) {
        var index = value.indexOf(tag.id);
        value.splice(index, 1);
        this.$emit("remove", tag.id);
      } else {
        value.push(tag.id);
        this.$emit("add", tag.id);
      }
      this.$emit("input", value);

      if (this.contactId) {
        this.saveContactValue(value);
      }
    },

    colorStyle(tag) {
      const { color, id } = tag;
      if (this.localValue.includes(id)) {
        return colorClass[color];
      } else {
        return mutedColorClass.grey;
      }
    },

    getContactValue() {
      return this.$query(CONTACT_TAGS_GET, {
        contactId: Number(this.contactId),
      }).then(({ data: { contact } }) => {
        if (contact.tags.length) {
          this.$emit(
            "input",
            contact.tags.map((item) => item.id)
          );
        }
      });
    },

    saveContactValue(value) {
      return this.$mutate(CONTACT_TAGS_UPSERT, {
        contactId: Number(this.contactId),
        input: {
          tagIds: value,
        },
      });
    },
  },
};
</script>
