<template>
  <Loader v-if="loading" />

  <!-- Hydrated -->
  <AppLayout v-else>
    <main class="vp-overflow-auto">
      <div class="vp-flex vp-h-full vp-flex-col">
        <EmailNotVerified />
        <AccountDowngraded />
        <!-- Header -->
        <div class="vp-flex vp-items-stretch vp-border-b vp-relative vp-z-20">
          <div class="vp-flex vp-items-center">
            <SwitchBiz />
            <Breadcrumbs class="vp-flex-grow" />
          </div>
          <div class="vp-ml-auto vp-flex vp-items-center vp-px-2">
            <PortalTarget name="header" slim />
            <TheNotification
              :is-notification="unreadNotificationCount ? true : false"
            />
          </div>
        </div>
        <!-- Content -->

        <!-- Handle scroll with top heading banner -->
        <div class="vp-flex-grow vp-overflow-auto">
          <VpSubShell>
            <template #nav>
              <VpSubNavItem
                label="Dashboard"
                :icon="$options.icons.Dashboard"
                :to="{ name: 'home' }"
                exact
              />
              <!-- <VpSubNavItem
                label="Dashboard"
                :icon="$options.icons.Dashboard"
                :to="{ name: 'dashboard' }"
                exact
              /> -->
              <VpSubNavItem
                label="Tickets"
                :icon="$options.icons.Ticket"
                :to="{ name: 'tickets' }"
              />
              <VpSubNavItem
                :icon="$options.icons.Reminder"
                :to="{ name: 'reminders' }"
                label="Reminders"
              />

              <!-- CONNECTIONS -->
              <VpNavGroup>Connections</VpNavGroup>
              <VpSubNavItem
                label="Contacts"
                :icon="$options.icons.ContactBook"
                :to="{ name: 'contacts' }"
              />
              <VpSubNavItem
                label="WhatsApp Groups"
                :icon="$options.icons.Group"
                :to="{ name: 'whatsapp-groups' }"
              />

              <MarketingNavGroup />

              <!-- SAVED -->
              <VpNavGroup>Saved</VpNavGroup>
              <VpSubNavItem
                label="Messages"
                :icon="$options.icons.SavedMessage"
                :to="{ name: 'messages' }"
              />
              <VpSubNavItem
                label="Chats"
                :icon="$options.icons.Chat"
                :to="{ name: 'chats' }"
              />

              <!-- SETTINGS -->
              <VpNavGroup>More</VpNavGroup>

              <VpSubNavItem
                :icon="$options.icons.Poll"
                :to="{ name: 'polls' }"
                label="Polls"
              />
              <VpSubNavItem
                label="Chrome Extension"
                :icon="$options.icons.Chrome"
                :to="{ name: 'chrome-extension' }"
              />
              <VpSubNavItem
                label="Settings"
                :icon="$options.icons.Settings"
                :to="{ name: 'settings' }"
              />
              <VpSubNavItem
                label="Export History"
                :icon="$options.icons.History"
                :to="{ name: 'exports' }"
              />

              <!-- <VpSubNavItem
              label="Custom Fields"
              :to="{ name: 'crm-custom-fields' }"
            /> -->
            </template>
          </VpSubShell>
        </div>
      </div>
      <FrillWidget />
    </main>
  </AppLayout>
</template>

<script>
import AppLayout from "@/components/app-layout.vue";

import {
  Menu,
  Dashboard,
  SavedMessage,
  Chat,
  QuickReply,
  Funnel,
  Tiers,
  Tag,
  TicketStatus,
  Chrome,
  Ticket,
  Reminder,
  ContactBook,
  Settings,
  Group,
  History,
  Business,
  Integration,
  Poll,
} from "icons/icons.js";
import { init as initSegment } from "plugins/segment";
import { init as initSplit } from "plugins/split";
import { getToken, getCampaign, setCampaign } from "plugins/utils";
import { mapActions, mapGetters } from "vuex";
import Breadcrumbs from "components/src/app/breadcrumbs.vue";
import hydrateQuery from "./hydrate.gql";
import unreadCount from "components/src/notifications/unread-count.gql";
import campaigns from "graph/user/campaigns.gql";
import { sendMessageToExt } from "@/utils";

import AccountDowngraded from "components/src/account/downgraded.vue";
import EmailNotVerified from "components/src/email/not-verified.vue";
import Loader from "components/src/app/loader.vue";
import MarketingNavGroup from "../crm/marketing/_nav-group.vue";

import TheNotification from "components/src/notifications/header-button.vue";
import SwitchBiz from "./_switch-biz.vue";
import FrillWidget from "components/src/frill-widget.vue";
import { toWeb } from "plugins/utils.js";
import { version } from "../../../package.json";

export default {
  icons: {
    Business,
    Integration,
    Menu,
    Dashboard,
    SavedMessage,
    Poll,
    Chat,
    QuickReply,
    Chrome,
    History,
    Funnel,
    Tiers,
    Tag,
    TicketStatus,
    Settings,
    Ticket,
    Reminder,
    ContactBook,
    Group,
  },
  components: {
    FrillWidget,
    Loader,
    SwitchBiz,
    EmailNotVerified,
    AccountDowngraded,
    Breadcrumbs,
    MarketingNavGroup,
    TheNotification,
    AppLayout,
  },
  data() {
    return {
      loading: true,
      campaignId: this.$router.app._route.params.campaignId || getCampaign(),
      windowWidth: window.innerWidth,
      unreadNotificationCount: 0,
    };
  },
  async mounted() {
    /**
     * Check for token
     * Set token in localStorage before sending the hydration call
     */
    const token = getToken();
    if (!token) {
      toWeb("login");
      return;
    }

    /**
     * Check for default campaign ID
     * If not found then get all the campaigns and select 1st one.
     */
    if (!this.campaignId) {
      this.campaignId = await this.getDefaultCampaignId();
    }
    if (this.$router.app._route.params.campaignId) {
      setCampaign(this.campaignId);
    }

    this.startHydration(false);

    this.$root.$on("rehydrate", (campaignId, web, serviceWorker) => {
      if (campaignId) {
        this.campaignId = campaignId;
      } else {
        this.campaignId = this.activeCampaignId;
      }
      setCampaign(this.campaignId);
      this.rehydrate(web, serviceWorker);
    });
  },

  computed: {
    ...mapGetters(["analytics"]),
    ...mapGetters({
      trackingData: "user/trackingData",
      activeCampaignId: "biz/id",
      getStorage: "storage/get",
      isSubscriptionActive: "subscription/isActive",
    }),
  },

  methods: {
    ...mapActions(["hydrate"]),

    startHydration(serviceWorker) {
      if (!serviceWorker) {
        this.loading = true;
      }

      this.hydrate({
        request: this.$query(
          hydrateQuery,
          {
            campaignId: parseInt(this.campaignId),
            userId: parseInt(this.$router.app._route.params.userId),
          },
          {
            // Cache refresh is required here for crm setup create account flow, redirection back to this route
            noCache: true,
          }
        ),
        version: version,
      })
        .then((res) => {
          if (!res.campaign.vepaarProducts.crm)
            this.$router.push({ name: "get-started" });
          this.loading = false;
          this.updateNotificationCount(res.user.id);

          // this.$nextTick(() => {
          //   this.$vayu.modal.open("crm-setup-modal");
          // });

          // this.$vayu.modal.open("pro-feature-modal", {
          //   featureName: "CONTACT",
          // });

          this.vendorPlugins();

          setTimeout(() => {
            const query = Object.assign({}, this.$route.query);
            const isSignup = query.signup;
            const isLogin = query.login;

            if (isSignup) {
              this.$track("User Signup");
              delete query.signup;
              this.$router.replace({ query }).catch();
            } else if (isLogin) {
              this.$track("Login");
              delete query.login;
              this.$router.replace({ query }).catch();
            }

            this.$track("Open");
          }, 2000);
        })
        .catch((err) => {
          this.loading = false;
          console.error(err);

          this.$vayu.notify({
            state: "danger",
            title: "Failed to Login!",
            message:
              "Can not fetch user data. Please login again. If issue persist, kindly contact us.",
            duration: 10000,
          });
          toWeb("logout");
        });
    },

    rehydrate(web, serviceWorker = true) {
      /**
       * Need to register prototypes in side created hook to have access to component instance.
       * We're connecting with the Chrome Extension
       * When any change is done in admin panel which needs to be updated in extension too,
       * we sent a rehydrate request to extension service worker script.
       *
       * https://developer.chrome.com/docs/extensions/mv2/messaging/
       */
      sendMessageToExt("rehydrate");

      //Rehydrate web platform only if required
      if (web) {
        this.$cache.evict({
          id: "ROOT_QUERY",
        });
        this.startHydration(serviceWorker);
      }
    },

    async getDefaultCampaignId() {
      return this.$query(campaigns, {})
        .then(({ data }) => {
          const defaultCampaign = data.campaigns.data[0]?.id;
          setCampaign(defaultCampaign);
          return defaultCampaign;
        })
        .catch((err) => {
          console.error(err);
          this.$router.push({ name: "logout" });
        });
    },

    updateNotificationCount(userId) {
      this.$query(unreadCount, {
        userId,
        filters: {
          readAt: false,
          product: "crm",
        },
      }).then(({ data }) => {
        this.unreadNotificationCount = data.notifications.pagination.total;
        this.$root.$emit(
          "NOTIFICATIONS:SET",
          data.notifications.pagination.total
        );
      });
    },

    vendorPlugins() {
      try {
        initSplit(this.analytics.userId);
        if (this.isSubscriptionActive) {
          // Segment has monthly tracked users limit.therefore sending data of paid users only. learn more at https://segment.com/pricing/
          initSegment(this.analytics);
        }
        this.initClarity();
        this.initOneSignal();
        this.initProfitWell();
      } catch (err) {
        console.error(err);
      }
    },

    initProfitWell() {
      if (window.profitwell) {
        window.profitwell("start", { user_email: this.analytics.email });
      } else {
        console.info("Profit Well is not enabled");
      }
    },

    initOneSignal() {
      if (import.meta.env.VITE_ONESIGNAL !== "true") return;

      this.$OneSignal
        .init({
          appId: import.meta.env.VITE_ONESIGNAL_APP_ID,
          safari_web_id: import.meta.env.VITE_ONESIGNAL_SAFARI_WEB_ID,
          allowLocalhostAsSecureOrigin: true,
          notifyButton: {
            enable: true,
            size: "small",
            position: "bottom-left",
            offset: {
              bottom: "5px",
              left: "5px",
            },
          },
          showCredit: true /* Hide the OneSignal logo */,
          displayPredicate() {
            return this.$OneSignal
              .isPushNotificationsEnabled()
              .then((isPushEnabled) => {
                return !isPushEnabled;
              });
          },
        })
        .then(() => {
          this.$OneSignal.isPushNotificationsEnabled().then((isPushEnabled) => {
            if (!isPushEnabled) {
              this.$OneSignal.showNativePrompt();
            }
          });

          //As per documentation here: https://documentation.onesignal.com/docs/identity-verification
          this.$OneSignal.setEmail(this.analytics.email, {
            emailAuthHash: this.analytics.emailAuthHash,
          });
          this.$OneSignal.setExternalUserId(
            this.analytics.userId.toString(),
            this.analytics.userIdAuthHash
          );
        })
        .catch((err) => {
          console.error(err);
        });
    },

    initClarity() {
      if (import.meta.env.VITE_CLARITY !== "true") return;
      if (window.clarity) {
        window.clarity("set", "user_uuid", this.analytics.uuid);
      }
    },
  },
};
</script>
