<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    class="vp-p-6"
    :save="save"
    :get="get"
    :del="del"
    :archive="del"
    width="400px"
    collection-cache="funnels"
    :track="$options.track"
    track-prefix="Funnel"
    feature="FUNNEL"
  >
    <template #default="{ state, response }">
      <VpShouldDisplayOnStoreAlert
        v-if="response && !response.shouldDisplayOnStore"
        title="Funnel not accessible!"
        desc="This funnel is not accessible on extension."
      />
      <VpField rules="required" name="Name" label="Name">
        <VpInput>
          <VpTextBox v-model="state.name" />
        </VpInput>
      </VpField>

      <VpField rules="required" name="color" label="Color">
        <ColorPicker v-model="state.color" />
      </VpField>
    </template>
  </VpVueForm>
</template>

<script>
import ColorPicker from "components/src/color/picker.vue";
import get from "./get.gql";
import move from "./move.gql";
import upsert from "./upsert.gql";
import { clearExtCache } from "@/utils";

export default {
  track: {
    id: "Id",
    name: "Name",
    color: "Color",
  },
  props: {
    id: [Number, String],
    feature: Object,
  },
  components: {
    ColorPicker,
  },
  data() {
    return {
      fields: ["name", { name: "color", value: "grey" }],
    };
  },
  methods: {
    get(id) {
      return this.$query(get, {
        id: id,
      }).then(({ data }) => {
        return {
          values: data.funnel,
          res: data,
        };
      });
    },

    save(id, data) {
      const inputs = {
        input: data,
      };
      if (id) inputs.id = id;
      return this.$mutate(upsert, inputs).then(() => {
        this.postCud();
      });
    },

    del(id, action) {
      return this.$mutate(move, {
        id: parseInt(id),
        action,
      }).then(() => {
        this.postCud();
      });
    },

    postCud() {
      clearExtCache({
        key: this.feature.key,
      });
      this.$router.push({ name: "funnels" });
    },
  },
};
</script>
