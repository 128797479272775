import gql from "graphql-tag";

export const ODOO_INTEGRATION = gql`
  fragment odooIntegration on Odoo {
    id
    status
    user {
      database
      username
      apiKey
    }
  }
`;

export const ODOO_GET = gql`
  query getOdooIntegration {
    integration {
      odoo {
        ...odooIntegration
      }
    }
  }
  ${ODOO_INTEGRATION}
`;

export const ODOO_UPSERT = gql`
  mutation upsertOdooIntegration($input: UpdateOdooUserInput!) {
    updateOdooUser(input: $input) {
      ...odooIntegration
    }
  }
  ${ODOO_INTEGRATION}
`;
