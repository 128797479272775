<template>
  <div
    class="vp-overflow-hidden vp-w-full vp-h-2 vp-text-xs vp-flex vp-rounded vp-bg-gray-50"
    v-tooltip="`${progress}%`"
  >
    <div
      :style="`width: ${progress}%`"
      class="vp-shadow-none vp-flex vp-flex-col vp-text-center vp-rounded vp-whitespace-nowrap vp-text-white vp-justify-center vp-bg-primary-500"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    progress: Number,
  },
};
</script>
