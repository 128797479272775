import Text from "@/views/crm/messages/types/Text.vue";
import Images from "@/views/crm/messages/types/Images.vue";
import Videos from "@/views/crm/messages/types/Videos.vue";
import Audio from "@/views/crm/messages/types/Audio.vue";
import Documents from "@/views/crm/messages/types/Documents.vue";

export default [
  {
    path: "",
    name: "messages",
    redirect: "text",
    meta: {
      label: "Messages",
      breadcrumbs: ["home", "messages"],
    },
  },
  {
    path: "text",
    name: "text",
    component: Text,
    props: true,
    meta: {
      label: "Text",
      breadcrumbs: ["home", "messages", "text"],
    },
  },
  {
    path: "images",
    name: "images",
    component: Images,
    props: true,
    meta: {
      label: "Images",
      breadcrumbs: ["home", "messages", "images"],
    },
  },
  {
    path: "videos",
    name: "videos",
    component: Videos,
    props: true,
    meta: {
      label: "Videos",
      breadcrumbs: ["home", "messages", "videos"],
    },
  },
  {
    path: "audio",
    name: "audio",
    component: Audio,
    props: true,
    meta: {
      label: "Audio",
      breadcrumbs: ["home", "messages", "audio"],
    },
  },
  {
    path: "documents",
    name: "documents",
    component: Documents,
    props: true,
    meta: {
      label: "Documents",
      breadcrumbs: ["home", "messages", "documents"],
    },
  },
];
