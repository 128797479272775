<template>
  <Async :req="req">
    <template #pending>
      <div class="vp-h-8 vp-rounded vp-bg-shimmer vp-animate-pulse"></div>
    </template>
    <template #default="{ res }">
      <VpDropdown
        placeholder="Select Status"
        :options="res"
        :value="value"
        :unselect="unselect"
        @input="input($event)"
      />
    </template>
  </Async>
</template>

<script>
import get from "./get.gql";
import Async from "../async.vue";

export default {
  props: {
    value: Number,
    unselect: Boolean,
  },
  components: {
    Async,
  },
  methods: {
    req() {
      return this.$query(get).then(({ data }) => {
        return data.ticketStatuses.data.map((item) => {
          return { label: item.name, id: item.id, color: item.color };
        });
      });
    },

    input(value) {
      this.$emit("input", value);
    },
  },
};
</script>
