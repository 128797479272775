<template>
  <VyButton
    class="vp-capitalize button--muted button--sm button--rounded"
    :class="$options.buttonClass[value]"
    :label="label"
  />
</template>

<script>
const buttonClass = {
  pending: "button--warning",
  success: "button--success",
  failed: "button--danger",
};
export default {
  buttonClass,
  props: {
    value: String,
  },
  computed: {
    label() {
      return this.value === "success" ? "delivered" : this.value;
    },
  },
};
</script>
