<template>
  <VpSubShell class="vp-h-full vp-overflow-hidden">
    <template #before-nav>
      <nav-contact :request="get">
        <VpSubNavItem
          label="Profile"
          :icon="$options.icons.Info"
          :to="{ name: 'whatsapp-group' }"
          exact
        />
        <VpSubNavItem
          :icon="$options.icons.DonutChart"
          label="Statistics"
          :to="{ name: 'whatsapp-group-statistics' }"
        />
        <VpSubNavItem
          :icon="$options.icons.Group"
          label="Participants"
          :to="{ name: 'whatsapp-group-participants' }"
        />
      </nav-contact>
    </template>

    <template #default>
      <router-view />
    </template>
  </VpSubShell>
</template>

<script>
import overview from "@/graph/crm/whatsAppGroup/overview.gql";
import NavContact from "components/src/contacts/_Nav.vue";
import { Info, DonutChart, Group } from "icons/icons.js";

export default {
  icons: {
    Info,
    DonutChart,
    Group,
  },
  props: {
    id: [Number, String],
  },
  components: {
    NavContact,
  },
  methods: {
    get() {
      return this.$query(overview, {
        id: this.id,
      })
        .then(({ data }) => data.whatsAppGroup)
        .then((data) => {
          const { name, profilePicture } = data;
          return {
            image: profilePicture?.url,
            title: name,
          };
        });
    },
  },
};
</script>
