<template>
  <div class="vp-flex vp-flex-wrap vp-items-center">
    <div
      class="vp-w-16 vp-h-16 vp-bg-gray-200 vp-rounded-full vp-flex vp-items-center vp-justify-center vp-mr-3"
    >
      <VpIcon :name="icon" class="vp-text-gray-400 vp-w-10 vp-h-10" />
    </div>
    <div>
      <router-link :to="to" class="vp-text-black vp-leading-none title">
        <h3 class="vp-text-2xl vp-font-bold vp-mt-2">{{ title }}</h3>
      </router-link>
      <p class="vp-text-gray-500 vp-leading-none">{{ desc }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    desc: String,
    icon: {},
    to: {
      type: Object,
      default: () => ({
        name: "home",
      }),
    },
  },
};
</script>
