<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2m-5.4 12L12 12.4L9.4 14l.7-3l-2.3-2l3-.3L12 6l1.2 2.8l3 .3l-2.3 2z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiMessageStar",
};
</script>
