<template>
  <VyButton
    class="vp-capitalize button--muted button--pill button--xs"
    :class="buttonClass[value]"
    :label="value"
  />
</template>

<script>
export default {
  props: {
    value: String,
  },
  data() {
    return {
      buttonClass: {
        allow: "button--success",
        disallow: "button--danger",
        override: "button--warning",
      },
    };
  },
};
</script>
