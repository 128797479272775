import List from "@/views/polls/List.vue";
import Item from "@/views/polls/Item.vue";
import Edit from "@/views/polls/Edit.vue";
import Result from "@/views/polls/Result.vue";
import Voters from "@/views/polls/Voters.vue";
import Exports from "@/views/polls/Exports.vue";

export default [
  {
    path: "polls",
    name: "polls",
    component: List,
    meta: {
      whatsapp: false,
      label: "Polls",
      breadcrumbs: ["home", "polls"],
    },
  },
  {
    path: "polls/:id",
    component: Item,
    props: true,
    meta: {
      whatsapp: false,
      label: "Poll",
      breadcrumbs: ["home", "polls", "poll"],
    },
    children: [
      {
        path: "",
        name: "poll",
        component: Edit,
        props: true,
        meta: {
          whatsapp: false,
          label: "Poll",
          breadcrumbs: ["home", "polls", "poll"],
        },
      },
      {
        path: "result",
        name: "poll-result",
        component: Result,
        props: true,
        meta: {
          whatsapp: false,
          label: "Result",
          breadcrumbs: ["home", "polls", "poll", "poll-result"],
        },
      },
      {
        path: "voters",
        name: "poll-voters",
        component: Voters,
        props: true,
        meta: {
          whatsapp: false,
          label: "Voters",
          breadcrumbs: ["home", "polls", "poll", "poll-voters"],
        },
      },
      {
        path: "voters/exports",
        name: "poll-voters-export-history",
        component: Exports,
        meta: {
          label: "Export History",
          breadcrumbs: [
            "home",

            "polls",
            "poll",
            "poll-voters",
            "poll-voters-export-history",
          ],
        },
      },
    ],
  },
];
