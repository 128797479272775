export default [
  {
    label: "Afrikaans",
    value: "af",
  },
  {
    label: "Albanian",
    value: "sq",
  },
  {
    label: "Arabic",
    value: "ar",
  },
  {
    label: "Azerbaijani",
    value: "az",
  },
  {
    label: "Bengali",
    value: "bn",
  },
  {
    label: "Bulgarian",
    value: "bg",
  },
  {
    label: "Catalan",
    value: "ca",
  },
  {
    label: "Chinese (CHN)",
    value: "zh_CN",
  },
  {
    label: "Chinese (HKG)",
    value: "zh_HK",
  },
  {
    label: "Chinese (TAI)",
    value: "zh_TW",
  },
  {
    label: "Croatian",
    value: "hr",
  },
  {
    label: "Czech",
    value: "cs",
  },
  {
    label: "Danish",
    value: "da",
  },
  {
    label: "Dutch",
    value: "nl",
  },
  {
    label: "English",
    value: "en",
  },
  {
    label: "English (UK)",
    value: "en_GB",
  },
  {
    label: "English (US)",
    value: "en_US",
  },
  {
    label: "Estonian",
    value: "et",
  },
  {
    label: "Filipino",
    value: "fil",
  },
  {
    label: "Finnish",
    value: "fi",
  },
  {
    label: "French",
    value: "fr",
  },
  {
    label: "Georgian",
    value: "ka",
  },
  {
    label: "German",
    value: "de",
  },
  {
    label: "Greek",
    value: "el",
  },
  {
    label: "Gujarati",
    value: "gu",
  },
  {
    label: "Hausa",
    value: "ha",
  },
  {
    label: "Hebrew",
    value: "he",
  },
  {
    label: "Hindi",
    value: "hi",
  },
  {
    label: "Hungarian",
    value: "hu",
  },
  {
    label: "Indonesian",
    value: "id",
  },
  {
    label: "Irish",
    value: "ga",
  },
  {
    label: "Italian",
    value: "it",
  },
  {
    label: "Japanese",
    value: "ja",
  },
  {
    label: "Kannada",
    value: "kn",
  },
  {
    label: "Kazakh",
    value: "kk",
  },
  {
    label: "Kinyarwanda",
    value: "rw_RW",
  },
  {
    label: "Korean",
    value: "ko",
  },
  {
    label: "Kyrgyz (Kyrgyzstan)",
    value: "ky_KG",
  },
  {
    label: "Lao",
    value: "lo",
  },
  {
    label: "Latvian",
    value: "lv",
  },
  {
    label: "Lithuanian",
    value: "lt",
  },
  {
    label: "Macedonian",
    value: "mk",
  },
  {
    label: "Malay",
    value: "ms",
  },
  {
    label: "Malayalam",
    value: "ml",
  },
  {
    label: "Marathi",
    value: "mr",
  },
  {
    label: "Norwegian",
    value: "nb",
  },
  {
    label: "Persian",
    value: "fa",
  },
  {
    label: "Polish",
    value: "pl",
  },
  {
    label: "Portuguese (BR)",
    value: "pt_BR",
  },
  {
    label: "Portuguese (POR)",
    value: "pt_PT",
  },
  {
    label: "Punjabi",
    value: "pa",
  },
  {
    label: "Romanian",
    value: "ro",
  },
  {
    label: "Russian",
    value: "ru",
  },
  {
    label: "Serbian",
    value: "sr",
  },
  {
    label: "Slovak",
    value: "sk",
  },
  {
    label: "Slovenian",
    value: "sl",
  },
  {
    label: "Spanish",
    value: "es",
  },
  {
    label: "Spanish (ARG)",
    value: "es_AR",
  },
  {
    label: "Spanish (SPA)",
    value: "es_ES",
  },
  {
    label: "Spanish (MEX)",
    value: "es_MX",
  },
  {
    label: "Swahili",
    value: "sw",
  },
  {
    label: "Swedish",
    value: "sv",
  },
  {
    label: "Tamil",
    value: "ta",
  },
  {
    label: "Telugu",
    value: "te",
  },
  {
    label: "Thai",
    value: "th",
  },
  {
    label: "Turkish",
    value: "tr",
  },
  {
    label: "Ukrainian",
    value: "uk",
  },
  {
    label: "Urdu",
    value: "ur",
  },
  {
    label: "Uzbek",
    value: "uz",
  },
  {
    label: "Vietnamese",
    value: "vi",
  },
  {
    label: "Zulu",
    value: "zu",
  },
];
