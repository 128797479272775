<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    class="vp-p-6"
    :save="save"
    :get="get"
    :del="del"
    :archive="del"
    width="400px"
    collection-cache="tags"
    :track="$options.track"
    track-prefix="Tag"
    feature="TAG"
  >
    <template #default="{ state, response, isCreating }">
      <VpShouldDisplayOnStoreAlert
        v-if="response && !response.shouldDisplayOnStore"
        title="Tag not accessible!"
        desc="This tag is not accessible on extension."
      />
      <VpField rules="required" name="Name" label="Name">
        <VpInput>
          <VpTextBox v-model="state.name" />
        </VpInput>
      </VpField>

      <VpField rules="required" name="Tag is for" label="Tag is for">
        <div>
          <types :readonly="!isCreating" v-model="state.type" />
        </div>
      </VpField>

      <VpField label="Color" name="Color" rules="required">
        <ColorPicker v-model="state.color" />
      </VpField>

      <VpStatusInput v-model="state.status" />
    </template>
  </VpVueForm>
</template>

<script>
import ColorPicker from "components/src/color/picker.vue";

import del from "graph/tag/delete.gql";
import get from "graph/tag/get.gql";
import upsert from "graph/tag/upsert.gql";

import Types from "./_Types.vue";

import { clearExtCache } from "@/utils";

export default {
  track: {
    id: "Id",
    name: "Name",
    color: "Color",
    status: "Status",
    type: {
      key: "is for",
      value: (type) => {
        if (type === 1) {
          return "Contact";
        } else if (type === 2) {
          return "Message";
        } else {
          return "Media";
        }
      },
    },
  },
  props: {
    id: [Number, String],
    feature: Object,
  },
  components: {
    ColorPicker,
    Types,
  },
  data() {
    return {
      fields: [
        "id",
        "name",
        "type",
        { name: "color", value: "grey" },
        { name: "status", value: "active" },
      ],
    };
  },
  methods: {
    get(id) {
      return this.$query(get, {
        id: id,
      }).then(({ data }) => {
        return {
          values: data.tag,
          res: data,
        };
      });
    },

    save(id, data) {
      return this.$mutate(upsert, {
        input: {
          id,
          ...data,
        },
      }).then(() => {
        this.postCud();
      });
    },

    del(id, action) {
      return this.$mutate(del, {
        id: parseInt(id),
        action,
      }).then(() => {
        this.postCud();
      });
    },

    postCud() {
      clearExtCache({
        key: this.feature.key,
      });
      this.$router.push({ name: "tags" });
    },
  },
};
</script>
