<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    class="vp-p-6"
    :save="save"
    :get="get"
    :del="del"
    width="400px"
    collection-cache="ticketStatuses"
    :track="$options.track"
    track-prefix="Ticket Status"
    feature="TICKET"
  >
    <template #default="{ state }">
      <VpField rules="required" name="Name" label="Name">
        <VpInput>
          <VpTextBox v-model="state.name" />
        </VpInput>
      </VpField>

      <VpField rules="required" name="Color" label="Color">
        <ColorPicker v-model="state.color" />
      </VpField>
    </template>
  </VpVueForm>
</template>

<script>
import ColorPicker from "components/src/color/picker.vue";
import del from "./delete.gql";
import get from "./get.gql";
import upsert from "./upsert.gql";

export default {
  track: {
    id: "Id",
    name: "Name",
    color: "Color",
  },
  props: {
    id: [Number, String],
  },
  components: {
    ColorPicker,
  },
  data() {
    return {
      fields: ["name", { name: "color", value: "grey" }],
    };
  },
  methods: {
    get(id) {
      return this.$query(get, {
        id: id,
      }).then(({ data }) => {
        return {
          values: data.ticketStatus,
          res: data,
        };
      });
    },
    save(id, data) {
      const inputs = {
        input: data,
      };
      if (id) inputs.id = id;
      return this.$mutate(upsert, inputs).then(({ data }) => {
        this.$root.$emit("rehydrate", null, true);
        this.$router.push({ name: "ticket-statuses" });
        return data;
      });
    },
    del(id) {
      return this.$mutate(del, {
        id: parseInt(id),
      }).then(({ data }) => {
        this.$root.$emit("rehydrate", null, true);
        this.$router.push({ name: "ticket-statuses" });
        return data;
      });
    },
  },
};
</script>
