import gql from "graphql-tag";

export const FUNNEL = gql`
  fragment funnel on Funnel {
    id
    color
    name
  }
`;

export const FUNNELS_GET = gql`
  query funnels {
    funnels(perPage: -1) {
      data {
        ...funnel
      }
    }
  }
  ${FUNNEL}
`;

export const CONTACT_FUNNEL_GET = gql`
  query contactFunnel($contactId: Int!) {
    contact(id: $contactId) {
      id
      funnel {
        ...funnel
      }
    }
  }
  ${FUNNEL}
`;

export const CONTACT_FUNNEL_UPSERT = gql`
  mutation saveContact($contactId: Int, $input: ContactInput!) {
    upsertContact(id: $contactId, input: $input, source: "whatsapp") {
      id
      funnel {
        ...funnel
      }
    }
  }
  ${FUNNEL}
`;
