<template>
  <VpListLayout>
    <ExportModal
      endpoint="poll"
      :params="{ ...params }"
      title="Voters"
      feature="POLL_VOTER_EXPORT"
      history-route="poll-voters-export-history"
    />
    <template #header-start>
      <VpPortalButton
        label="Export History"
        :to="{ name: 'poll-voters-export-history' }"
      />

      <VpPortalButton
        label="Export"
        @click.native="
          checkPermission('POLL_VOTER_EXPORT', 'read', () => {
            $vayu.modal.open('export');
          })
        "
      />
    </template>
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="pollVotes"
      :request-payload="{ query: $options.pollVotes }"
      :filters.sync="filters"
      cache="pollVotes"
      :params="params"
      class="vp-h-full"
      ref="list"
      title="Poll Votes"
    >
      <template #filters>
        <VpField label="Poll Options">
          <VpInput>
            <VpSelect
              v-model.number="filters.optionId"
              :options="pollOptions"
              placeholder="Select"
            />
          </VpInput>
        </VpField>
      </template>
      <template #voter="{ item }">
        <VpConnection has-link type="contact" :data="item.voter" />
      </template>

      <template #receivedAt="{ item }">
        <VpDatetime :value="item.receivedAt" />
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import { mapGetters } from "vuex";
import get from "./get.gql";
import ExportModal from "components/src/export-modal.vue";
import pollVotes from "@/views/polls/pollVote/collection.gql";

export default {
  pollVotes,
  components: {
    ExportModal,
  },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      filters: {
        optionId: null,
      },
      poll: null,
    };
  },
  created() {
    this.getPoll(this.id);
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),

    params() {
      return { pollId: this.id };
    },
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "voter", type: "string" },
        {
          name: "choice",
          label: "Choice",
          type: "string",
          value(item) {
            return item.option.label;
          },
        },
        { name: "receivedAt", label: "Received", type: "string" },
      ];
    },
    pollOptions() {
      const options = [];
      options.push({ label: "All", value: null });
      this.poll &&
        this.poll.options.forEach(function (option) {
          options.push({
            label: option.label,
            value: Number(option.id),
          });
        });
      return options;
    },
  },
  methods: {
    getPoll(id) {
      this.$query(get, {
        id: id,
      }).then(({ data }) => {
        this.poll = data.poll;
        this.$emit("poll", data.poll);
      });
    },
  },
};
</script>
