<template>
  <div class="vp-p-6">
    <MessageStatistics :request="get" />
    <WhatsappAdmins class="vp-mt-8" :data="admins" />
  </div>
</template>

<script>
import statistics from "@/graph/crm/whatsAppGroup/statistics.gql";
import MessageStatistics from "components/src/contacts/_MessageStatistics.vue";
import WhatsappAdmins from "./_WhatsappAdmins.vue";

export default {
  props: {
    id: [String, Number],
  },
  data() {
    return {
      admins: null,
    };
  },
  components: {
    MessageStatistics,
    WhatsappAdmins,
  },

  methods: {
    get() {
      return this.$query(statistics, {
        id: this.id,
      }).then(({ data }) => {
        this.admins = data.whatsAppGroup?.admin;
        return data.contactStatistics.count;
      });
    },
  },
};
</script>
