<template>
  <VyButton
    class="vp-capitalize button--muted button--sm button--rounded"
    :class="$options.buttonClass[value]"
    :label="value"
  />
</template>

<script>
const buttonClass = {
  paused: "button--warning",
  draft: "button--warning",
  running: "button--success",
  complete: "button--success",
  failed: "button--danger",
};
export default {
  buttonClass,
  props: {
    value: String,
  },
};
</script>
