<template>
  <VpListLayout add-route="ticket-status" feature="TICKET">
    <VpList
      :per-page="25"
      :columns="columns"
      endpoint="ticketStatuses"
      :params="params"
      :request-payload="{ query: $options.ticketStatuses }"
      class="vp-h-full"
      cache="ticketStatuses"
      :reorder="true"
      ref="list"
      title="Ticket Status"
      @reorder="reorder('ticketStatuses', $event)"
      @rowClick="
        $router.push({
          name: 'ticket-status',
          params: {
            id: $event.id,
          },
        })
      "
    >
      <template #empty>
        <VpErrorPage
          title="No Ticket Status Found!"
          desc="You can adjust ticket status like it is now open or close, etc."
        >
          <template #actions>
            <VyButton
              label="Add New"
              :icon="$options.icons.Add"
              :to="{ name: 'ticket-status', params: { id: '+' } }"
              class="button--primary button--sm button--solid button--rounded"
            />
          </template>
        </VpErrorPage>
      </template>
      <template #color="{ item }">
        <ColorPicker :value="item.color" readonly />
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import ColorPicker from "components/src/color/picker.vue";
import reorder from "mixins/reorder.js";
import { Add } from "icons/icons.js";
import ticketStatuses from "@/views/crm/ticket-statuses/collection.gql";

export default {
  ticketStatuses,
  icons: {
    Add,
  },
  mixins: [reorder],
  components: {
    ColorPicker,
  },
  computed: {
    columns() {
      return [
        { name: "_index", fix: true, label: "#" },
        { name: "color", fix: true, type: "string" },
        { name: "name", sortable: true, type: "string" },
      ];
    },
  },
};
</script>
