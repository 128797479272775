import Vue from "vue";
import * as Sentry from "@sentry/vue";

if (import.meta.env.VITE_SENTRY == "true") {
  Sentry.init({
    Vue,
    dsn: "https://5b630bb8f22541349f5ae9258a3d5d22@o416535.ingest.sentry.io/5546869",
    tracesSampleRate: 1.0,
    ignoreErrors: ["ResizeObserver loop limit exceeded"],
  });
}
