<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    class="vp-p-6"
    :save="save"
    :get="get"
    :del="del"
    width="400px"
    collection-cache="tickets"
    :track="$options.track"
    track-prefix="Ticket"
    feature="TICKET"
  >
    <template #default="{ state, response, isCreating }">
      <VpField
        v-if="isCreating"
        rules="required"
        name="Contact"
        label="Contact"
      >
        <ContactSelector v-model="state.contactId" />
      </VpField>
      <VpField v-else label="Contact">
        <VpConnection type="contact" :data="response.contact" />
      </VpField>

      <VpField rules="required" name="Subject" label="Subject">
        <VpInput>
          <VpTextBox v-model="state.subject" />
        </VpInput>
      </VpField>
      <VpField rules="required" name="Description" label="Description">
        <VpTextArea :rows="7" v-model="state.description" />
      </VpField>

      <VpField rules="required" name="Status" label="Status">
        <TicketStatusInput v-model="state.statusId" />
      </VpField>

      <div class="vp-flex vp-gap-7" v-if="!isCreating">
        <VpField label="Created">
          <VpDatetime :value="response.createdAt" />
        </VpField>

        <VpField
          label="Closed"
          v-if="response.closedAt"
          class="vp-border-l-2 vp-pl-7"
        >
          <VpDatetime :value="response.closedAt" />
        </VpField>
      </div>

      <VpField label="Priority" class="priority" optional>
        <TicketPriority v-model="state.priority" />
      </VpField>

      <VpField label="Due Date" optional>
        <VpInput>
          <VpDatepicker v-model="state.dueDate" />
        </VpInput>
      </VpField>
    </template>
  </VpVueForm>
</template>

<script>
import upsert from "@/views/crm/tickets/upsert.gql";
import get from "@/views/crm/tickets/get.gql";
import del from "@/views/crm/tickets/delete.gql";
import { format } from "date-fns";
import ContactSelector from "@/components/contact-selector.vue";
import TicketPriority from "components/src/ticket-priority.vue";
import TicketStatusInput from "components/src/ticket-status/input.vue";

export default {
  track: {
    id: "Id",
    contact: {
      key: "Contact",
      value: (contact) =>
        contact?.name ?? contact?.mobileNumber.internationalFormat ?? "",
    },
    subject: "Subject",
    description: "Description",
    status: {
      key: "Status",
      value: (status) => status.name,
    },
    priority: "Priority",
    dueDate: {
      key: "Due Date",
      value: (dueDate) => new Date(dueDate).toISOString(),
    },
  },

  props: {
    id: [String, Number],
  },

  components: {
    ContactSelector,
    TicketPriority,
    TicketStatusInput,
  },

  computed: {
    fields() {
      return [
        "contactId",
        "subject",
        "description",
        {
          name: "priority",
          value: null,
        },
        {
          name: "dueDate",
          value: null,
        },
        {
          name: "statusId",
          value: null,
        },
      ];
    },
  },

  methods: {
    get(id) {
      return this.$query(get, {
        id,
        notificationId: this.$route.query.nId,
      }).then(({ data }) => {
        const ticket = { ...data.ticket };
        if (ticket.dueDate && ticket.dueDate.startsWith("0000")) {
          ticket.dueDate = null;
        }
        ticket.statusId = ticket.status.id;
        ticket.contactId = Number(ticket.contact?.id);
        return {
          values: ticket,
          res: data,
        };
      });
    },
    save(id, data) {
      if (!data.dueDate) data.dueDate = null;
      else {
        if (data.dueDate.includes("/"))
          data.dueDate = data.dueDate.replaceAll("/", "-");

        data.dueDate = format(new Date(data.dueDate), "yyyy-MM-dd");
      }

      const payload = {
        ...data,
        contactId: Number(data.contactId),
      };
      return this.$mutate(upsert, {
        id,
        input: payload,
      }).then(({ data }) => {
        this.$root.$emit("rehydrate", null, true);
        this.$router.push({ name: "tickets" });
        return data;
      });
    },
    del(id) {
      return this.$mutate(del, {
        id: parseInt(id),
      }).then(({ data }) => {
        this.$root.$emit("rehydrate", null, true);
        this.$router.push({ name: "tickets" });
        return data;
      });
    },
  },
};
</script>
