<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      d="M7 13v-2h14v2H7m0 6v-2h14v2H7M7 7V5h14v2H7M3 8V5H2V4h2v4H3m-1 9v-1h3v4H2v-1h2v-.5H3v-1h1V17H2m2.25-7a.75.75 0 0 1 .75.75c0 .2-.08.39-.21.52L3.12 13H5v1H2v-.92L4 11H2v-1h2.25z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiFormatListNumbered",
};
</script>
