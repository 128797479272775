import "./sentry";
import "plugins/index.js";

import Vue from "vue";

import OneSignalVue from "onesignal-vue";
import { init as initVueList } from "plugins/v-list";

/**
 * Click Outside
 */
import vClickOutside from "v-click-outside";
/**
 * Cropper
 */
import VueCropper from "vue-cropperjs";
import VueGtag from "vue-gtag";
/**
 * Vue Select
 */
import vSelect from "vue-select";

/**
 * Import Vue List
 */

import router from "../router";

Vue.component("VSelect", vSelect);

Vue.component("VueCropper", VueCropper);

Vue.mixin({
  data() {
    return {
      PLATFORM: "WEB",
    };
  },
});

/**
 * Google Tag Manager
 */
Vue.use(
  VueGtag,
  {
    config: { id: "GTM-PK3RK5G" },
  },
  router
);

Vue.use(vClickOutside);

initVueList();

// One Signal
Vue.use(OneSignalVue);
