import contact from "./contact";
import messages from "./messages";
import whatsappGroup from "./whatsapp-group";
import marketing from "./marketing/index.js";
import settings from "../settings.js";
import poll from "./marketing/poll.js";

// const HomeIndex = () => import("@/views/home/Index.vue");
import DashboardIndex from "@/views/crm/dashboard/Index.vue";
import MessagesIndex from "@/views/crm/messages/Index.vue";
import ChatsList from "@/views/crm/chats/List.vue";
import ChatsIntro from "@/views/crm/chats/Intro.vue";
import ChatsItem from "@/views/crm/chats/Item.vue";
// import ChatsExports from "@/views/crm/chats/Exports.vue"

import TicketsIndex from "@/views/crm/tickets/Index.vue";
import TicketsList from "@/views/crm/tickets/List.vue";
// import TicketsExport from "@/views/crm/tickets/Exports.vue"
import TicketsItem from "@/views/crm/tickets/Item.vue";

import SettingsIndex from "@/views/crm/settings/index.vue";
import RemindersIndex from "@/views/crm/reminders/Index.vue";
import RemindersList from "@/views/crm/reminders/List.vue";
import RemindersItem from "@/views/crm/reminders/Item.vue";

export default [
  {
    path: "",
    name: "home",
    component: DashboardIndex,
    meta: {
      label: "Dashboard",
      breadcrumbs: ["home"],
    },
  },
  // {
  //   path: "dashboard",
  //   name: "dashboard",
  //   component: DashboardIndex,
  //   meta: {
  //     label: "Dashboard",
  //     breadcrumbs: ["home", "dashboard"],
  //   },
  // },

  ...poll,
  ...contact,
  ...whatsappGroup,
  ...marketing,
  {
    path: "settings",
    name: "settings",
    redirect: { name: "biz-info" },
    meta: {
      label: "Settings",
      breadcrumbs: ["home", "settings"],
    },
    component: SettingsIndex,
    children: settings,
  },
  {
    path: "messages",
    component: MessagesIndex,
    children: messages,
  },
  {
    path: "chats",
    component: ChatsList,
    props: true,
    children: [
      {
        path: "",
        name: "chats",
        component: ChatsIntro,
        props: true,
        meta: {
          whatsapp: false,
          label: "Chats",
          breadcrumbs: ["home", "chats"],
        },
      },
      {
        path: ":id",
        name: "conversation",
        component: ChatsItem,
        props: true,
        meta: {
          whatsapp: false,
          label: "Conversation",
          breadcrumbs: ["home", "chats", "conversation"],
        },
      },
      // {
      //   path: ":id/exports",
      //   name: "chats-export-history",
      //   component: ChatsExports,
      //   meta: {
      //     label: "Export History",
      //     breadcrumbs: [, "chats", "chats-export-history"],
      //   },
      // },
    ],
  },

  {
    path: "tickets",
    component: TicketsIndex,
    children: [
      {
        path: "",
        name: "tickets",
        component: TicketsList,
        props: true,
        meta: {
          label: "Tickets",
          breadcrumbs: ["home", "tickets"],
        },
      },
      {
        path: ":id",
        name: "ticket",
        component: TicketsItem,
        props: true,
        meta: {
          label: "Ticket",
          breadcrumbs: ["home", "tickets", "ticket"],
        },
      },
    ],
  },

  {
    path: "reminders",
    component: RemindersIndex,
    children: [
      {
        path: "",
        name: "reminders",
        component: RemindersList,
        meta: {
          label: "Reminders",
          breadcrumbs: ["home", "reminders"],
        },
      },
      {
        path: ":id",
        name: "reminder",
        component: RemindersItem,
        props: true,
        meta: {
          label: "Reminder",
          breadcrumbs: ["home", "reminders", "reminder"],
        },
      },
    ],
  },
];
