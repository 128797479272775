<template>
  <div class="dashboard">
    <div v-if="isPermission">
      <Portal to="header">
        <VpCalendar
          key="crm-date-range"
          class="vp-w-56"
          duration="last_30_days"
          v-model="range"
          @input="$refs.request && $refs.request.refresh()"
        />
      </Portal>
      <VpRequest v-if="range" :get="get" ref="request">
        <template
          #default="{
            res: { contactNotes, contacts, messages, reminders, tickets },
          }"
        >
          <div v-if="contacts">
            <div class="vp-p-6">
              <HomeIndex />
            </div>
            <hr />
            <TheTitle> {{ rangeDate }} </TheTitle>

            <TheTitle>{{ contacts.title }}</TheTitle>
            <div class="vp-grid vp-grid-cols-2 vp-border-b vp-divide-x">
              <IconCard
                v-for="(item, index) in contacts.counters"
                :key="'count-' + index"
                :icon="item.icon"
                :title="item.value"
                :desc="item.label"
                icon-type="link"
              />
            </div>
          </div>
          <div class="vp-mb-4" v-if="messages">
            <TheTitle>{{ messages.title }}</TheTitle>
            <div class="vp-grid vp-grid-cols-2 vp-border-b vp-divide-x">
              <IconCard
                v-for="(item, index) in messages.counters"
                :key="'count-' + index"
                :icon="item.icon"
                :title="item.value"
                :desc="item.label"
                icon-type="link"
              />
            </div>
          </div>
          <div class="vp-mb-4">
            <div class="vp-grid vp-grid-cols-2 vp-border-b vp-divide-x">
              <div v-if="tickets">
                <TheTitle>{{ tickets.title }}</TheTitle>
                <div
                  v-for="(item, index) in tickets.counters"
                  :key="'count-' + index"
                  class="vp-m-4 vp-rounded"
                >
                  <IconCard
                    :icon="item.icon"
                    :title="item.value"
                    :desc="item.label"
                    :item-key="item.id"
                    icon-type="link"
                    clickable
                    v-model="filters.ticketStatus"
                    @input="
                      $refs['ticket-priority-request'] &&
                        $refs['ticket-priority-request'].refresh()
                    "
                  />
                </div>
              </div>
              <VpRequest :get="getTicketPriority" ref="ticket-priority-request">
                <template #default="{ res }">
                  <PieChart
                    :options="ticketPriorityOptions"
                    :values="res.counters"
                  />
                </template>
              </VpRequest>
            </div>
          </div>
          <div class="vp-mb-4">
            <div class="vp-grid vp-grid-cols-2 vp-border-b vp-divide-x">
              <div v-if="reminders">
                <TheTitle>{{ reminders.title }}</TheTitle>
                <div class="vp-divide-y">
                  <IconCard
                    v-for="(item, index) in reminders.counters"
                    :key="'count-' + index"
                    :icon="item.icon"
                    :title="item.value"
                    :desc="item.label"
                    icon-type="link"
                  />
                </div>
              </div>
              <VpRequest
                :get="getTopSentQuickReply"
                ref="top-quick-replies-request"
              >
                <template #default="{ res }">
                  <BarChart
                    :is-quick-reply-chart="true"
                    :values="res"
                    :options="quickReplyOptions"
                  />
                </template>
              </VpRequest>
            </div>
          </div>
          <!-- funnel & tag -->
          <div class="vp-grid vp-grid-cols-2 vp-divide-x vp-border-b vp-mb-4">
            <VpRequest :get="getTopFunnel" ref="top-funnels-request">
              <template #default="{ res }">
                <BarChart :values="res" :options="funnelOptions" />
              </template>
            </VpRequest>
            <VpRequest :get="getTopContactTag" ref="top-contact-request">
              <template #default="{ res }">
                <BarChart :values="res" :options="tagOptions" />
              </template>
            </VpRequest>
          </div>
          <!-- notes -->
          <div class="vp-mb-4" v-if="contactNotes">
            <TheTitle>{{ contactNotes.title }}</TheTitle>
            <div class="vp-grid vp-grid-cols-2 vp-border-b vp-divide-x">
              <IconCard
                v-for="(item, index) in contactNotes.counters"
                :key="'count-' + index"
                :icon="item.icon"
                :title="item.value"
                :desc="item.label"
                icon-type="link"
              />
            </div>
          </div>
        </template>
      </VpRequest>
    </div>
    <div v-else>
      <VpUnauthorizedAccess class="vp-p-4"></VpUnauthorizedAccess>
    </div>
  </div>
</template>
<script>
import { format } from "date-fns";
import { mapGetters } from "vuex";

import IconCard from "components/src/dashboard/icon-card.vue";

import get from "@/graph/crm/dashboard/get.gql";
import getTicketPriorities from "@/graph/crm/dashboard/getTicketPriorities.gql";
import getTopContactTags from "@/graph/crm/dashboard/getTopContactTags.gql";
import getTopFunnels from "@/graph/crm/dashboard/getTopFunnels.gql";
import getTopSentQuickReplies from "@/graph/crm/dashboard/getTopSentQuickReplies.gql";

import BarChart from "./_BarChart.vue";
import PieChart from "./_PieChart.vue";
import TheTitle from "./_Title.vue";
import HomeIndex from "@/views/home/Index.vue";

export default {
  components: {
    HomeIndex,
    IconCard,
    TheTitle,
    BarChart,
    PieChart,
  },
  data() {
    return {
      filters: {
        ticketStatus: null,
      },
      format,
      range: null,
      funnelOptions: {
        name: "Funnels",
      },
      tagOptions: {
        name: "Tags",
      },
      ticketPriorityOptions: {
        name: "Ticket Priorities",
      },
      quickReplyOptions: {
        name: "Quick Replies",
      },
      errors: {
        error:
          "Unauthorized, You don't have the access to this feature. Please ask admin for the further approvals.",
      },
    };
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),

    isPermission() {
      return this.checkPermission("CRM_DASHBOARD", "read");
    },

    rangeDate() {
      return `${this.format(
        this.range?.start * 1000,
        "dd MMMM yyyy"
      )} - ${this.format(this.range?.end * 1000, "dd MMMM, yyyy")}`;
    },
    params() {
      return {
        duration: {
          start: this.range?.start,
          end: this.range?.end,
          preset: "custom",
        },
      };
    },
  },
  methods: {
    get() {
      this.clearCache("crmDashboard");
      return this.$query(get, this.params).then(
        ({ data }) => data.crmDashboard
      );
    },

    getTicketPriority() {
      this.clearCache("crmDashboard");
      return this.$query(getTicketPriorities, {
        ...this.params,
        filters: this.filters,
      }).then(({ data }) => data.crmDashboard.ticketPriorities);
    },

    getTopFunnel() {
      this.clearCache("topFunnels");
      return this.$query(getTopFunnels, {}).then(
        ({ data }) => data.topFunnels.value
      );
    },

    getTopContactTag() {
      this.clearCache("topContactTags");
      return this.$query(getTopContactTags, {}).then(
        ({ data }) => data.topContactTags.value
      );
    },

    getTopSentQuickReply() {
      this.clearCache("topSentQuickReplies");
      return this.$query(getTopSentQuickReplies, {}).then(
        ({ data }) => data.topSentQuickReplies.value
      );
    },

    clearCache(fieldName) {
      this.$cache.evict({
        id: "ROOT_QUERY",
        fieldName,
      });
    },
  },
};
</script>
