<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    class="vp-p-6"
    :save="save"
    :get="get"
    :del="move"
    width="400px"
    collection-cache="whatsAppGroups"
    :track="$options.track"
    track-prefix="WhatsApp Group"
    feature="WHATSAPP_GROUP"
  >
    <template #default="{ state }">
      <VpField label="Funnel">
        <FunnelInput v-model="state.funnelId" />
      </VpField>

      <VpField label="Tags">
        <TagsInput type="contact" v-model="state.tagIds" />
      </VpField>

      <VpField label="Note">
        <VpTextArea :rows="5" v-model="state.note" />
      </VpField>
    </template>
  </VpVueForm>
</template>

<script>
import TagsInput from "components/src/tags/input.vue";
import FunnelInput from "components/src/funnel/input.vue";

import upsert from "@/graph/crm/whatsAppGroup/upsert.gql";
import get from "@/graph/crm/whatsAppGroup/get.gql";
import move from "graph/contact/move.gql";
import { timeStampToISOString } from "plugins/utils";

export default {
  track: {
    id: "Id",
    name: "Name",
    note: {
      key: "Note",
      value: (note) => note || "",
    },
    funnel: {
      key: "Funnel",
      value: (funnel) => funnel?.name ?? "",
    },
    tags: {
      key: "Tags",
      value: (tags) => JSON.stringify(tags?.map((tag) => tag.name)),
    },
    profilePicture: {
      key: "Profile Picture",
      value: (profilePicture) => profilePicture?.url ?? "",
    },
    creation: {
      key: "Created At",
      value: (creation) => timeStampToISOString(creation),
    },
  },
  props: {
    id: [String, Number],
  },
  components: {
    FunnelInput,
    TagsInput,
  },
  data() {
    return {
      fields: ["funnelId", "tagIds", "note"],
      groupName: null,
    };
  },
  methods: {
    get() {
      return this.$query(get, {
        id: this.id,
      }).then(({ data }) => {
        this.groupName = data.whatsAppGroup.name;
        return {
          values: {
            ...data.whatsAppGroup,
            funnelId: data.whatsAppGroup.funnel?.id,
            tagIds: data.whatsAppGroup.tags?.map((item) => item.id),
          },
          res: data,
        };
      });
    },

    save(id, data) {
      return this.$mutate(upsert, {
        id,
        input: data,
      }).then(({ data }) => data);
    },

    move(id, action) {
      return this.$mutate(move, {
        id: [parseInt(id)],
        action,
      }).then(({ data }) => {
        this.$router.push({ name: "whatsapp-groups" });

        return data;
      });
    },
  },
};
</script>
