<template>
  <div class="vp-p-6 vp-rounded-md vp-border">
    <h4
      class="vp-mb-4 vp-border-b vp-flex vp-items-center vp-pb-2 vp-text-lg vp-font-bold"
    >
      <p v-if="emoji" class="vp-mr-4">{{ emoji }}</p>
      <p class="vp-flex-auto">
        {{ title }}
      </p>
    </h4>
    <div class="vp-flex vp-flex-col vp-space-y-4 vp-text-gray-900">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    emoji: String,
  },
};
</script>
