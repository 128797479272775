<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    class="vp-p-6"
    :save="save"
    :del="move"
    :get="get"
    width="400px"
    collection-cache="reminders"
    :track="$options.track"
    track-prefix="Reminder"
    feature="REMINDER"
  >
    <template #default="{ state, response, isCreating }">
      <VpField
        v-if="isCreating"
        rules="required"
        name="Contact"
        label="Contact"
      >
        <ContactSelector v-model="state.contactId" />
      </VpField>
      <VpField v-else label="Contact">
        <VpConnection type="contact" :data="response.contact" />
      </VpField>

      <VpField rules="required" name="Title" label="Title">
        <VpInput>
          <VpTextBox v-model="state.title" />
        </VpInput>
      </VpField>

      <VpField rules="required" name="Description" label="Description">
        <VpTextArea :rows="5" v-model="state.description" />
      </VpField>

      <VpField rules="required|dateTime" name="Remind At" label="Remind At">
        <VpInput>
          <VpTextBox
            type="datetime-local"
            v-model="state.remindAt"
            :min="convertToDateTimeLocal(new Date())"
          />
        </VpInput>
      </VpField>
    </template>
  </VpVueForm>
</template>

<script>
import get from "@/views/crm/reminders/get.gql";
import upsert from "@/views/crm/reminders/upsert.gql";
import move from "@/views/crm/reminders/move.gql";
import { timeStampToISOString } from "plugins/utils";
import ContactSelector from "@/components/contact-selector.vue";

export default {
  track: {
    id: "Id",
    contact: {
      key: "Contact",
      value: (contact) =>
        contact?.name ?? contact?.mobileNumber.internationalFormat ?? "",
    },
    title: "Title",
    description: "Description",
    remindAt: {
      key: "Remind At",
      value: (remindAt) => timeStampToISOString(remindAt),
    },
  },
  props: {
    id: [Number, String],
  },

  components: {
    ContactSelector,
  },

  data() {
    return {
      fields: ["contactId", "title", "description", "remindAt"],
    };
  },
  methods: {
    convertToDateTimeLocal(date) {
      return new Date(
        date.getTime() + new Date().getTimezoneOffset() * -60 * 1000
      )
        .toISOString()
        .slice(0, 16);
    },
    get(id) {
      return this.$query(get, {
        id: id,
        notificationId: this.$route.query.nId,
      }).then(({ data }) => {
        const reminder = { ...data.reminder };
        reminder.contactId = reminder.contact?.id;
        reminder.remindAt = this.convertToDateTimeLocal(
          new Date(Number(reminder.remindAt) * 1000)
        );
        return {
          values: reminder,
          res: data,
        };
      });
    },
    save(id, data) {
      const inputs = {
        id,
        contactId: Number(data.contactId),
        input: {
          title: data.title,
          description: data.description,
          remindAt: String(new Date(data.remindAt).getTime() / 1000).split(
            "."
          )[0],
        },
      };

      return this.$mutate(upsert, inputs).then(({ data }) => {
        this.$root.$emit("rehydrate", null, true);
        this.$router.push({ name: "reminders" });
        return data;
      });
    },
    move(id) {
      return this.$mutate(move, {
        id: parseInt(id),
      }).then(({ data }) => {
        this.$root.$emit("rehydrate", null, true);
        this.$router.push({ name: "reminders" });
        return data;
      });
    },
  },
};
</script>
