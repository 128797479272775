<template>
  <VpModal name="delete-chat" _class="vp-w-1/3" title="Delete Chat">
    <div class="vp-p-6 vp-space-y-4">
      <p>
        This action will remove messages which are automatically synced. This
        action can not be reversed. Are you sure you want to delete all the
        chats with this contact?
      </p>

      <VpField
        inline
        label="Delete Saved Messages"
        note="Turn this on if you wish to delete all the saved messages including media."
      >
        <VpSwitch v-model="deleteSavedMessages" />
      </VpField>

      <div class="vp-flex vp-mt-6">
        <VyButton
          type="button"
          :icon="$options.icons.Delete"
          label="Delete Chat"
          @click.native="del"
          :loading="deleting"
          class="button--danger button--solid button--lg button--rounded"
        />
      </div>
    </div>
  </VpModal>
</template>

<script>
import del from "./delete.gql";
import { Delete } from "icons/icons.js";

export default {
  icons: {
    Delete,
  },
  props: {
    id: [String, Number],
  },

  data() {
    return {
      deleting: false,
      deleteSavedMessages: false,
    };
  },
  methods: {
    async del() {
      this.deleting = true;
      await this.$mutate(del, {
        contactId: this.id,
        deleteSavedMessages: this.deleteSavedMessages,
      }).then(() => {
        this.$vayu.modal.close("delete-chat");
        this.$emit("delete");
      });
      this.deleting = false;
    },
  },
};
</script>
