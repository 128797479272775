import whatsappBroadcast from "./whatsapp-broadcast";
import index from "@/views/crm/marketing/index.vue";
export default [
  {
    path: "",
    name: "marketing",
    component: index,
    redirect: () => ({
      name: "whatsapp-broadcasts",
      params: { via: "api" },
    }),
    meta: {
      label: "Marketing",
      breadcrumbs: ["home", "marketing"],
    },
    children: [...whatsappBroadcast],
  },
];
