<template>
  <VpModal
    name="dynamic-quick-replies"
    _class="vp-w-1/2 vp-h-3/4"
    title="How to Format"
  >
    <div class="vp-p-4">
      <help-card title="Dynamic Quick Replies">
        <p>
          Add contact's information in a reply with placeholders. Write down a
          placeholder like this: &lt;name&gt;
        </p>
        <p class="vp-m-0">Available Placeholders:</p>
        <ul class="vp-m-0">
          <li>name</li>
          <li>whatsapp_no</li>
          <li>whatsapp_no_plain</li>
          <li>city</li>
          <li>address</li>
          <li>email</li>
          <li>country</li>
          <li>timezone</li>
          <li>note</li>
          <li>hubspot_first_name</li>
          <li>hubspot_last_name</li>
          <li>hubspot_company</li>
          <li>hubspot_email</li>
          <li>hubspot_lifecycle_stage</li>
          <li>hubspot_mobile_number</li>
        </ul>
      </help-card>

      <help-card class="vp-mt-2" title="Decorating Text">
        <div>
          <p class="vp-font-bold">Bold:</p>
          <p>To write in bold, wrap the text like *text*</p>
        </div>
        <div>
          <p class="vp-italic">Italic:</p>
          <p>To write in italics, wrap the text like _text_</p>
        </div>
        <div>
          <p class="vp-strikethrough">Strikethrough:</p>
          <p>To cross out a text, wrap the text like ~text~</p>
        </div>
      </help-card>
    </div>
  </VpModal>
</template>

<script>
import HelpCard from "components/src/help/card.vue";

export default {
  components: {
    HelpCard,
  },
};
</script>
