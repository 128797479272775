<template>
  <Modal title="Switch Business" name="biz-switch-modal" _class="vp-w-1/3">
    <template #default>
      <div v-if="isCampaigns && !isLoading" class="vp-divide-y">
        <button
          v-for="business in campaigns"
          :key="business.id"
          class="vp-flex vp-items-center vp-w-full vp-space-x-3 vp-p-3 hover:vp-bg-gray-50"
          @click="switchBusiness(business.id)"
        >
          <div>
            <Avatar
              :image="business.profilePicture?.url"
              :name="business.name"
              :color-index="business.id.charAt(business.id.length - 1)"
              class="vp-rounded-full"
              size="sm"
            />
          </div>
          <div class="vp-flex vp-items-center vp-justify-between vp-w-full">
            <p class="vp-text-lg vp-font-medium">{{ business.name }}</p>
            <Icon
              v-if="campaignId == business.id"
              class="vp-text-[28px] vp-mr-1 vp-text-green-500"
              :name="$options.icons.RadioChecked"
            />
          </div>
        </button>
      </div>
      <div v-else class="vp-divide-y vp-space-y-3">
        <div
          v-for="items in 3"
          :key="items"
          class="vp-flex vp-p-3 vp-space-x-3 vp-animate-pulse vp-items-center"
        >
          <div class="vp-p-8 vp-bg-gray-200 vp-rounded-full"></div>
          <div class="vp-w-full vp-space-y-2">
            <div class="vp-w-1/2 vp-h-5 vp-bg-gray-200 vp-rounded-lg"></div>
            <div class="vp-w-1/3 vp-h-2 vp-bg-gray-200 vp-rounded-md"></div>
          </div>
        </div>
      </div>
    </template>
    <template #footer-actions>
      <VyButton
        label="Manage"
        @click.native="handleRedirect()"
        class="button--primary button--rounded button--md button--solid"
      />
    </template>
  </Modal>
</template>
<script>
import { RadioChecked } from "icons/icons.js";
import { mapGetters } from "vuex";

import Avatar from "./avatar.vue";
import Icon from "./icon.vue";
import Modal from "./modal.vue";
import campaigns from "graph/user/campaigns.gql";
export default {
  components: {
    Icon,
    Modal,
    Avatar,
  },
  icons: {
    RadioChecked,
  },
  props: {
    isExtension: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      campaigns: null,
      isLoading: true,
    };
  },
  mounted() {
    this.getCampaigns();
  },
  methods: {
    handleRedirect() {
      if (this.isExtension) {
        window.open(this.$toCrm("businesses"), "_blank");
      } else {
        this.$router.push({ name: "businesses" });
      }
      this.$vayu.modal.close("biz-switch-modal");
    },
    switchBusiness(id) {
      if (this.campaignId != id) {
        this.$root.$emit("rehydrate", id, true, false);
        this.$vayu.modal.close("biz-switch-modal");
      }
    },
    getCampaigns() {
      if (this.$cache?.clear) {
        this.$cache.clear("campaigns");
      } else {
        this.$cache?.evict({
          id: "ROOT_QUERY",
          fieldName: "campaigns",
        });
      }
      return this.$query(campaigns)
        .then(({ data }) => {
          this.campaigns = data.campaigns.data;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  computed: {
    ...mapGetters({
      campaignId: "biz/id",
    }),
    isCampaigns() {
      if (this.campaigns) return this.campaigns;
      else {
        return this.getCampaigns();
      }
    },
  },
};
</script>
