<template>
  <VpVueForm
    id="true"
    :fields="fields"
    class="vp-p-6"
    :get="get"
    width="400px"
    ref="item"
    :save="save"
    #default="{ state, response }"
    :track="$options.track"
    track-prefix="Integration"
    track-event-name="Configured"
    cache="integration"
    collection-cache="integrations"
    feature="INTEGRATION_SALESFORCE"
  >
    <VpPageHeader title="Salesforce" />

    <VpStatusInput id="salesforce-status" v-model="state.status" />

    <template>
      <!-- CONNECTED -->
      <VpField label="Connected As">
        <div
          v-if="response.user && !connectingUser"
          class="vp-bg-gray-100 vp-rounded-xl vp-p-4 vp-space-y-4"
        >
          <VpField label="Name">
            <h5 class="vp-font-bold">
              {{ response.user.firstName }} {{ response.user.lastName }}
            </h5>
          </VpField>

          <VpField label="Email">
            <p>{{ response.user.email }}</p>
          </VpField>

          <VpField label="Company">
            <p>{{ response.user.companyName }}</p>
          </VpField>

          <VpField label="Salesforce ID">
            <p>{{ response.user.id }}</p>
          </VpField>

          <div>
            <VyButton
              type="button"
              label="Disconnect"
              :loading="disconnectingUser"
              @click.native="
                checkPermission('INTEGRATION_SALESFORCE', 'delete', () => {
                  disconnect(response);
                })
              "
              class="button--warning button--solid button--rounded button--sm"
            />
          </div>
        </div>

        <div v-else>
          <VyButton
            type="button"
            :loading="connectingUser"
            label="Connect to SalesForce"
            @click.native="generateRedirectUrl(response)"
            class="button--secondary button--solid button--md button--rounded"
          />
        </div>
      </VpField>
    </template>
  </VpVueForm>
</template>

<script>
import { Copy } from "icons/icons.js";
import { mapGetters } from "vuex";

import deleteUser from "./delete-user.gql";
import get from "./get.gql";
import update from "./update.gql";
import updateUser from "./update-user.gql";

export default {
  icons: {
    Copy,
  },
  track: {
    id: {
      key: "Provider",
      value: () => "Salesforce",
    },
  },
  components: {},

  data() {
    return {
      fields: ["status"],
      connectingUser: false,
      disconnectingUser: false,
    };
  },

  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
  },

  methods: {
    generateRedirectUrl({ loginEndpoint, redirectUrl }) {
      const encodedRedirectUrl = encodeURIComponent(redirectUrl);
      window.location.href = `${loginEndpoint}&response_type=code&redirect_uri=${encodedRedirectUrl}`;
    },

    copyRedirectUrl(redirectUrl) {
      this.$vayu.copy(redirectUrl);
      this.$vayu.notify({
        title: "Copied",
      });
    },

    async disconnect() {
      try {
        this.disconnectingUser = true;
        await this.$mutate(deleteUser);
        this.$refs.item.refresh();
      } catch (err) {
        console.error(err);
      }
      this.disconnectingUser = false;
    },

    async checkForCode() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      if (code) {
        this.connectingUser = true;
        try {
          await this.$mutate(updateUser, {
            input: {
              code,
            },
          });
          this.$refs.item.refresh();
          this.$router.replace({ query: null });
        } catch (err) {
          console.error(err);
        }
        this.connectingUser = false;
      }
    },

    get() {
      return this.$query(get).then(({ data }) => {
        if (this.checkPermission("INTEGRATION_SALESFORCE", "upsert")) {
          this.checkForCode();
        }
        return {
          values: data.integration.salesforce,
          res: data.integration,
        };
      });
    },

    save(id, data) {
      return this.$mutate(update, {
        input: {
          ...data,
        },
      }).then(({ data }) => {
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: "integration",
        });
        this.$router.push({ name: "integrations" });
        return data;
      });
    },
  },
};
</script>
