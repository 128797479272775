<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
  >
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="m3 8l4-4l4 4M7 4v9m6 3l4 4l4-4m-4-6v10"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "TablerSwitchVertical",
};
</script>
