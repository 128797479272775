<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    class="vp-p-4"
    :save="save"
    :get="get"
    :del="del"
    width="500px"
    :collection-cache="
      isApi ? 'whatsappCloudApiBroadcasts' : 'whatsappExtensionBroadcasts'
    "
    feature="WHATSAPP_BROADCAST"
    ref="broadcastForm"
  >
    <template #default="{ state, response, isCreating }">
      <VpField rules="required" name="Broadcast Name" label="Broadcast Name">
        <VpInput>
          <VpTextBox v-model="state.name" />
        </VpInput>
      </VpField>

      <VpField label="Select Contacts" note="">
        <div
          class="vp-border-gray-200 vp-border vp-rounded-md vp-overflow-hidden"
        >
          <VpLoaderContainer overlay :loading="loading">
            <div class="vp-p-5 vp-space-y-4">
              <VpField
                label="Tags"
                :note="
                  isCreating
                    ? `Contact having any of the tags you select will be added to broadcast.`
                    : ``
                "
              >
                <TagsInput
                  v-if="isCreating"
                  type="contact"
                  v-model="state.tagIds"
                  @input="getTotalContactCount(state)"
                />

                <TagsDisplay
                  v-else-if="response.tags.length"
                  :value="response.tags"
                />

                <p v-else class="vp-text-xs vp-text-gray-500">
                  No tags are selected.
                </p>
              </VpField>
              <VpField
                label="Funnels"
                :note="
                  isCreating
                    ? `Contact having any of the funnel you select will be added to broadcast.`
                    : ``
                "
              >
                <FunnelMultiInput
                  v-if="isCreating"
                  v-model="state.funnelIds"
                  @input="getTotalContactCount(state)"
                />
                <div
                  class="vp-flex vp-flex-wrap vp-gap-1"
                  v-else-if="response.funnels.length"
                >
                  <FunnelDisplay
                    v-for="item in response.funnels"
                    :key="item.id"
                    :value="item"
                  />
                </div>
                <p v-else class="vp-text-xs vp-text-gray-500">
                  No funnel is selected.
                </p>
              </VpField>
              <div>
                <p class="vp-text-primary-500">
                  Selected Contacts:
                  {{
                    isCreating ? totalContacts : response?.summary.recipients
                  }}
                </p>
                <p v-if="!isApi" class="vp-text-xs vp-text-warning-500">
                  To avoid your number being blocked by WhatsApp, we recommend
                  to add upto 100 contacts to broadcast list.
                </p>
              </div>
            </div>
          </VpLoaderContainer>
        </div>
      </VpField>

      <!-- API -->
      <VpField
        v-if="isApi"
        label="Template Details"
        note="Template details are available at WhatsApp Manager under Meta Account."
      >
        <template #default>
          <div class="vp-border vp-border-gray-200 vp-p-5 vp-space-y-4">
            <VpField rules="required" name="Name" label="Name">
              <VpInput>
                <VpTextBox v-model="state.templateName" />
              </VpInput>
            </VpField>

            <VpField rules="required" name="Type" label="Type">
              <VpInput>
                <VpSelect
                  v-model="state.templateType"
                  :options="$options.types"
                  @input="media = null"
                />
              </VpInput>
            </VpField>

            <VpField
              v-if="state.templateType && state.templateType !== 'text'"
              label="Media"
            >
              <div>
                <VpFile
                  label="Upload Now"
                  @input="media = $event[0]"
                  :accept="$options.fileTypes[state.templateType]"
                />
                <div
                  v-if="media"
                  class="vp-mt-2 vp-flex vp-items-center vp-space-x-2"
                >
                  <VpMedia :value="media" />

                  <VyButton
                    type="button"
                    v-if="media"
                    :icon="$options.icons.Delete"
                    :loading="removingMedia"
                    @click.native="media = null"
                    class="button--danger button--muted button--square button--sm button--rounded"
                  />
                </div>
              </div>
            </VpField>

            <VpField rules="required" name="Language" label="Language">
              <VpInput>
                <VpSelect
                  v-model="state.templateLanguageCode"
                  :options="$options.languageCodes"
                />
              </VpInput>
            </VpField>
          </div>
        </template>

        <template #end>
          <VyButton
            label="Get Template Details"
            href="https://business.facebook.com/wa/manage/message-templates"
            target="whatsapp-manager"
            :icon="$options.icons.OpenInNew"
            class="button--primary button--sm button--right"
          />
        </template>
      </VpField>

      <!-- EXT -->
      <template v-else>
        <VpField
          rules="required"
          label="Select Quick Reply"
          name="Select Quick Reply"
          note="You can broadcast simple message, single image with text or only send one document file using this feature."
        >
          <VpSelectRequest
            v-model.number="state.quickReplyId"
            :request="getQuickReplies"
          />
        </VpField>
      </template>
    </template>
  </VpVueForm>
</template>

<script>
import { Delete, Document, OpenInNew } from "icons/icons.js";
import { createMedia, deleteMedia } from "utils/presets";
import TagsDisplay from "components/src/tags/display.vue";
import TagsInput from "components/src/tags/input.vue";
import FunnelDisplay from "components/src/funnel/display.vue";
import FunnelMultiInput from "components/src/funnel/multi-input.vue";

import languageCodes from "@/assets/whatsappCloudApiLanguageCodes";
import getApi from "./cloud-api/get.gql";
import upsertApi from "./cloud-api/upsert.gql";
import getExt from "./extension/get.gql";
import quickReplies from "./extension/quick-replies.gql";
import upsertExt from "./extension/upsert.gql";
import getContacts from "./contacts-count.gql";
import move from "./move.gql";
import { fileToBase64 } from "plugins/utils.js";

export default {
  icons: {
    Document,
    Delete,
    OpenInNew,
  },
  languageCodes,
  types: [
    {
      label: "Text",
      value: "text",
    },
    {
      label: "Image",
      value: "image",
    },
    {
      label: "Video",
      value: "video",
    },
    {
      label: "Document",
      value: "document",
    },
  ],

  fileTypes: {
    text: null,
    image: ".jpg,.jpeg,.png",
    video: ".3gp,.mp4",
    document: ".pdf,.csv,.doc,.docx,.ppt,.xls,.xlsx,.rtf,.txt",
  },

  track: {
    id: "Id",
    name: "Name",
    templateName: "Template Name",
    templateType: "Template Type",
    templateLanguageCode: "Template Language Code",
    totalContact: "Total Contacts",
    tags: {
      key: "Tags",
      value: (item) =>
        item.length ? JSON.stringify(item.map((tag) => tag.name)) : "[]",
    },
    funnels: {
      key: "Funnels",
      value: (item) =>
        item.length ? JSON.stringify(item.map((funnel) => funnel.name)) : "[]",
    },
  },

  props: {
    id: [Number, String],
  },

  components: {
    FunnelDisplay,
    TagsDisplay,
    TagsInput,
    FunnelMultiInput,
  },

  data() {
    return {
      totalContacts: 0,
      loading: false,
      removingMedia: false,
      media: null,
      fields: [
        "name",
        "templateName",
        "templateType",
        "mediaId",
        "templateLanguageCode",
        "quickReplyId",
        { name: "tagIds", value: [] },
        { name: "funnelIds", value: [] },
      ],
    };
  },

  computed: {
    isApi() {
      return this.$route.params.via == "api";
    },
  },

  mounted() {
    if (this.id == "+") {
      this.getTotalContactCount(null);
    }
  },

  methods: {
    getQuickReplies() {
      return this.$query(quickReplies).then(
        ({
          data: {
            quickReplies: { data },
          },
        }) => {
          return data.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });
        }
      );
    },

    getTotalContactCount(state) {
      this.loading = true;
      this.$query(getContacts, {
        filters: {
          tagIds: state?.tagIds.length ? state?.tagIds : null,
          funnelIds: state?.funnelIds.length ? state?.funnelIds : null,
        },
      })
        .then(({ data: { contacts } }) => {
          this.totalContacts = contacts.pagination.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    get(id) {
      return this.$query(this.isApi ? getApi : getExt, {
        id,
      }).then(({ data }) => {
        const broadcast = this.isApi
          ? data.whatsappCloudApiBroadcast
          : data.whatsappExtensionBroadcast;

        this.$emit("item", broadcast);

        this.media = broadcast?.media;

        return {
          values: {
            ...broadcast,
            funnelIds: broadcast.funnels?.map((item) => item.id),
            tagIds: broadcast.tags?.map((item) => item.id),
            quickReplyId: broadcast.quickReply?.id,
            templateName: broadcast.template?.name,
            templateType: broadcast.template?.type,
            mediaId: broadcast.media?.id,
            templateLanguageCode: broadcast.template?.languageCode,
          },
          res: data,
        };
      });
    },

    async save(id, data) {
      if (this.isApi) {
        delete data.quickReplyId;

        if (data.templateType !== "text" && !this.media) {
          this.$vayu.notify({
            title: "Validation Error",
            message: "Please upload media file to continue!",
            state: "danger",
            duration: 4000,
          });
          return;
        }

        if (this.media) {
          const { dataUrl, fileName } = await fileToBase64(this.media);
          data.mediaId = await this.upload(dataUrl, fileName);
        } else {
          if (data.mediaId) {
            await deleteMedia([data.mediaId]);
            data.mediaId = null;
          }
        }
      } else {
        delete data.mediaId;
        delete data.templateType;
        delete data.templateName;
        delete data.templateLanguageCode;
      }

      return this.$mutate(this.isApi ? upsertApi : upsertExt, {
        id,
        input: data,
      })
        .then(({ data }) => {
          const api = this.isApi
            ? data.upsertWhatsappCloudApiBroadcast
            : data.upsertWhatsappExtensionBroadcast;
          if (id) {
            this.$track(
              this.isApi ? "API Broadcast Updated" : "Ext Broadcast Updated",
              {
                "Broadcast Name": api.name || "",
                "Template Name": api.template?.name || "",
                "Template Type": api.template?.type || "",
                "Template Language Code": api.template?.languageCode || "",
              }
            );
          } else {
            this.$track(
              this.isApi ? "API Broadcast Created" : "Ext Broadcast Created",
              {
                "Broadcast Name": api.name || "",
                "Number of contacts": api.summary.recipients || "",
                Funnels: api.funnels.map((res) => res.name).join(",") || "",
                Tag: api.tags.map((res) => res.name).join(",") || "",
                "Template Name": api.template?.name || "",
                "Template Type": api.template?.type || "",
                "Template Language Code": api.template?.languageCode || "",
              }
            );
          }

          this.$router.push({
            name: "whatsapp-broadcast",
            params: {
              via: this.$route.params.via,
              id: this.isApi
                ? data.upsertWhatsappCloudApiBroadcast.id
                : data.upsertWhatsappExtensionBroadcast.id,
            },
          });
          return data;
        })
        .catch(() => {});
    },

    del(id, action) {
      return this.$mutate(move, {
        id: parseInt(id),
        action,
      }).then(() => {
        this.$track("API Broadcast Deleted", {
          "Broadcast Name":
            this.$refs.broadcastForm.$refs["vue-form"].state.name || "",
        });

        this.$router.push({
          name: "whatsapp-broadcasts",
          params: { via: this.isApi ? "api" : "ext" },
        });
      });
    },

    async upload(dataUrl, fileName) {
      try {
        const uploadedFile = await createMedia(
          "campaign_broadcast",
          [dataUrl],
          fileName
        );
        return uploadedFile[0].id;
      } catch (err) {
        console.error(err);
        return null;
      }
    },
  },
};
</script>
