<template>
  <div class="vp-h-full">
    <!-- Header Buttons -->
    <VpHeaderActions>
      <VpAddItem :feature="feature" route="tag" />
    </VpHeaderActions>

    <!-- List -->
    <VpList
      :per-page="25"
      :columns="columns"
      endpoint="tags"
      :filters.sync="filters"
      :reorder="true"
      class="vp-h-full"
      ref="list"
      :request-payload="{ query: $options.tags }"
      title="Tags"
      @reorder="reorder('tags', $event)"
      @rowClick="
        $router.push({
          name: 'tag',
          params: {
            id: $event.id,
          },
        })
      "
      @refresh="feature.refresh"
      feature="TAG"
    >
      <template #empty>
        <VpListEmpty :feature="feature" route="tag" />
      </template>

      <template #filters>
        <ListFilterStatus v-model="filters.status" :options="statusOptions" />
        <VpField label="Store Visibility">
          <VpRadioGroup
            name="display"
            stack
            :options="displayOnStoreOptions"
            v-model="filters.shouldDisplayOnStore"
          />
        </VpField>
        <VpListFilterArchived v-model="filters.archived" />
      </template>

      <template #name="{ item }">
        <LockedItem :item="item" />
        <p>{{ item.name }}</p>
      </template>

      <template #status="{ item }">
        <VpStatus :value="item.status" />
      </template>
      <template #color="{ item }">
        <ColorPicker readonly :value="item.color" />
      </template>
      <template #type="{ item }">
        <Types readonly :value="item.type" />
      </template>
    </VpList>
  </div>
</template>

<script>
import { Help } from "icons/icons.js";
import LockedItem from "components/src/vp-list/locked-item.vue";

import ColorPicker from "components/src/color/picker.vue";
import ListFilterStatus from "components/src/vp-list/filter-status.vue";
import reorder from "mixins/reorder";
import tags from "graph/tag/collection.gql";

import Types from "./_Types.vue";

export default {
  tags,
  icons: {
    Help,
  },
  props: {
    feature: Object,
  },
  mixins: [reorder],
  components: {
    Types,
    ColorPicker,
    ListFilterStatus,
    LockedItem,
  },
  data() {
    return {
      filters: {
        archived: false,
        status: "",
        shouldDisplayOnStore: null,
      },
      statusOptions: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Inactive",
          value: "inactive",
        },
      ],
    };
  },
  computed: {
    displayOnStoreOptions() {
      return [
        { label: "All", value: null },
        { label: "Accessible", value: true },
        { label: "Not Accessible", value: false },
      ];
    },
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "color", label: "Color", fix: true, type: "string" },
        {
          name: "name",
          label: "Name",
          type: "string",
        },
        { name: "type", label: "Tag For", type: "string" },
        { name: "status", label: "Status", sortable: true, type: "string" },
        {
          name: "taggedItemsCount",
          label: "Tagged Items",
          type: "string",
        },
      ];
    },
  },
};
</script>
