<template>
  <VpModal _class="vp-w-1/3" name="add-contact" title="Add Contact">
    <div class="vp-p-6">
      <VpVueForm
        :fields="fields"
        :id="null"
        class="vp-p-0"
        :save="save"
        :track="track"
        track-prefix="Contact"
        collection-cache="contacts"
        ref="item"
        feature="CONTACT"
      >
        <template #default="{ state, setValue }">
          <VpField label="Name" rules="required" name="Name">
            <VpInput>
              <VpTextBox v-model="state.name" />
            </VpInput>
          </VpField>

          <MobileNumberInput
            ref="mobileNumber"
            @input="setValue('mobileNumberWithCallingCode', $event)"
          />
        </template>

        <template #footer="{ saveItem, isSaving, reset, resetValidation }">
          <VpLoaderContainer
            :loading="isSaving"
            v-if="isSaving"
            class="vp-h-9 vp-w-full"
          />
          <template v-else>
            <VyButton
              label="Add"
              color="primary"
              @click.native="
                lastAction = 'Continue Action';
                add(saveItem, 'continue', reset, resetValidation);
              "
              class="button--primary button--solid button--md button--rounded"
            />
            <VyButton
              label="Add & Go to Profile"
              @click.native="
                lastAction = 'Redirect To Profile';
                add(saveItem, 'go');
              "
              class="vp-ml-2 button--gray button--muted button--md button--rounded vp-whitespace-nowrap"
            />
          </template>
        </template>
      </VpVueForm>
    </div>
  </VpModal>
</template>

<script>
import { mapGetters } from "vuex";

import MobileNumberInput from "../mobile-number-input.vue";
import insert from "graph/contact/insert.gql";
import feature from "../feature/get.gql";

export default {
  components: {
    MobileNumberInput,
  },

  data() {
    return {
      fields: ["name", "mobileNumberWithCallingCode"],
      lastAction: "Default Action",
      lastId: null,
      usage: null,
    };
  },

  props: ["feature"],

  computed: {
    ...mapGetters({
      isSubscriptionLimitExceeded: "user/isSubscriptionLimitExceeded",
      checkPermission: "user/checkPermission",
    }),
    track() {
      return {
        id: "Id",
        name: "Name",
        mobileNumber: {
          key: "Mobile Number",
          value: (item) => item?.internationalFormat ?? "",
        },
        action: {
          key: "On Create Action",
          value: () => this.lastAction,
        },
      };
    },
  },

  methods: {
    save(id, data) {
      return new Promise((resolve, reject) => {
        this.checkPermission("CONTACT", "upsert", () => {
          this.isSubscriptionLimitExceeded("CONTACT", false, this.usage, () => {
            this.$mutate(insert, {
              input: data,
            })
              .then(({ data }) => {
                this.lastId = data.insertContact.id;
                resolve(data);
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      });
    },

    clearCache() {
      this.$cache.evict({
        id: "ROOT_QUERY",
        fieldName: "features",
      });
      this.$query(feature, {
        key: "CONTACT",
      }).then(({ data }) => {
        this.usage = data.features.data[0].usage;
        this.$emit("updateUsage", data.features.data[0].usage);
      });
    },

    add(saveItem, next, reset, resetValidation) {
      return saveItem().then((res) => {
        if (res) {
          switch (next) {
            case "go":
              this.clearCache();
              this.$router.push({
                name: "contact-profile",
                params: {
                  id: this.lastId,
                },
              });
              break;
            default:
              if (res) {
                this.clearCache();
                reset();
                resetValidation();
                this.$refs.mobileNumber.reset();
                this.$emit("refresh");
              }
              break;
          }
        }
      });
    },
  },
};
</script>
