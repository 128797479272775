<template>
  <VpListLayout>
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="message-audio"
      :filters.sync="filters"
      :params="params"
      class="saved vp-h-full saved--audio"
      ref="list"
      title="Audios"
      @rowClick="open($event)"
      :search="false"
      feature="MESSAGE"
      cache="messages"
      :request-payload="{ query: $options.messagesQuery }"
    >
      <template #empty>
        <VpErrorPage
          title="Audio Not Found!"
          desc="All audio saved from WhatsApp Web using the Vepaar extension's save button will be displayed here."
        />
      </template>
      <template #filters>
        <VpDateRangePicker
          stack
          label="Order Date"
          :value="[filters.startDate, filters.endDate]"
          @input="
            filters.startDate =
              $event[0] ?? String(Math.floor(new Date().getTime() / 1000));
            filters.endDate =
              $event[1] ?? String(Math.floor(new Date().getTime() / 1000));
          "
        />

        <VpField label="Tags">
          <TagsInput v-model="filters.tags" type="audio" />
        </VpField>
      </template>

      <template #body="{ item }">
        <p class="saved__body">{{ item.body }}</p>
      </template>
      <template #convo="{ item }">
        <div class="vp-flex vp-items-center vp-flex-nowrap">
          <div>
            <VpConnection
              :type="item.sender && item.sender.contactType"
              style="width: 200px"
              v-if="item.direction == 'In'"
              :data="item.sender"
            />
            <VyButton
              v-else
              label="You"
              class="button--gray button--muted button--pill button--sm"
            />
          </div>
          <div class="vp-px-4">
            <VpIcon
              class="vp-text-gray-400 vp-w-6 vp-h-6"
              :name="$options.icons.ArrowRight"
            />
          </div>
          <div>
            <VpConnection
              :type="item.receiver && item.receiver.contactType"
              style="width: 200px"
              v-if="item.direction == 'Out'"
              :data="item.receiver"
            />
            <VyButton
              v-else
              label="You"
              class="button--gray button--muted button--pill button--sm"
            />
          </div>
        </div>
      </template>

      <template #tags="{ item }">
        <TagsDisplay :value="item.tags" />
      </template>

      <template #time="{ item }">
        <div class="vp-text-xs vp-text-gray-500 vp-whitespace-nowrap">
          <div class="vp-flex vp-items-center vp-flex-nowrap">
            <p>Received</p>
            <VpDatetime class="vp-ml-1" :value="item.receivedOn" />
          </div>
          <div class="vp-mt-1 vp-flex vp-items-center vp-flex-nowrap">
            <p>Saved</p>
            <VpDatetime class="vp-ml-1" :value="item.savedOn" />
          </div>
        </div>
      </template>
    </VpList>

    <message-modal
      type="audio"
      :data="openMessage"
      @refresh="$refs.list.refresh()"
    />
  </VpListLayout>
</template>

<script>
import TagsDisplay from "components/src/tags/display.vue";
import TagsInput from "components/src/tags/input.vue";

import filesize from "filesize";
import MessageModal from "../_Modal.vue";
import { ArrowRight } from "icons/icons.js";
import messagesQuery from "@/views/crm/messages/collection.gql";

export default {
  messagesQuery,
  icons: {
    ArrowRight,
  },
  components: {
    MessageModal,
    TagsDisplay,
    TagsInput,
  },
  data() {
    return {
      filters: {
        startDate: null,
        endDate: null,
        tags: [],
      },
      openMessage: null,
    };
  },
  computed: {
    columns() {
      return [
        { name: "convo", label: "From > To", fix: true },
        {
          name: "duration",
          value: (item) => item.media?.duration,
        },
        {
          name: "size",
          value: (item) => filesize(item.media?.size),
        },
        {
          name: "tags",
          label: "Tags",
        },
        {
          name: "time",
          label: "Time",
          fix: true,
        },
      ];
    },
    params() {
      return {
        type: "audio",
        //TODO: Apply direction filter
        //direction: 2,
      };
    },
  },
  methods: {
    open(message) {
      this.openMessage = message;
      this.$nextTick(() => {
        this.$vayu.modal.open("saved-modal");
      });
    },
  },
};
</script>
