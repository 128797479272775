<template>
  <AppLayout>
    <div class="vp-flex vp-flex-col vp-justify-between">
      <div class="vp-flex vp-justify-center vp-h-full vp-overflow-auto">
        <TheFeatures class="vp-mt-14" />
      </div>
      <GetStartedCta>
        What are you waiting for? Start setting up your CRM now!
        <template #action>
          <VpButtonPill
            @click.native="$router.push({ name: 'get-started' })"
            class="vp-flex-row-reverse vp-gap-2"
            :icon="$options.icons.ArrowRight"
            label="Get Started"
          />
        </template>
      </GetStartedCta>
    </div>
  </AppLayout>
</template>

<script>
import TheFeatures from "./_features.vue";
import { ArrowRight } from "icons/icons.js";
import GetStartedCta from "components/src/get-started-cta.vue";
import AppLayout from "@/components/app-layout.vue";

export default {
  icons: {
    ArrowRight,
  },
  components: {
    AppLayout,
    GetStartedCta,
    TheFeatures,
  },
};
</script>
