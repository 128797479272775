<template>
  <VpModal
    _class="vp-w-1/3 vp-rounded-xl"
    name="contact-activity-modal"
    :title="title"
    @open="open($event)"
  >
    <div class="vp-p-4">
      <VpVueForm
        :fields="['note']"
        :id="id"
        class="vp-p-0"
        :save="save"
        :get="get"
        :del="del"
        collection-cache="contactActivities"
        :track="$options.track"
        track-prefix="Contact Activity"
        feature="CONTACT"
      >
        <template #default="{ state }">
          <VpField name="Note" rules="required">
            <Tiptap
              v-model="state.note"
              :isEditable="checkPermission('CONTACT', 'upsert')"
              size="lg"
            />
          </VpField>
        </template>
      </VpVueForm>
    </div>
  </VpModal>
</template>

<script>
import { mapGetters } from "vuex";
import Tiptap from "../../tiptap/editor.vue";
import upsert from "graph/contact/activity/upsert.gql";
import del from "graph/contact/activity/delete.gql";
import { timeStampToISOString } from "plugins/utils";

export default {
  track: {
    type: "Type",
    id: "Id",
    author: {
      key: "Author",
      value: (author) =>
        author?.name ?? author?.mobileNumber.internationalFormat ?? "",
    },
    createdAt: {
      key: "Created At",
      value: (createdAt) => timeStampToISOString(createdAt),
    },
    note: "Note",
  },
  components: {
    Tiptap,
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
  },
  data() {
    return {
      id: null,
      note: null,
      contactId: null,
      title: null,
    };
  },
  methods: {
    open({ scope: { id, note, contactId } }) {
      this.id = id;
      this.note = note;
      this.contactId = contactId;
      this.title = id === "+" ? "Add Note" : "Edit Note";
    },
    get() {
      const res = {
        contactNote: {
          note: this.note,
        },
      };
      return new Promise((resolve) =>
        resolve({
          values: res.contactNote,
          res,
        })
      );
    },
    save(id, data) {
      return this.$mutate(upsert, {
        contactId: this.contactId,
        id,
        input: data,
      }).then(({ data }) => {
        this.$emit("modal-close");
        this.$vayu.modal.close("contact-activity-modal");
        return {
          upsertContactNote: {
            ...data.upsertContactNote,
            ...data.upsertContactNote.note,
          },
        };
      });
    },
    del(id) {
      return this.$mutate(del, {
        contactId: this.contactId,
        id,
      }).then(({ data }) => {
        this.$emit("modal-close");
        this.$vayu.modal.close("contact-activity-modal");
        return data;
      });
    },
  },
};
</script>
