<template>
  <Root class="vepaar">
    <RouterView />

    <!-- Public Containers -->
    <VyNotificationPort
      name="default"
      class="port--top port--right vp-p-4 vp-z-50"
    />
    <PortalTarget
      id="slideover-container"
      name="slideover-container"
      multiple
    />
    <VyCopy />

    <VyPort name="modal" has-overlay class="port--middle port--center z-40" />

    <VyPort name="confirm" class="port--middle port--center z-50" has-overlay />
    <Confirm />
    <ConfirmDelete />
    <UnauthorizedAccessModal />
    <UpgradeNoticeModal />
    <ProFeatureModal />
    <LegacyModal />

    <VyConfirm
      name="default"
      _class="vp-w-1/2 vp-rounded-xl"
      title="Are you sure?"
      message="Are you sure?"
    />
  </Root>
</template>

<script>
import ProFeatureModal from "components/src/pro-feature-modal.vue";
import Root from "components/src/root.vue";
import UnauthorizedAccessModal from "components/src/unauthorized-access-modal.vue";
import UpgradeNoticeModal from "components/src/upgrade-notice-modal.vue";

import LegacyModal from "components/src/account/legacy-modal.vue";
import ConfirmDelete from "@/components/confirm/delete.vue";
import Confirm from "@/components/confirm/index.vue";

export default {
  components: {
    LegacyModal,
    UpgradeNoticeModal,
    ProFeatureModal,
    UnauthorizedAccessModal,
    Root,
    ConfirmDelete,
    Confirm,
  },
};
</script>

<style>
.vepaar {
  height: 100vh;
}
</style>
