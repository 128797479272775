<template>
  <VpVueForm
    id="true"
    :fields="fields"
    class="vp-p-6"
    :get="get"
    width="400px"
    ref="item"
    :save="save"
    #default="{ state }"
    :track="$options.track"
    track-prefix="Integration"
    track-event-name="Configured"
    collection-cache="integrations"
  >
    <VpPageHeader title="Odoo" />

    <VpStatusInput id="odoo-status" v-model="state.status" />

    <VpField rules="required" label="Database">
      <VpInput>
        <VpTextBox v-model="state.database" />
      </VpInput>
    </VpField>

    <VpField rules="required" label="Username">
      <VpInput>
        <VpTextBox v-model="state.username" />
      </VpInput>
    </VpField>

    <VpField rules="required" label="API Key">
      <VpInput>
        <VpTextBox v-model="state.apiKey" />
      </VpInput>
    </VpField>
  </VpVueForm>
</template>

<script>
import { ODOO_GET, ODOO_UPSERT } from "./gql";

export default {
  data() {
    return {
      fields: ["database", "username", "apiKey", "status"],
    };
  },

  methods: {
    get() {
      return this.$query(ODOO_GET).then(({ data }) => {
        const {
          integration: {
            odoo: { status, user },
          },
        } = data;

        return {
          values: {
            status: status,
            database: user.database,
            username: user.username,
            apiKey: user.apiKey,
          },
          res: data,
        };
      });
    },

    save(id, data) {
      return this.$mutate(ODOO_UPSERT, {
        input: data,
      }).then(() => {
        this.$router.push({ name: "integrations" });
      });
    },
  },
};
</script>
