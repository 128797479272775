<template>
  <div class="vp-h-full">
    <!-- Header Buttons -->
    <VpHeaderActions>
      <VpAddItem :feature="feature" route="funnel" />
    </VpHeaderActions>

    <!-- List -->
    <VpList
      :per-page="25"
      :columns="columns"
      endpoint="funnels"
      :request-payload="{ query: $options.funnels }"
      :filters.sync="filters"
      :reorder="true"
      class="vp-h-full"
      ref="list"
      title="Funnels"
      @reorder="reorder('funnels', $event)"
      @rowClick="
        $router.push({
          name: 'funnel',
          params: {
            id: $event.id,
          },
        })
      "
      feature="FUNNEL"
      @refresh="feature.refresh"
    >
      <template #empty>
        <VpListEmpty :feature="feature" route="funnel" />
      </template>

      <template #filters>
        <VpListFilterArchived v-model="filters.archived" />
      </template>
      <template #name="{ item }">
        <LockedItem :item="item" />
        <p>{{ item.name }}</p>
      </template>
      <template #color="{ item }">
        <ColorPicker :value="item.color" readonly />
      </template>
    </VpList>
  </div>
</template>

<script>
import { Help } from "icons/icons.js";
import { mapGetters } from "vuex";

import ColorPicker from "components/src/color/picker.vue";
import reorder from "mixins/reorder.js";
import LockedItem from "components/src/vp-list/locked-item.vue";
import funnels from "@/views/crm/funnels/collection.gql";

export default {
  funnels,
  icons: {
    Help,
  },
  props: {
    feature: Object,
  },
  mixins: [reorder],
  components: {
    ColorPicker,
    LockedItem,
  },
  data() {
    return {
      filters: {
        archived: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),

    columns() {
      return [
        { name: "_index", fix: true, label: "#" },
        { name: "color", fix: true, type: "string" },
        {
          name: "name",
          sortable: true,
          type: "string",
        },
        { name: "contactCount", label: "Contacts", fix: true, type: "string" },
      ];
    },
  },
};
</script>
