<template>
  <VpListLayout>
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="whatsappBroadcastContacts"
      :filters.sync="filters"
      :search="false"
      :params="params"
      title="Contacts"
      class="vp-h-full"
      bulk
      cache="whatsappBroadcastContacts"
      ref="list"
      :request-payload="{ query: $options.whatsappBroadcastContacts }"
      feature="WHATSAPP_BROADCAST"
    >
      <template #filters>
        <VpField label="Status">
          <VpDropdown
            v-model="filters.status"
            :options="statusOptions"
            :unselect="false"
          />
        </VpField>
      </template>
      <template #profilePicture="{ item: { contact } }">
        <VpAvatar
          class="vp-rounded-md"
          :image="contact.profilePicture && contact.profilePicture.url"
          :name="contact.name.length ? contact.name : '#'"
          size="sm"
        />
      </template>
      <template #name="{ item: { contact } }">
        {{ contact.name || (contact.whatsapp && contact.whatsapp.name) }}
      </template>

      <template #mobile="{ item: { contact } }">
        <p class="vp-whitespace-nowrap">
          {{ contact.mobileNumber.internationalFormat }}
        </p>
      </template>

      <template #status="{ item }">
        <DeliveryStatus :value="item.status" />
      </template>

      <!-- BULK ACTIONS -->
      <template #_bulk="{ count, ids }">
        <VpBulkAction
          label="Remove From List"
          :icon="$options.icons.Delete"
          @click="
            checkPermission('WHATSAPP_BROADCAST', 'delete', () => {
              del(ids, count);
            })
          "
        />
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import { Delete } from "icons/icons.js";
import { mapGetters } from "vuex";

import deleteContacts from "./delete-contacts.gql";

import DeliveryStatus from "./_DeliveryStatus.vue";
import whatsappBroadcastContacts from "@/views/crm/marketing/whatsapp-broadcast/contacts.gql";

export default {
  whatsappBroadcastContacts,
  icons: {
    Delete,
  },
  props: ["id", "broadcastName"],
  components: {
    DeliveryStatus,
  },
  data() {
    return {
      filters: {
        status: null,
      },
      columns: [
        { name: "profilePicture", label: "Photo", fix: true, type: "string" },
        { name: "name", type: "string" },
        { name: "mobile", type: "string" },
        { name: "status", type: "string", fix: true },
      ],
    };
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
    params() {
      return {
        broadcastId: this.id,
      };
    },
    statusOptions() {
      return [
        {
          id: "pending",
          label: "Pending",
          color: "warning",
        },
        {
          id: "success",
          label: "Delivered",
          color: "success",
        },
        {
          id: "failed",
          label: "Failed",
          color: "danger",
        },
      ];
    },
  },

  methods: {
    del(ids, count) {
      const isConfirm = confirm(
        `Are you sure you want to delete ${count} broadcast contacts?`
      );
      if (isConfirm) {
        this.$mutate(deleteContacts, {
          ...this.params,
          ids,
        }).then(() => {
          this.$track("Bulk Action Broadcast Contact Deleted", {
            "Number Of Broadcast Contact Deleted": ids.length,
            "Broadcast Name": this.broadcastName,
          });
          this.$refs.list.refresh();
        });
      }
    },

    segmentAnalytics(contact) {
      const { id, name, mobileNumber, whatsapp, funnel, tags } = contact;
      this.$track("Broadcast Contact Deleted", {
        "Broadcast Contact Id": id,
        "Broadcast Contact Name": name || (whatsapp && whatsapp.name),
        "Broadcast Contact Mobile Number": mobileNumber.internationalFormat,
        "Broadcast Contact Funnel": funnel?.name ?? "",
        "Broadcast Contact Tags":
          tags.length !== 0 ? JSON.stringify(tags.map((i) => i.name)) : "[]",
      });
    },
  },
};
</script>
