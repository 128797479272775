<template>
  <VyButton
    v-bind="$attrs"
    class="button--md button--muted button--square button--gray vp-relative"
  />
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>
