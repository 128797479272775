<template>
  <div class="vp-h-full">
    <ExportModal
      endpoint="chat"
      history-route="exports"
      :params="{ ...params }"
      title="Chats"
      feature="CHAT_EXPORT"
      :isDuration="true"
    />
    <portal to="header">
      <div class="vp-space-x-2 vp-flex vp-items-center">
        <VpPortalButton
          label="Export"
          @click.native="
            checkPermission('CHAT_EXPORT', 'read', () => {
              $vayu.modal.open('export');
            })
          "
          class="vp-ml-2"
        />

        <VyButton
          type="button"
          :icon="$options.icons.Refresh"
          @click.native="refresh()"
          class="button--gray button--muted button--square button--sm button--rounded"
        />

        <VyButton
          type="button"
          :icon="$options.icons.Delete"
          @click.native="
            checkPermission('CHAT', 'delete', () => {
              $vayu.modal.open('delete-chat');
            })
          "
          :loading="deleting"
          class="button--danger button--muted button--square button--sm button--rounded"
        />
      </div>
    </portal>
    <div class="vp-h-full vp-bg-gray-200 vp-overflow-auto">
      <VpList
        :columns="[]"
        endpoint="chatMessage"
        :params="params"
        class="vp-relative vp-p-6"
        :infinite="true"
        feature="CHAT"
        ref="list"
        :header="false"
        :request-payload="{ query: $options.chatMessage }"
      >
        <template #default="{ items }">
          <div class="vp-flex vp-flex-col-reverse">
            <!-- BUBBLE -->
            <div
              v-for="item in items"
              class="vp-mb-4 vp-flex vp-relative"
              :class="{ 'vp-justify-end': isOut(item) }"
              :key="item.id"
            >
              <!-- ARROW -->
              <div
                class="vp-w-0 vp-h-0 vp-border-4 vp-absolute vp-top-0"
                :class="{
                  'vp-border-r-white vp-border-t-white -vp-left-2': isIn(item),
                  'vp-border-l-green-500 vp-border-t-green-500 -vp-right-2':
                    isOut(item),
                }"
              ></div>
              <div
                class="convo__bubble vp-p-4 vp-relative vp-max-w-[60%]"
                :class="{
                  'vp-bg-white vp-rounded-r-xl vp-rounded-bl-xl': isIn(item),
                  'vp-bg-green-500 vp-text-white vp-rounded-l-xl vp-rounded-br-xl':
                    isOut(item),
                }"
              >
                <header
                  class="vp-mb-1 vp-font-semibold"
                  :class="{
                    'vp-text-green-900': isOut(item),
                    [senderColor(
                      item.sender?.mobileNumber?.numberWithCallingCode
                    )]: isIn(item),
                  }"
                >
                  {{
                    item.sender?.name ||
                    item.sender?.mobileNumber?.internationalFormat
                  }}
                </header>
                <p class="convo__body vp-whitespace-pre-line vp-break-words">
                  {{ item.text }}
                </p>

                <VpDatetime
                  class="vp-mt-4 vp-text-xs vp-inline-block"
                  :value="item.receivedOn"
                  :class="{
                    'vp-text-gray-500': isIn(item),
                    'vp-text-green-100': isOut(item),
                  }"
                />
                <br />
                <span
                  class="vp-text-xs vp-inline-block"
                  :class="{
                    'vp-text-gray-500': isIn(item),
                    'vp-text-green-100': isOut(item),
                  }"
                >
                  {{ item.sender?.mobileNumber?.internationalFormat }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </VpList>
    </div>

    <Delete :id="id" @delete="afterChatDelete" />
  </div>
</template>

<script>
import ExportModal from "components/src/export-modal.vue";
import { Delete as DeleteIcon, Refresh } from "icons/icons.js";
import { mapGetters } from "vuex";

import Delete from "./_Delete.vue";
import chatMessage from "@/views/crm/chats/message/collection.gql";

const colors = [
  "vp-text-red-500",
  "vp-text-pink-500",
  "vp-text-purple-500",
  "vp-text-indigo-500",
  "vp-text-blue-500",
  "vp-text-cyan-500",
  "vp-text-green-500",
  "vp-text-lime-500",
  "vp-text-amber-500",
  "vp-text-orange-500",
];

export default {
  chatMessage,
  icons: {
    Refresh,
    Delete: DeleteIcon,
  },
  props: {
    id: [Number, String],
    contact: Object,
  },
  components: {
    Delete,
    ExportModal,
  },
  data() {
    return {
      deleting: false,
    };
  },
  created() {
    this.updateBreadcrumbs();
  },
  watch: {
    contact() {
      this.updateBreadcrumbs();
    },
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),

    params() {
      return {
        contactId: Number(this.id),
      };
    },
  },
  methods: {
    isOut(message) {
      return message.direction == "Out";
    },
    isIn(message) {
      return message.direction == "In";
    },
    senderColor(contactId) {
      return colors[String(contactId).charAt(5)];
    },

    refresh() {
      this.$cache.evict({
        id: "ROOT_QUERY",
        fieldName: "chatMessages",
      });
      this.$refs.list.refresh();
    },

    afterChatDelete() {
      this.$track("Chat Deleted", {
        "Chat Contact Type": this.contact.contactType,
        "Chat Contact Id": this.contact.id,
        "Chat Contact Name":
          this.contact?.name ??
          this.contact?.mobileNumber?.internationalFormat ??
          "",
      });
      this.$cache.evict({
        id: "ROOT_QUERY",
        fieldName: "chatMessages",
      });
      this.$router.push({ name: "chats" });
      this.$emit("refresh");
    },

    updateBreadcrumbs() {
      if (this.contact?.name) {
        this.$root.$emit(
          "breadcrumbs-label",
          this.$route.name,
          this.contact.name
        );
      }
    },
  },
};
</script>
