import Vue from "vue";
import VueRouter from "vue-router";
import privateRoutes from "./private";
import { pageView } from "plugins/segment";
import { getToken, getOS } from "plugins/utils";
import AuthIndex from "@/views/auth/Index.vue";
const { VITE_WEB_URL } = import.meta.env;
// Open Routes coming from Extension
import Redirect from "components/src/redirect.vue";
import BizSetup from "@/views/onboard/crm-setup.vue";
import Welcome from "@/views/onboard/welcome.vue";

Vue.use(VueRouter);
const os = getOS();

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/get-started",
    name: "get-started",
    component: BizSetup,
  },
  {
    path: "/welcome",
    name: "welcome",
    component: Welcome,
  },

  {
    path: "/",
    component: AuthIndex,
    props: true,
    meta: {
      public: false,
    },
    children: privateRoutes,
  },
  {
    path: "/redirect",
    name: "redirect",
    component: Redirect,
    meta: {
      public: true,
    },
  },
];

const router = new VueRouter({
  routes,
  mode: "hash",
});

/**========================================================================
 * *                              NOTE
 * We don't allow users from mobile devices hence, the first thing before
 * entering the route should be to check if it is mobile OS
 *========================================================================**/
router.beforeEach(async (from, to, next) => {
  if (
    ["android", "ios"].includes(os) &&
    from.name != "download" &&
    from.name != "verify-account"
  ) {
    next({
      name: "download",
    });
  } else {
    if (from.params.id && from.params.id !== "+") {
      from.params.id = parseInt(from.params.id);
    }

    if (from.meta.public === true) {
      next();
    } else {
      if (getToken()) {
        next();
      } else {
        window.open(VITE_WEB_URL, "_self");
      }
    }
  }
});

router.afterEach((to, from) => {
  if (router.app.isSubscriptionActive) {
    pageView(to, from);
  }
  if (
    ![
      "login",
      "signup",
      "logout",
      "help",
      "verify-account",
      "forgot-password",
    ].includes(to.name)
  ) {
    localStorage.setItem("vp-last-path", to.path);
  }
});

export default router;
