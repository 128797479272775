<template>
  <VpSubShell>
    <template #nav>
      <VpSubNavItem
        label="About"
        exact
        :icon="$options.icons.About"
        :to="{ name: 'biz-info' }"
      />

      <VpSubNavItem
        label="Quick Replies"
        :icon="$options.icons.QuickReply"
        :to="{ name: 'quick-replies' }"
      />
      <VpSubNavItem
        label="Funnels"
        :icon="$options.icons.Funnel"
        :to="{ name: 'funnels' }"
      />
      <VpSubNavItem
        label="Tags"
        :icon="$options.icons.Tag"
        :to="{ name: 'tags' }"
      />
      <VpSubNavItem
        label="Tiers"
        :icon="$options.icons.Tiers"
        :to="{ name: 'tiers' }"
      />
      <VpSubNavItem
        label="Ticket Status"
        :icon="$options.icons.TicketStatus"
        :to="{ name: 'ticket-statuses' }"
      />
      <VpSubNavItem
        label="Integrations"
        :icon="$options.icons.Integration"
        :to="{ name: 'integrations' }"
      />

      <VpSubNavItem
        label="My Businesses"
        :icon="$options.icons.Business"
        :to="{ name: 'businesses' }"
      />
    </template>
    <template #default>
      <RouterView />
    </template>
  </VpSubShell>
</template>

<script>
import {
  TicketStatus,
  Tag,
  Funnel,
  QuickReply,
  Integration,
  Settings,
  Business,
  Tiers,
  About,
} from "icons/icons.js";
export default {
  icons: {
    TicketStatus,
    Tag,
    Funnel,
    QuickReply,
    Integration,
    Tiers,
    Settings,
    Business,
    About,
  },
};
</script>
