<template>
  <VpListLayout>
    <VpList
      ref="list"
      endpoint="integrations"
      :request-payload="{ query: $options.integrations }"
      :columns="[]"
      :header="false"
      :search="false"
      :per-page="-1"
      feature="INTEGRATION"
    >
      <template #default="{ items }">
        <div class="vp-p-6">
          <VpPageHeader class="vp-mb-6" title="Integrations.">
            <p>
              Missing something you need? Contact us for a custom integration.
            </p>
          </VpPageHeader>

          <div class="vp-grid vp-grid-cols-3 vp-gap-4">
            <Integration
              v-for="(item, index) in items"
              :data="item"
              :key="`item--${index}`"
            />
          </div>
        </div>
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import Integration from "./_Integration.vue";
import integrations from "@/views/settings/integrations/collection.gql";

export default {
  integrations,
  components: {
    Integration,
  },
};
</script>
