<template>
  <VpBulkAccordion
    label="Change Ticket Status"
    :confirm="`Are you sure you want to change status of ${count} tickets?`"
  >
    <VpVueForm
      :fields="fields"
      :save="save"
      #default="{ state }"
      create-label="Update"
      class="vp-pb-0"
      :has-reset="false"
      collection-cache="tickets"
      feature="TICKET"
    >
      <VpField rules="required" name="Status" label="Status">
        <TicketStatusInput v-model="state.statusId" />
      </VpField>
    </VpVueForm>
  </VpBulkAccordion>
</template>

<script>
import batchUpdate from "@/views/crm/tickets/batch/status.gql";
import TicketStatusInput from "components/src/ticket-status/input.vue";

export default {
  props: {
    ids: Array,
    count: Number,
  },
  components: {
    TicketStatusInput,
  },
  data() {
    return {
      fields: [
        {
          name: "statusId",
          value: null,
        },
      ],
    };
  },
  methods: {
    save(id, input) {
      return this.$mutate(batchUpdate, {
        ids: this.ids,
        input,
      }).then(() => {
        this.$emit("refresh");
      });
    },
  },
};
</script>
