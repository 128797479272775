<template>
  <div class="vp-border-t vp-pt-6 vp-flex">
    <div class="vp-shrink-0">
      <p class="vp-text-md vp-text-gray-500">
        {{ title }}
      </p>
      <h4 class="vp-flex vp-items-center vp-text-lg vp-font-bold">
        <a
          class="vp-text-primary-500 hover:vp-underline"
          :href="url"
          :target="target"
        >
          {{ displayUrl }}
        </a>
        <VyButton
          v-if="editDomain"
          class="vp-ml-2 button--gray button--muted button--circle button--xs"
          :icon="$options.icons.Edit"
          :to="{ name: 'domain' }"
        />
      </h4>
    </div>
  </div>
</template>

<script>
import { Edit } from "icons/icons.js";

export default {
  icons: {
    Edit,
  },
  props: {
    title: String,
    url: String,
    displayUrl: String,
    target: String,
    editDomain: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
