<template>
  <Icon
    v-if="readonly && value"
    :name="$options.icons.Priority"
    v-bind="$attrs"
    class="vp-w-5 vp-h-5"
    :class="$options.colors[color]"
  />
  <p v-else-if="readonly"></p>
  <div v-else class="vp-flex vp-space-x-1">
    <template v-for="(option, index) in priorities">
      <VyButton
        v-if="value == option.value"
        :key="`${option.value}-${index}`"
        type="button"
        :name="$options.icons.Priority"
        :class="$options.buttonColorClasses[option.color]"
        :label="option.label"
        class="button--sm button--solid button--rounded"
        @click.native="$emit('input', null)"
      />
      <VyButton
        v-else
        :key="`${option.value}-${index}`"
        type="button"
        :label="option.label"
        class="button--sm button--muted button--gray button--rounded"
        @click.native="$emit('input', option.value)"
      />
    </template>
  </div>
</template>

<script>
import { Priority } from "icons/icons.js";
import buttonColorClasses from "static/src/button-color-variants.json";

import Icon from "./icon.vue";

const colors = {
  grey: "vp-text-gray-500",
  info: "vp-text-info-500",
  warning: "vp-text-warning-500",
  danger: "vp-text-danger-500",
};

export default {
  components: {
    Icon,
  },
  buttonColorClasses,
  colors,
  icons: {
    Priority,
  },
  props: {
    value: String,
    readonly: Boolean,
  },
  data() {
    return {
      priorities: [
        { value: "low", color: "grey", label: "Low" },
        { value: "medium", color: "info", label: "Medium" },
        { value: "high", color: "warning", label: "High" },
        { value: "urgent", color: "danger", label: "Urgent" },
      ],
    };
  },
  computed: {
    selected() {
      return this.priorities.find((item) => item.value == this.value);
    },
    color() {
      return this.selected?.color;
    },
    label() {
      return this.selected?.label;
    },
  },
};
</script>
