<template>
  <div class="vp-h-full">
    <!-- Header Buttons -->
    <VpHeaderActions>
      <VpAddItem :feature="feature" route="tier" />
    </VpHeaderActions>

    <!-- List -->
    <VpList
      :per-page="25"
      :columns="columns"
      endpoint="tiers"
      :filters.sync="filters"
      :request-payload="{ query: $options.tiers }"
      class="vp-h-full"
      ref="list"
      title="Tiers"
      @rowClick="
        $router.push({
          name: 'tier',
          params: {
            id: $event.id,
          },
        })
      "
      @refresh="feature.refresh"
      feature="TIER"
    >
      <template #empty>
        <VpListEmpty :feature="feature" route="tier" />
      </template>

      <template #filters>
        <VpListFilterArchived v-model="filters.archived" />
      </template>
      <template #name="{ item }">
        <RouterLink
          :to="{
            name: 'tier',
            params: {
              id: item.id,
            },
          }"
        >
          <p>{{ item.name }}</p>
        </RouterLink>
      </template>
      <template #color="{ item }">
        <ColorPicker :value="item.color" readonly />
      </template>
    </VpList>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import tiers from "graph/tier/collection.gql";

import ColorPicker from "components/src/color/picker.vue";
export default {
  tiers,
  components: {
    ColorPicker,
  },
  props: {
    feature: Object,
  },
  data() {
    return {
      filters: {
        archived: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
    columns() {
      return [
        { name: "_index", fix: true, label: "#" },
        { name: "color", fix: true, type: "string" },
        {
          name: "name",
          sortable: true,
          type: "string",
        },
        { name: "contactCount", label: "Contacts", fix: true, type: "string" },
      ];
    },
  },
  methods: {},
};
</script>
