<template>
  <VpRequest :get="get" ref="request" :cache="cache">
    <template #default="{ res, refresh }">
      <div class="vp-p-16 vp-text-center vp-bg-gray-100 vp-border-b">
        <div class="vp-flex vp-justify-center vp-space-x-2">
          <template
            v-if="isApi && checkPermission('WHATSAPP_BROADCAST', 'upsert')"
          >
            <VyButton
              v-if="res.status == 'draft' || res.status == 'start'"
              label="Start Broadcast"
              :icon="$options.icons.Play"
              @click.native="toggle(res, 'start').then(() => refresh())"
              class="button--primary button--solid button--lg button--rounded"
            />

            <VyButton
              v-else-if="res.status == 'paused' || res.status == 'failed'"
              label="Resume"
              :icon="$options.icons.Play"
              @click.native="toggle(res, 'start').then(() => refresh())"
              class="button--primary button--solid button--lg button--rounded"
            />

            <VyButton
              v-else-if="res.status == 'running'"
              label="Pause"
              :icon="$options.icons.Pause"
              @click.native="toggle(res, 'paused').then(() => refresh())"
              class="button--primary button--solid button--lg button--rounded"
            />
          </template>
          <template v-else>
            <VyButton
              label="Open WhatsApp Web"
              :icon="$options.icons.OpenInNew"
              target="whatsapp"
              href="https://web.whatsapp.com"
              class="button--primary button--solid button--lg button--rounded"
            />
            <VyButton
              label="Install Chrome Extension"
              :icon="$options.icons.Chrome"
              target="_blank"
              href="https://vepa.ar/ext"
              class="button--primary button--outline button--lg button--rounded"
            />
          </template>

          <VyButton
            label="Refresh"
            :icon="$options.icons.Refresh"
            @click.native="
              refresh();
              trackEvent(res);
            "
            class="button--primary button--outline button--lg button--rounded"
          />
        </div>
        <p v-if="!isApi" class="vp-text-gray-500 vp-mt-2">
          Vepaar Chrome Extension is required to send Broadcast Messages.
        </p>
      </div>
      <div class="vp-p-6 vp-border-r vp-border-b">
        <VpField :label="`Progress (${res.summary.progress}%)`">
          <ProgressBar :progress="res.summary.progress" />
        </VpField>
      </div>
      <div class="vp-grid vp-grid-cols-3 vp-gap-px vp-bg-gray-100 vp-border-b">
        <IconCard
          :icon="$options.icons.Group"
          desc="Contacts"
          :title="res.summary.recipients"
        />
        <IconCard
          :icon="$options.icons.Check"
          desc="Messages Delivered"
          :title="res.summary.delivered"
        />
        <IconCard
          :icon="$options.icons.Progress"
          desc="Pending Delivery"
          :title="res.summary.pending"
        />
      </div>

      <div class="vp-grid vp-grid-cols-2 vp-gap-px vp-border-b">
        <div class="vp-p-6 vp-border-r">
          <span class="vp-text-gray-500 vp-font-bold block vp-my-4">Tags</span>
          <TagsDisplay v-if="res.tags.length" :value="res.tags" />
          <p v-else class="vp-text-xs vp-text-gray-500">
            No tags are selected.
          </p>
        </div>
        <div class="vp-p-6">
          <span class="vp-text-gray-500 vp-font-bold block vp-my-4">
            Funnel
          </span>
          <div class="vp-flex vp-flex-wrap vp-gap-1" v-if="res.funnels?.length">
            <FunnelDisplay
              v-for="item in res.funnels"
              :key="item.id"
              :value="item"
            />
          </div>
          <p v-else class="vp-text-xs vp-text-gray-500">
            No funnel is selected.
          </p>
        </div>
      </div>
    </template>
  </VpRequest>
</template>

<script>
import {
  Check,
  Chrome,
  Group,
  OpenInNew,
  Pause,
  Play,
  Progress,
  Refresh,
} from "icons/icons.js";
import { mapGetters } from "vuex";

import TagsDisplay from "components/src/tags/display.vue";
import FunnelDisplay from "components/src/funnel/display.vue";

import IconCard from "components/src/dashboard/icon-card.vue";

import ProgressBar from "@/components/progress-bar.vue";
import getApiBroadcast from "./cloud-api/get.gql";
import toggleBroadcast from "./cloud-api/toggle.gql";
import getExtBroadcast from "./extension/get.gql";

export default {
  icons: {
    Check,
    Progress,
    Play,
    Pause,
    OpenInNew,
    Refresh,
    Group,
    Chrome,
  },
  props: {
    id: {},
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),

    isApi() {
      return this.$route.params.via == "api";
    },

    params() {
      return {
        broadcastId: this.id,
      };
    },

    cache() {
      return this.isApi
        ? "whatsappCloudApiBroadcast"
        : "whatsappExtensionBroadcast";
    },
  },

  components: {
    IconCard,
    ProgressBar,
    TagsDisplay,
    FunnelDisplay,
  },
  methods: {
    get() {
      return this.$query(this.isApi ? getApiBroadcast : getExtBroadcast, {
        id: this.id,
      }).then(({ data }) => {
        const broadcast = this.isApi
          ? data.whatsappCloudApiBroadcast
          : data.whatsappExtensionBroadcast;
        this.$emit("item", broadcast);
        return broadcast;
      });
    },

    toggle(res, status) {
      return this.$mutate(toggleBroadcast, {
        id: res.id,
        status,
      }).then(() => {
        this.$track(
          this.isApi
            ? "Broadcast Status Clicked"
            : "Ext Broadcast Status Clicked",
          {
            "Broadcast Name": res.name,
            "Broadcast Status": status,
          }
        );
      });
    },
    trackEvent(res) {
      this.$track("Refresh API Broadcast", {
        "Broadcast Name": res.name,
      });
    },
  },
};
</script>
