<template>
  <VpSubShell full-height class="vp-h-full vp-overflow-hidden">
    <template #before-nav>
      <nav-contact :request="get">
        <VpSubNavItem
          label="Profile"
          :icon="$options.icons.Info"
          :to="{ name: 'contact-profile' }"
        />
        <VpSubNavItem
          :icon="$options.icons.Activity"
          label="Activity"
          :to="{ name: 'contact-activity' }"
          exact
        />
        <VpSubNavItem
          :icon="$options.icons.DonutChart"
          label="Statistics"
          :to="{ name: 'contact-statistics' }"
        />
        <!-- <VpSubNavItem
          :icon="$options.icons.Rating"
          label="Reviews"
          :to="{ name: 'contact-reviews' }"
        /> -->
      </nav-contact>
    </template>

    <template #default>
      <router-view />
    </template>
  </VpSubShell>
</template>

<script>
import overview from "graph/contact/overview.gql";
import NavContact from "components/src/contacts/_Nav.vue";
import { Info, DonutChart, Activity } from "icons/icons.js";

export default {
  icons: {
    Info,
    DonutChart,
    Activity,
  },
  props: {
    id: [Number, String],
  },
  components: {
    NavContact,
  },
  methods: {
    get() {
      return this.$query(overview, {
        id: this.id,
      })
        .then(({ data }) => data.contact)
        .then((data) => {
          const { name, profilePicture, mobileNumber } = data;
          return {
            image: profilePicture?.url,
            title: name,
            desc: mobileNumber?.internationalFormat,
          };
        });
    },
  },
};
</script>
