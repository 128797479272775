<template>
  <div>
    <TheTitle> {{ options.name }} </TheTitle>
    <div v-if="isEmpty" class="vp-w-full vp-h-full vp-m-0 vp-pt-8">
      <VpErrorPage title="No Data Found!" />
    </div>
    <div v-else class="vp-p-4 vp-flex vp-items-center">
      <ApexCharts
        type="donut"
        :width="350"
        :options="chartOptions"
        :series="series"
      />
      <div class="">
        <div class="vp-flex vp-space-x-2 vp-p-1">
          <div>
            <p class="vp-text-xs vp-text-gray-500">No Priorities</p>
            <p class="vp-text-sm">
              {{ priorityValue("priority_not_assign_ticket") }}
            </p>
          </div>
        </div>

        <div
          v-for="priority in $options.priorities"
          :key="priority.value"
          class="vp-flex vp-space-x-2 vp-p-1"
        >
          <div>
            <p class="vp-text-xs" :class="priority.color">
              {{ priority.label }}
            </p>
            <p class="vp-text-sm">{{ priorityValue(priority.value) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { format } from "date-fns";
import TheTitle from "./_Title.vue";

const priorities = [
  { value: "low_priority_ticket", color: "vp-text-gray-500", label: "Low" },
  {
    value: "medium_priority_ticket",
    color: "vp-text-primary-500",
    label: "Medium",
  },
  {
    value: "high_priority_ticket",
    color: "vp-text-warning-500",
    label: "High",
  },
  {
    value: "urgent_priority_ticket",
    color: "vp-text-danger-500",
    label: "Urgent",
  },
];
export default {
  priorities,
  props: {
    values: Array,
    options: Object,
  },
  data() {
    return {
      format,
    };
  },
  components: {
    ApexCharts: () => import("vue-apexcharts"),

    TheTitle,
  },
  computed: {
    chartOptions() {
      return {
        chart: { type: "donut" },
        legend: { show: false },
        dataLabels: { enabled: false },
        tooltip: { enabled: false },
        colors: ["#F5F5F5", "#607d8b", "#304ffe", "#ff9800", "#f44336"],
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    },

    isEmpty() {
      return this.series?.every((item) => item == 0);
    },

    series() {
      return this.values?.map((val) => Number(val.value));
    },
  },

  methods: {
    priorityValue(val) {
      return this.values?.find((v) => {
        return v.id == val;
      })?.value;
    },
  },
};
</script>

<style scoped>
.apexcharts-toolbar {
  z-index: 9 !important;
}
</style>
