<template>
  <VpVueForm
    id="true"
    :fields="fields"
    class="vp-p-6"
    :get="get"
    width="400px"
    ref="item"
    :save="save"
    #default="{ state, response }"
    :track="$options.track"
    track-prefix="Integration"
    track-event-name="Configured"
    collection-cache="integrations"
    cache="integration"
    feature="INTEGRATION_HUBSPOT"
  >
    <VpPageHeader title="HubSpot" />

    <VpStatusInput id="hubspot-status" v-model="state.status" />

    <template>
      <!-- CONNECTED -->
      <VpField label="Connected As">
        <div
          v-if="response.user && !connectingUser"
          class="vp-bg-gray-100 vp-rounded-xl vp-p-6 vp-space-y-4"
        >
          <VpField label="Email">
            <p>{{ response.user.email }}</p>
          </VpField>

          <VpField label="HubSpot Domain">
            <p>{{ response.user.hubDomain }}</p>
          </VpField>

          <VpField label="HubSpot ID">
            <p>{{ response.user.hubId }}</p>
          </VpField>

          <VpField label="App ID">
            <p>{{ response.user.appId }}</p>
          </VpField>

          <div>
            <VyButton
              type="button"
              label="Disconnect"
              :loading="disconnectingUser"
              @click.native="
                checkPermission('INTEGRATION_HUBSPOT', 'delete', () => {
                  disconnect(response);
                })
              "
              class="button--warning button--solid button--sm button--rounded"
            />
          </div>
        </div>

        <div v-else>
          <VyButton
            type="button"
            :loading="connectingUser"
            label="Connect to HubSpot"
            @click.native="redirect(response)"
            class="button--secondary button--solid button--md button--rounded"
          />
        </div>
      </VpField>
    </template>
  </VpVueForm>
</template>

<script>
import { mapGetters } from "vuex";
import deleteUser from "./delete-user.gql";
import updateUser from "./update-user.gql";
import get from "./get.gql";
import update from "./update.gql";

export default {
  track: {
    id: {
      key: "Provider",
      value: () => "HubSpot",
    },
  },
  components: {},

  data() {
    return {
      fields: ["status"],
      connectingUser: false,
      disconnectingUser: false,
    };
  },

  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
  },

  methods: {
    redirect(response) {
      window.location.href = response.loginEndpoint;
    },
    async disconnect() {
      try {
        this.disconnectingUser = true;
        await this.$mutate(deleteUser);
        this.$refs.item.refresh();
      } catch (err) {
        console.error(err);
      }
      this.disconnectingUser = false;
    },

    async checkForCode() {
      const params = new URLSearchParams(window.location.search);
      const paramValues = new Proxy(params, {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      const code = paramValues.code;
      if (code) {
        this.connectingUser = true;
        try {
          await this.$mutate(updateUser, {
            input: {
              code,
            },
          });
          this.$refs.item.refresh();
          window.history.replaceState(
            {},
            document.title,
            "/#" + this.$route.path
          );
        } catch (err) {
          console.error(err);
        }
        this.connectingUser = false;
      }
    },

    get() {
      return this.$query(get).then(({ data }) => {
        if (this.checkPermission("INTEGRATION_HUBSPOT", "upsert")) {
          this.checkForCode();
        }
        return {
          values: data.integration.hubspot,
          res: data.integration,
        };
      });
    },

    save(id, data) {
      return this.$mutate(update, {
        input: {
          ...data,
        },
      }).then(({ data }) => {
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: "integration",
        });
        this.$router.push({ name: "integrations" });
        return data;
      });
    },
  },
};
</script>
