<template>
  <ExportHistory product="marketing" feature="CRM_EXPORT_HISTORY" />
</template>

<script>
import ExportHistory from "components/src/export-history.vue";

export default {
  components: {
    ExportHistory,
  },
};
</script>
