<template>
  <div class="vp-flex vp-flex-wrap vp-gap-1">
    <VyButton
      v-for="tag in value"
      :key="`${tag.id}`"
      type="button"
      class="button--sm button--rounded vp-cursor-auto"
      :class="[colorStyle(tag)]"
      :label="tag.name"
    />
  </div>
</template>

<script>
import colorClass from "static/src/button-color-classes.json";
import mutedColorClass from "static/src/muted-button-color-classes.json";

export default {
  colorClass,
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    colorStyle(tag) {
      if (tag.color) {
        return colorClass[tag.color];
      } else {
        return mutedColorClass.grey;
      }
    },
  },
};
</script>
