<template>
  <VpBulkAccordion
    label="Change Ticket Priority"
    :confirm="`Are you sure you want to change priority of ${count} tickets?`"
  >
    <VpVueForm
      :fields="fields"
      :save="save"
      #default="{ state }"
      create-label="Update"
      class="vp-pb-0"
      :has-reset="false"
      collection-cache="tickets"
      feature="TICKET"
    >
      <VpField rules="required" name="Priority" label="Priority">
        <div>
          <TicketPriority v-model="state.priority" />
        </div>
      </VpField>
    </VpVueForm>
  </VpBulkAccordion>
</template>

<script>
import batchUpdate from "@/views/crm/tickets/batch/priority.gql";
import TicketPriority from "components/src/ticket-priority.vue";

export default {
  components: {
    TicketPriority,
  },
  props: {
    ids: Array,
    count: Number,
  },
  data() {
    return {
      fields: ["priority"],
    };
  },
  methods: {
    save(id, input) {
      return this.$mutate(batchUpdate, {
        ids: this.ids,
        input,
      }).then(() => {
        this.$emit("refresh");
      });
    },
  },
};
</script>
