<template>
  <div class="vp-p-4 crm-settings">
    <ActionCard
      v-if="!extensionVersion"
      href="https://bit.ly/whext"
      target="_blank"
      icon="https://vepaar.b-cdn.net/public/CHROME_EXT_INSTALL.svg"
      title="Install Chrome Extension"
      desc="Add additional features to web.whatsapp.com with it. Build customer profiles & close leads faster."
    />
    <VpProFeature title="CRM Settings" feature="CRM_SETTINGS" class="vp-py-2">
      <VpVueForm
        :fields="fields"
        :id="campaignId"
        :save="save"
        :get="get"
        save-label="Update"
        ref="item"
        :track="$options.track"
        track-prefix="CRM"
        cache="campaign"
        feature="CRM_SETTINGS"
      >
        <template #default="{ state }">
          <div class="vp-mt-2">
            <VpStatusInput
              label="Auto Save Chats"
              desc="Automatically save all the chats you're doing on WhatsApp with anyone."
              :value="
                state.autoSyncChat == 'forced' ? 'active' : state.autoSyncChat
              "
              @input="state.autoSyncChat = $event"
              :disabled="state.autoSyncChat == 'forced'"
            />
            <p
              v-if="state.autoSyncChat == 'forced'"
              class="vp-text-xs vp-text-warning-500 vp-mt-1"
            >
              This has been configured by your Organization and can not be
              changed.
            </p>
          </div>
        </template>
      </VpVueForm>
    </VpProFeature>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ActionCard from "components/src/action-card.vue";

import get from "./get.gql";
import upsert from "./upsert.gql";
import { sendMessageToExt } from "@/utils";
export default {
  track: {
    autoSyncChat: "Auto Save Chats",
  },
  props: {
    id: [Number, String],
    feature: String,
  },
  components: {
    ActionCard,
  },
  data() {
    return {
      fields: ["autoSyncChat"],
      extensionVersion: null,
    };
  },
  created() {
    sendMessageToExt("version", {}, (data) => {
      this.extensionVersion = data;
    });
  },
  computed: {
    ...mapGetters({
      campaignId: "biz/id",
    }),
  },

  methods: {
    get(id) {
      return this.$query(get, {
        id: id,
      }).then(({ data }) => {
        return {
          values: data.campaign,
          res: data,
        };
      });
    },

    async save(id, data) {
      return this.$mutate(upsert, {
        id,
        input: {
          ...data,
        },
      }).then(({ data }) => data);
    },
  },
};
</script>

<style scoped>
.crm-settings {
  width: 500px;
}
</style>
