<template>
  <div>
    <VpNavGroup>Broadcast</VpNavGroup>

    <VpSubNavItem
      label="With WhatsApp API"
      :icon="$options.icons.Whatsapp"
      :to="{ name: 'whatsapp-broadcasts', params: { via: 'api' } }"
    />
    <VpSubNavItem
      v-if="isExtBroadcast"
      label="With Extension"
      :icon="$options.icons.Chrome"
      :to="{ name: 'whatsapp-broadcasts', params: { via: 'ext' } }"
    />
  </div>
</template>

<script>
import { treatment } from "plugins/split";
import { Whatsapp, Chrome } from "icons/icons.js";

export default {
  icons: {
    Whatsapp,
    Chrome,
  },
  data() {
    return {
      isExtBroadcast: false,
    };
  },
  mounted() {
    treatment("WhatsAppExtBroadcast", (res) => {
      this.isExtBroadcast = res;
    });
  },
};
</script>
