import crm from "./crm/";
import NotificationsIndex from "@/views/notifications/index.vue";
import ChromeExtension from "@/views/crm/settings/chrome-extension.vue";
import Exports from "@/views/crm/Exports.vue";

export default [
  {
    path: "notifications",
    name: "notifications",
    component: NotificationsIndex,
    meta: {
      label: "Notifications",
      breadcrumbs: ["home", "notifications"],
    },
  },
  {
    path: "chrome-extension",
    name: "chrome-extension",
    component: ChromeExtension,
    meta: {
      label: "Chrome Extension",
      breadcrumbs: ["home", "chrome-extension"],
    },
  },
  {
    path: "exports",
    name: "exports",
    component: Exports,
    props: true,
    meta: {
      label: "Export History",
      breadcrumbs: ["home", "exports"],
    },
  },
  ...crm,
];
