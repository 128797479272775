<template>
  <VpListLayout>
    <VpList
      :per-page="10"
      :columns="[]"
      endpoint="message-videos"
      :filters.sync="filters"
      :params="params"
      class="saved vp-h-full saved--video"
      ref="list"
      title="Videos"
      :search="false"
      :settings="false"
      feature="MESSAGE"
      cache="messages"
      :request-payload="{ query: $options.messagesQuery }"
    >
      <template #empty>
        <VpErrorPage
          title="Video Not Found!"
          desc="All videos saved from WhatsApp Web using the Vepaar extension's save button will be displayed here."
        />
      </template>
      <template #filters>
        <VpDateRangePicker
          stack
          label="Order Date"
          :value="[filters.startDate, filters.endDate]"
          @input="
            filters.startDate =
              $event[0] ?? String(Math.floor(new Date().getTime() / 1000));
            filters.endDate =
              $event[1] ?? String(Math.floor(new Date().getTime() / 1000));
          "
        />

        <VpField label="Tags">
          <TagsInput v-model="filters.tags" type="video" />
        </VpField>
      </template>

      <template #default="{ items }">
        <div class="vp-bg-gray-100">
          <div
            class="vp-grid vp-grid-cols-3 vp-gap-2 vp-p-6 vp-whitespace-nowrap"
          >
            <template v-for="(item, index) in items">
              <MessageSaved
                :key="item.id + '-' + index"
                :data="item"
                type="video"
                @click="open(item)"
              />
            </template>
          </div>
        </div>
      </template>
    </VpList>

    <MessageModal
      type="video"
      :data="openMessage"
      @refresh="$refs.list.refresh()"
    />
  </VpListLayout>
</template>

<script>
import TagsInput from "components/src/tags/input.vue";

import MessageModal from "../_Modal.vue";
import MessageSaved from "../_Saved.vue";
import messagesQuery from "@/views/crm/messages/collection.gql";

export default {
  messagesQuery,
  components: {
    MessageSaved,
    MessageModal,
    TagsInput,
  },
  data() {
    return {
      filters: {
        startDate: null,
        endDate: null,
        tags: [],
      },
      openMessage: null,
    };
  },
  computed: {
    params() {
      return {
        type: "video",
        //TODO: Apply direction filter
        //direction: 2,
      };
    },
  },
  methods: {
    open(message) {
      this.openMessage = message;
      this.$nextTick(() => {
        this.$vayu.modal.open("saved-modal");
      });
    },
  },
};
</script>
