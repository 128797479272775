<template>
  <div>
    <button
      @click="$vayu.modal.open('biz-switch-modal')"
      class="vp-group vp-p-2 vp-w-[208px] vp-cursor-pointer vp-flex vp-gap-2 vp-items-center vp-border-r vp-border-gray-200"
    >
      <TheAvatar
        :name="bizName.charAt(0)"
        size="xxs"
        class="vp-shrink-0 vp-text-gray-500 vp-font-bold group-hover:vp-text-black"
      />
      <p class="vp-grow vp-font-semibold vp-truncate">
        {{ bizName }}
      </p>
      <VyIcon
        :name="$options.icons.SwitchBusiness"
        class="vp-text-gray-700 vp-text-2xl"
      />
    </button>
    <BizSwitchModal :is-extension="false" />
  </div>
</template>

<script>
import BizSwitchModal from "components/src/biz-switch-modal.vue";
import TheAvatar from "components/src/avatar.vue";
import { mapGetters } from "vuex";
import { SwitchBusiness } from "icons/icons.js";
export default {
  icons: {
    SwitchBusiness,
  },
  components: {
    BizSwitchModal,
    TheAvatar,
  },
  computed: {
    ...mapGetters({
      bizName: "biz/name",
    }),
  },
};
</script>
