<template>
  <div
    class="saved-intro vp-flex vp-flex-col vp-justify-center vp-items-center vp-h-full vp-text-center"
  >
    <div
      class="vp-flex vp-justify-center vp-items-center vp-text-center vp-text-white vp-w-16 vp-h-16 vp-rounded-full vp-bg-secondary-500"
    >
      <VpIcon :name="$options.icons.Chat" class="vp-w-9 vp-h-9" />
    </div>
    <h3 class="vp-text-gray-500 vp-mt-2 vp-text-2xl vp-font-bold">Chat View</h3>
    <p class="vp-text-gray-500 vp-mt-2 vp-max-w-md">
      See all the messages saved from a contact in a chat interface. Select a
      contact from left bar to open a chat.
    </p>
  </div>
</template>

<script>
import { Chat } from "icons/icons.js";
export default {
  icons: {
    Chat,
  },
};
</script>
