<template>
  <VpListLayout add-route="poll" feature="POLL">
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="polls"
      :request-payload="{ query: $options.polls }"
      :filters.sync="filters"
      cache="polls"
      class="vp-h-full"
      ref="list"
      title="Polls"
      :reorder="true"
      @rowClick="
        $router.push({
          name: 'poll-result',
          params: {
            id: $event.id,
          },
        })
      "
      @reorder="reorder('polls', $event)"
      feature="POLL"
      export-permission-key="POLL_VOTER_EXPORT"
    >
      <template #empty>
        <VpFeature the-key="POLL" #default="{ feature }">
          <VpListEmpty :feature="feature" route="poll" />
        </VpFeature>
      </template>
      <template #filters>
        <ListFilterStatus v-model="filters.status" :options="statusOptions" />

        <VpField label="Privacy">
          <VpInput>
            <VpSelect
              v-model="filters.privacy"
              :options="privacyOptions"
              placeholder="Select"
            />
          </VpInput>
        </VpField>

        <VpField label="Multiple Votes">
          <VpInput>
            <VpSelect
              v-model="filters.multipleVotes"
              :options="multipleVotesOptions"
              placeholder="Select"
            />
          </VpInput>
        </VpField>
        <VpField label="Store Visibility">
          <VpRadioGroup
            name="visibility"
            stack
            :options="displayOnStoreOptions"
            v-model="filters.shouldDisplayOnStore"
          />
        </VpField>

        <VpListFilterArchived v-model="filters.archived" />
      </template>

      <template #name="{ item }">
        <LockedItem :item="item" />
        <p>{{ item.name }}</p>
      </template>

      <template #status="{ item }">
        <VpStatus :value="item.status" />
      </template>
      <template #voteCount="{ item }">
        <h3 class="vp-text-2xl vp-font-bold">{{ item.voteCount }}</h3>
      </template>
      <template #createdAt="{ item }">
        <VpDatetime class="vp-text-gray-500" :value="item.createdAt" />
      </template>

      <template #privacy="{ item }">
        <span class="vp-text-lg" v-tooltip="item.privacy">
          {{ item.privacy == "private" ? "🔒" : "🌏" }}
        </span>
      </template>
      <template #isPinned="{ item }">
        <span class="vp-text-lg">
          {{ item.isPinned ? "📌" : " " }}
        </span>
      </template>

      <template #share="{ item }">
        <VyButton
          target="whatsapp"
          :icon="$options.icons.Whatsapp"
          label="Share"
          :href="item.shareUrl"
          class="button--success button--solid button--sm button--rounded"
        />
      </template>

      <template #multipleVotes="{ item }">
        <MultipleVote :value="item.multipleVotes" />
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import reorder from "mixins/reorder";
import MultipleVote from "./_multiple-vote.vue";
import ListFilterStatus from "components/src/vp-list/filter-status.vue";
import { Whatsapp } from "icons/icons.js";
import LockedItem from "components/src/vp-list/locked-item.vue";
import polls from "@/views/polls/collection.gql";

export default {
  polls,
  props: {
    feature: Object,
  },
  icons: {
    Whatsapp,
  },
  mixins: [reorder],
  components: {
    MultipleVote,
    ListFilterStatus,
    LockedItem,
  },
  data() {
    return {
      filters: {
        archived: false,
        status: null,
        privacy: null,
        multipleVotes: null,
        shouldDisplayOnStore: null,
      },
      statusOptions: [
        {
          label: "All",
          value: null,
        },
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Inactive",
          value: "inactive",
        },
      ],
      privacyOptions: [
        {
          label: "All",
          value: null,
        },
        {
          label: "Public",
          value: "public",
        },
        {
          label: "Private",
          value: "private",
        },
      ],
      multipleVotesOptions: [
        {
          label: "All",
          value: null,
        },
        {
          label: "Allow",
          value: "allow",
        },
        {
          label: "Disallow",
          value: "disallow",
        },
        {
          label: "Override",
          value: "override",
        },
      ],
    };
  },
  computed: {
    displayOnStoreOptions() {
      return [
        { label: "All", value: null },
        { label: "Accessible", value: true },
        { label: "Not Accessible", value: false },
      ];
    },
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        {
          name: "name",
          sortable: true,
          type: "string",
        },
        { name: "voteCount", label: "Votes", fix: true, type: "string" },
        { name: "status", fix: true, type: "string" },
        { name: "privacy", fix: true, type: "string" },
        { name: "multipleVotes", label: "Multiple Votes", type: "string" },
        { name: "isPinned", label: "Pinned", fix: true, type: "string" },
        { name: "share", rowClick: false, fix: true, type: "string" },
        { name: "createdAt", type: "string" },
      ];
    },
  },
};
</script>
