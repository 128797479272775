<template>
  <VpShell :aside="false" class="vp-h-full">
    <template #default>
      <div class="vp-flex vp-flex-row vp-h-full">
        <div class="vp-overflow-y-scroll">
          <VpVueForm
            :fields="fields"
            :id="id"
            class="vp-p-6"
            :save="save"
            :get="get"
            :del="move"
            :archive="move"
            width="400px"
            collection-cache="polls"
            :track="$options.track"
            track-prefix="Poll"
            feature="POLL"
          >
            <template #default="{ state, response, isCreating }">
              <VpShouldDisplayOnStoreAlert
                v-if="response && !response.shouldDisplayOnStore"
                title="Poll Disabled"
                desc="This poll is disabled."
              />
              <VpField rules="required" name="Name" label="Name">
                <VpInput>
                  <VpTextBox v-model="state.name" />
                </VpInput>
              </VpField>

              <VpField
                :rules="{ required: isCreating }"
                name="Question"
                label="Question"
              >
                <h4 class="vp-text-lg vp-font-bold" v-if="!isCreating">
                  {{ response && response.question }}
                </h4>
                <VpTextArea v-else :rows="5" v-model="state.question" />
              </VpField>

              <VpField label="Options">
                <!-- CREATE MODE -->

                <div v-if="isCreating">
                  <div class="vp-space-y-2">
                    <div
                      v-for="(choice, index) in state.options"
                      :key="`choice-${index}`"
                      class="vp-flex vp-flex-nowrap"
                    >
                      <VpField
                        :rules="{ required: index < 2 }"
                        class="vp-flex-auto"
                        :name="`Option ${index + 1}`"
                      >
                        <VpInput :before="index + 1">
                          <VpTextBox v-model="state.options[index]" />
                        </VpInput>
                      </VpField>
                      <VyButton
                        v-if="state.options.length > 2"
                        class="vp-ml-2 button--danger button--muted button--circle button--md"
                        :icon="$options.icons.Close"
                        @click.native="state.options.splice(index, 1)"
                      />
                    </div>
                  </div>

                  <VyButton
                    type="button"
                    class="vp-mt-2 button--primary button--pill button--muted button--md"
                    :icon="$options.icons.Add"
                    label="Add Option"
                    @click.native="state.options.push('')"
                  />
                </div>

                <!-- EDIT MODE -->
                <template v-else>
                  <div
                    v-if="response && response.options"
                    class="vp-mt-2 vp-space-y-2"
                  >
                    <div
                      class="vp-flex vp-items-center vp-flex-nowrap"
                      v-for="(choice, index) in response.options"
                      :key="`choice-${index}`"
                    >
                      <p
                        class="vp-font-bold vp-w-8 vp-h-8 vp-flex vp-items-center vp-justify-center vp-bg-gray-50 vp-text-gray-300 vp-rounded-full"
                      >
                        {{ index + 1 }}
                      </p>

                      <p class="vp-flex-auto vp-ml-2 vp-font-bold">
                        {{ choice.label }}
                      </p>
                    </div>
                  </div>
                </template>
              </VpField>

              <VpField
                optional
                label="Help Note"
                note="Will be appended at the end of poll question. It will help users to understand how to vote."
              >
                <VpTextArea :rows="5" v-model="state.note" />
              </VpField>

              <VpField
                label="Multiple Votes"
                :rules="{ required: isCreating }"
                name="Multiple Votes"
              >
                <div v-if="!isCreating" :style="{ whiteSpace: 'nowrap' }">
                  <span v-if="response && response.multipleVotes == 'allow'">
                    Allow
                  </span>
                  <span
                    v-else-if="response && response.multipleVotes == 'disallow'"
                  >
                    Disallow
                  </span>
                  <span v-else> Override </span>
                </div>

                <VpInput v-else>
                  <VpSelect
                    v-model="state.multipleVotes"
                    :options="multipleVotesOptions"
                    placeholder="Select"
                  />
                </VpInput>
              </VpField>

              <VpField label="Privacy">
                <VpRadioGroup
                  class="vp-flex"
                  v-model="state.privacy"
                  name="privacy"
                  :options="privacyOptions"
                />
              </VpField>

              <VpField
                inline
                label="Status"
                note="If inactive, person will be notified that poll is not active anymore and you won't receive votes."
              >
                <VpSwitch
                  id="poll-status"
                  on-value="active"
                  off-value="inactive"
                  v-model="state.status"
                />
              </VpField>

              <VpField
                inline
                label="Pin in Extension"
                note="In our Chrome Extension, you can pin this item in quick-reply bar to quickly send this poll as a message."
              >
                <VpSwitch
                  :value="state.isPinned"
                  v-model="state.isPinned"
                  :on-value="true"
                  :off-value="false"
                />
              </VpField>
            </template>
          </VpVueForm>
        </div>
        <div class="vp-p-4 vp-overflow-y-scroll vp-flex-1">
          <help-card title="How Polls Work">
            <p>
              When you create a poll, we'll create a WhatsApp message with an
              individual link for all the options.
            </p>
            <p>
              You can share the message with your WhatsApp contacts and they'll
              be able to visit the link and place a vote.
            </p>
            <p>
              The links will redirect voters to WhatsApp and open a chat with
              Vepaar's official WhatsApp Number
              <b class="vp-text-primary-500">+91 77780 62080</b>.
            </p>
            <p>
              The chat window will have a pre-filled message with vote message.
              Voters will just have to click on send and their votes will be
              counted.
            </p>
          </help-card>

          <help-card title="Multiple Votes" class="vp-mt-2">
            <div class="vp-space-y-4">
              <div>
                <b>Allow:</b>
                <p>
                  People can vote for multiple votes and each vote will be
                  counted in results.
                </p>
              </div>
              <div>
                <b>Disallow:</b>
                <p>
                  People can vote for only once and only first vote will be
                  counted.
                </p>
              </div>
              <div>
                <b>Override:</b>
                <p>
                  People can vote for multiple times and only latest vote will
                  be counted.
                </p>
              </div>
            </div>
          </help-card>

          <help-card title="Privacy" class="vp-mt-2">
            <div class="vp-space-y-4">
              <div>
                <b>Public:</b>
                <p>
                  A link to poll will be appended to question. People can visit
                  & see the results. Personal information about voters will not
                  be reveled.
                </p>
              </div>
              <div>
                <b>Private:</b>
                <p>Only you can see the poll results.</p>
              </div>
            </div>
          </help-card>
        </div>
      </div>
    </template>
  </VpShell>
</template>

<script>
import get from "./get.gql";
import upsert from "./upsert.gql";
import move from "./move.gql";
import HelpCard from "components/src/help/card.vue";
import { Close, Add } from "icons/icons.js";

export default {
  icons: {
    Close,
    Add,
  },
  track: {
    id: "Id",
    name: "Name",
    question: "Question",
    options: {
      key: "Options",
      value: (options) =>
        options.length
          ? options.map((option) => option.label).join(", ")
          : "[]",
    },
    privacy: "Privacy",
    status: "Status",
    multipleVotes: "Multiple Votes",
    isPinned: "Pin in Extension",
  },
  props: {
    id: [Number, String],
    poll: Object,
  },
  components: {
    HelpCard,
  },

  data() {
    return {
      fields: [
        "name",
        "question",
        {
          name: "options",
          value: ["", ""],
        },
        {
          name: "note",
          value:
            "ℹ️ Vote by clicking on the link below the option. You'll be asked to send a message to the poll creator with a pre-filled message. Just click on send to vote.",
        },
        "multipleVotes",
        { name: "privacy", value: "public" },
        { name: "status", value: "active" },
        { name: "isPinned", value: false },
      ],
      privacyOptions: [
        {
          label: "Private",
          value: "private",
        },
        {
          label: "Public",
          value: "public",
        },
      ],
      multipleVotesOptions: [
        {
          label: "Allow",
          value: "allow",
        },
        {
          label: "Disallow",
          value: "disallow",
        },
        {
          label: "Override",
          value: "override",
        },
      ],
    };
  },

  methods: {
    get(id) {
      return this.$query(get, {
        id: id,
      }).then(({ data }) => {
        const poll = data.poll;
        if (poll) {
          this.$emit("poll", poll);
        }
        return {
          values: poll,
          res: data,
        };
      });
    },
    save(id, data) {
      if (id) ["options"].forEach((key) => delete data[key]);
      const inputs = {
        input: data,
      };
      if (id) inputs.id = id;

      return this.$mutate(upsert, inputs).then(({ data }) => {
        this.$root.$emit("rehydrate");
        this.$router.push({ name: "polls" });
        return data;
      });
    },
    move(id, action) {
      return this.$mutate(move, {
        id: parseInt(id),
        action,
      }).then(({ data }) => {
        this.$root.$emit("rehydrate");
        this.$router.push({ name: "polls" });
        return data;
      });
    },
    segmentAnalytics(poll, options) {
      const { id, name, question, privacy, status, multipleVotes, isPinned } =
        poll;
      this.$track("Poll Created", {
        "Poll Id": id,
        "Poll Name": name,
        "Poll Question": question,
        "Poll Options": options.join(", "),
        "Poll Privacy": privacy,
        "Poll Status": status,
        "Poll Multiple Votes": multipleVotes,
        "Poll Pin in Extension": isPinned,
      });
    },
  },
};
</script>
