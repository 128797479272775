<template>
  <Async :req="req">
    <template #pending>
      <div class="vp-h-8 vp-rounded vp-bg-shimmer vp-animate-pulse"></div>
    </template>
    <template #default="{ res }">
      <VpDropdown
        placeholder="Apply Funnel"
        :options="res"
        :value="value"
        can-add-new
        add-new-message="No funnels found. Want to add one?"
        @onAddNew="$emit('onAddNew')"
        @input="input($event)"
      />
    </template>
  </Async>
</template>

<script>
import Async from "../async.vue";
import { FUNNELS_GET, CONTACT_FUNNEL_UPSERT, CONTACT_FUNNEL_GET } from "./gql";

export default {
  props: {
    value: Number,
    contactId: [Number, String],
  },

  components: {
    Async,
  },

  created() {
    if (this.contactId) {
      this.getContactValue();
    }
  },

  methods: {
    req() {
      return this.$query(FUNNELS_GET).then(({ data }) => {
        return data.funnels.data.map((item) => {
          return { label: item.name, id: item.id, color: item.color };
        });
      });
    },

    input(value) {
      this.$emit("input", value);
      if (this.contactId) {
        this.saveContactValue(value);
      }
    },

    getContactValue() {
      return this.$query(CONTACT_FUNNEL_GET, {
        contactId: Number(this.contactId),
      }).then(({ data: { contact } }) => {
        if (contact.funnel?.id) {
          this.$emit("input", contact.funnel.id);
        }
      });
    },

    saveContactValue(id) {
      return this.$mutate(CONTACT_FUNNEL_UPSERT, {
        contactId: Number(this.contactId),
        input: {
          funnelId: id,
        },
      });
    },
  },
};
</script>
