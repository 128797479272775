import List from "@/views/crm/whatsapp-group/List.vue";
import Item from "@/views/crm/whatsapp-group/Item.vue";
import Edit from "@/views/crm/whatsapp-group/Edit.vue";
import Statistics from "@/views/crm/whatsapp-group/Statistics.vue";
import Participants from "@/views/crm/whatsapp-group/Participants.vue";

export default [
  {
    path: "whatsapp-groups",
    name: "whatsapp-groups",
    component: List,
    meta: {
      label: "WhatsApp Groups",
      breadcrumbs: ["home", "whatsapp-groups"],
    },
  },
  {
    path: "whatsapp-groups/:id",
    component: Item,
    props: true,
    children: [
      {
        path: "",
        name: "whatsapp-group",
        component: Edit,
        props: true,
        meta: {
          label: "Profile",
          breadcrumbs: ["home", "whatsapp-groups", "whatsapp-group"],
        },
      },
      {
        path: "statistics",
        name: "whatsapp-group-statistics",
        component: Statistics,
        props: true,
        meta: {
          label: "Statistics",
          breadcrumbs: [
            "home",

            "whatsapp-groups",
            "whatsapp-group",
            "whatsapp-group-statistics",
          ],
        },
      },
      {
        path: "participants",
        name: "whatsapp-group-participants",
        component: Participants,
        props: true,
        meta: {
          label: "Participants",
          breadcrumbs: [
            "home",

            "whatsapp-groups",
            "whatsapp-group",
            "whatsapp-group-participants",
          ],
        },
      },
    ],
  },
];
