<template>
  <VpSubShell :aside="id != '+'" :key="id">
    <template #before-nav>
      <div class="vp-border-b vp-p-4">
        <template v-if="poll">
          <VpField label="Poll">
            <h3 class="vp-leading-none vp-text-2xl vp-font-bold">
              {{ poll.name }}
            </h3>
          </VpField>
        </template>
      </div>
    </template>

    <template #nav>
      <VpSubNavItem
        :to="{ name: 'poll-result', params: { id } }"
        :icon="$options.icons.DonutChart"
        label="Results"
      />
      <VpSubNavItem
        :to="{ name: 'poll', params: { id } }"
        :icon="$options.icons.Edit"
        label="Edit"
        exact
      />
      <VpSubNavItem
        :to="{ name: 'poll-voters', params: { id } }"
        :icon="$options.icons.Group"
        label="Voters"
      />
    </template>

    <template #default>
      <portal to="header">
        <VyButton
          v-if="id != '+'"
          :icon="$options.icons.Whatsapp"
          :href="poll && poll.shareUrl"
          label="Share"
          target="whatsapp"
          class="button--success button--solid button--sm button--rounded"
        />
      </portal>

      <RouterView :id="id" @poll="poll = $event" />
    </template>
  </VpSubShell>
</template>

<script>
import { DonutChart, Edit, Group, Whatsapp } from "icons/icons.js";

export default {
  icons: {
    DonutChart,
    Edit,
    Group,
    Whatsapp,
  },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      poll: null,
    };
  },
};
</script>
