<template>
  <div
    class="saved-message vp-p-4 vp-rounded-xl vp-bg-white hover:vp-ring-4 vp-ring-inset vp-ring-gray-200 vp-flex vp-flex-col vp-h-full vp-cursor-pointer"
    @click="$emit('click')"
  >
    <header class="vp-mb-4">
      <!-- TODO: This should be item.from -->
      <VpConnection
        type="contact"
        class="vp-flex-auto"
        :data="data.sender || data.receiver"
        :date="data.receivedOn"
      >
        <template #footer>
          <VpDatetime
            class="vp-text-xs vp-text-gray-500"
            :value="data.receivedOn"
          />
        </template>
      </VpConnection>
    </header>
    <div>
      <!-- TEXT -->
      <p v-if="type == 'text'" class="saved-message__body">{{ data.text }}</p>

      <!-- IMAGE -->
      <div
        v-if="type == 'image'"
        class="vp-bg-gray-100 vp-rounded-md vp-border"
      >
        <VpAvatar
          :image="data.media && data.media.media && data.media.media.url"
          :name="data.name"
          size="full"
        />
      </div>

      <!-- VIDEO -->
      <div
        v-if="type == 'video'"
        class="vp-aspect-w-16 vp-aspect-h-9 vp-bg-gray-100 vp-rounded-md vp-border"
      >
        <video
          width="100%"
          height="100%"
          :src="data.media && data.media.media && data.media.media.url"
        />
      </div>
    </div>
    <footer class="vp-mt-4">
      <TagsDisplay :value="data.tags" />
    </footer>
  </div>
</template>

<script>
import TagsDisplay from "components/src/tags/display.vue";

export default {
  props: {
    data: Object,
    type: String,
  },
  components: {
    TagsDisplay,
  },
};
</script>
