import crmCustomFields from "@/graph/crm/customFields/reorder.gql";
import funnels from "@/views/crm/funnels/reorder.gql";
import polls from "@/views/polls/reorder.gql";
import productAttributeOptions from "graph/productAttributeOption/reorder.gql";
import quickReplies from "@/views/crm/quick-replies/reorder.gql";
import quickReplyMedias from "@/views/crm/quick-replies/reorderMedia.gql";
import tags from "graph/tag/reorder.gql";
import ticketStatuses from "@/views/crm/ticket-statuses/reorder.gql";
export default {
  funnels,
  ticketStatuses,
  quickReplies,
  quickReplyMedias,
  tags,
  polls,
  productAttributeOptions,
  crmCustomFields,
};
