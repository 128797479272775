<template>
  <p v-if="readonly && selected" class="vp-font-bold">
    {{ selected.label }}
  </p>
  <div v-else class="vp-mt-1">
    <VpRadioGroup
      class="vp-flex"
      :options="options"
      name="type"
      :value="value"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: Number,
    readonly: Boolean,
  },
  data() {
    return {
      options: [
        {
          value: 1,
          label: "Contact",
        },
        {
          value: 2,
          label: "Message",
        },
        {
          value: 3,
          label: "Media",
        },
      ],
    };
  },

  computed: {
    selected() {
      if (!this.value) return;
      return this.options.find((option) => option.value == this.value);
    },
  },
};
</script>
