<template>
  <VpSubShell class="vp-h-full vp-overflow-hidden">
    <template #before-nav>
      <div class="vp-p-4">
        <VpSubNav>
          <VpSubNavItem
            label="Text"
            :icon="$options.icons.Message"
            :to="{ name: 'text' }"
          />
          <VpSubNavItem
            label="Images"
            :icon="$options.icons.Image"
            :to="{ name: 'images' }"
          />

          <VpSubNavItem
            label="Videos"
            :icon="$options.icons.Video"
            :to="{ name: 'videos' }"
          />
          <VpSubNavItem
            label="Audio"
            :icon="$options.icons.Audio"
            :to="{ name: 'audio' }"
          />
          <VpSubNavItem
            label="Documents"
            :icon="$options.icons.Document"
            :to="{ name: 'documents' }"
          />
        </VpSubNav>
      </div>
    </template>
    <template #default>
      <router-view />
    </template>
  </VpSubShell>
</template>

<script>
import { Message, Image, Video, Audio, Document } from "icons/icons.js";

export default {
  icons: {
    Message,
    Image,
    Video,
    Audio,
    Document,
  },
};
</script>
