<template>
  <div>
    <VpRequest ref="request" :get="get" #default="{ res: { campaign } }">
      <h3 class="vp-text-gray-500 vp-text-2xl vp-font-bold">Hi, {{ name }}</h3>
      <crm class="vp-mt-6" :campaign="campaign" :checklist="crmCheckLists" />
    </VpRequest>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import campaign from "./campaign.gql";
import checklists from "./checklists.gql";

import Crm from "./Crm.vue";

export default {
  components: {
    Crm,
  },

  data() {
    return {
      crmCheckLists: [],
    };
  },

  computed: {
    ...mapGetters({
      campaignId: "biz/id",
      name: "user/name",
    }),
  },

  mounted() {
    this.$cache.evict({
      id: "ROOT_QUERY",
      fieldName: "checklist",
    });
    this.getCheckList().then(({ crmCheckLists }) => {
      this.crmCheckLists = crmCheckLists;
    });
  },

  methods: {
    get() {
      return this.$query(campaign, {
        id: this.campaignId,
      }).then(({ data: { campaign, quickReplies } }) => {
        const quickReply = quickReplies.data?.[0] ?? null;
        return {
          campaign,
          quickReply,
        };
      });
    },
    getCheckList() {
      return this.$query(checklists).then(
        ({ data: { storeCheckLists, crmCheckLists } }) => {
          return {
            storeCheckLists: storeCheckLists?.items,
            crmCheckLists: crmCheckLists?.items,
          };
        }
      );
    },
  },
};
</script>
