<template>
  <VpSubShell :aside="id != '+'" :key="id">
    <template #before-nav>
      <div class="vp-border-b vp-p-4 vp-space-y-3">
        <template v-if="item">
          <VpField label="Broadcast Name">
            <h4 class="vp-leading-tight vp-text-lg vp-font-bold">
              {{ item.name }}
            </h4>
          </VpField>

          <VpField label="Status">
            <div>
              <Status :value="item.status" />
            </div>
          </VpField>
        </template>
      </div>
    </template>

    <template #nav>
      <VpSubNavItem
        label="Analytics"
        :icon="$options.icons.DonutChart"
        :to="{ name: 'whatsapp-broadcast-analytics' }"
      />
      <VpSubNavItem
        label="Edit"
        :icon="$options.icons.Edit"
        :to="{ name: 'whatsapp-broadcast' }"
        exact
      />

      <VpSubNavItem
        label="Contacts"
        :icon="$options.icons.Group"
        :to="{ name: 'whatsapp-broadcast-contacts' }"
      />
    </template>

    <template #default>
      <VpFeature the-key="WHATSAPP_BROADCAST" #default="{ feature }">
        <RouterView
          :feature="feature"
          :refresh-feature="feature && feature.refresh"
          :id="id"
          :broadcast-name="item?.name"
          @item="
            item = $event;
            $root.$emit('breadcrumbs-label', 'whatsapp-broadcast', $event.name);
          "
        />
      </VpFeature>
    </template>
  </VpSubShell>
</template>

<script>
import Status from "./_Status.vue";
import { mapGetters } from "vuex";
import { Edit, Group, DonutChart } from "icons/icons.js";

export default {
  icons: {
    Edit,
    Group,
    DonutChart,
  },
  props: {
    id: [Number, String],
  },

  data() {
    return {
      item: null,
    };
  },

  components: {
    Status,
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
  },
};
</script>
