<template>
  <div class="vp-h-full">
    <!-- Header Buttons -->
    <VpHeaderActions v-if="isAction">
      <VpPortalButton label="Bulk Actions" @click.native="checkBulkAction()" />
      <VpAddItem
        :feature="feature"
        @click="openAddContactModal"
        :usage="usage"
      />
    </VpHeaderActions>

    <!-- ADD NEW CONTACT -->
    <add-contact
      @refresh="$refs.list.refresh()"
      :usage="usage"
      ref="addContact"
      @updateUsage="setUsage($event)"
    />

    <!-- LIST -->
    <VpList
      :per-page="10"
      :columns="columns"
      :endpoint="endpoint"
      :filters.sync="filters"
      :params="params"
      :request-payload="requestPayload"
      class="vp-h-full"
      ref="list"
      title="Contacts"
      bulk
      @rowClick="$event.isOutOfQuota ? false : $router.push(link($event))"
      :export-endpoint="exportEndpoint"
      :export-feature="exportFeatureKey"
      export-history-route="exports"
      feature="CONTACT"
      :rowClass="rowClass"
    >
      <template #empty>
        <VpFeature the-key="CONTACT" #default="{ feature }">
          <VpListEmpty :feature="feature" route="contact">
            <template #action>
              <VpAddItem @click="openAddContactModal" :feature="feature" />
            </template>
          </VpListEmpty>
        </VpFeature>
      </template>
      <!-- FIELDS -->
      <template #profilePicture="{ item }">
        <VpAvatar
          class="vp-rounded-md"
          :image="item.profilePicture && item.profilePicture.url"
          :name="item.name"
          size="sm"
        />
      </template>

      <template #name="{ item }">
        <div class="row-details__wrap">
          <VyIcon
            v-if="item.isOutOfQuota"
            class="vp-w-6 vp-h-6"
            :name="$options.icons.Lock"
            @click.native="
              $vayu.modal.open('pro-feature-modal', { featureName: 'CONTACT' })
            "
          />
          <p>{{ item.name || (item.whatsapp && item.whatsapp.name) }}</p>
        </div>
      </template>

      <template #mobile="{ item }">
        <p class="vp-whitespace-nowrap">
          {{ item.mobileNumber.internationalFormat }}
        </p>
      </template>

      <template #email="{ item }">
        <a
          class="vp-whitespace-nowrap"
          :href="`mailto:${item.email}`"
          target="_blank"
        >
          {{ item.email }}
        </a>
      </template>

      <template #funnel="{ item }">
        <FunnelDisplay :value="item.funnel" />
      </template>

      <template #tier="{ item }">
        <ColorButton
          v-if="item.tier"
          :label="item.tier.name"
          :color="item.tier.color"
          class="button--sm"
        />
      </template>

      <template #createdAt="{ item }">
        <VpDatetime :value="item.createdAt" />
      </template>

      <template #tags="{ item }">
        <TagsDisplay :value="item.tags" />
      </template>

      <template #actions="{ item }">
        <VpActionButton
          :icon="$options.icons.Whatsapp"
          class="button--gray button--md"
          :href="
            item.isOutOfQuota
              ? ''
              : `https://web.whatsapp.com/send/?phone=${item.mobileNumber.numberWithCallingCode}&text&app_absent=0`
          "
          target="whatsapp"
        />
      </template>

      <!-- FILTERS -->
      <template #filters>
        <VpField label="Name">
          <VpInput>
            <VpTextBox v-model="filters.name" />
          </VpInput>
        </VpField>

        <VpField label="Mobile No">
          <VpInput>
            <VpTextBox type="number" v-model="filters.mobileNo" />
          </VpInput>
        </VpField>

        <VpField label="Email">
          <VpInput>
            <VpTextBox v-model="filters.email" />
          </VpInput>
        </VpField>
        <VpField label="Funnel">
          <FunnelInput v-model="filters.funnelId" />
        </VpField>

        <VpField label="Tiers" v-if="tierOptions.length > 0">
          <VpDropdown
            v-bind="$attrs"
            class="vp-mb-2"
            placeholder="Apply Tiers"
            :options="tierOptions"
            v-model="filters.tierId"
          />
        </VpField>

        <VpField label="Tags">
          <TagsInput v-model="filters.tagIds" type="contact" />
        </VpField>

        <VpField label="Contact Status">
          <VpRadioGroup
            class="vp-text-xs vp-gap-2"
            v-model="filters.isOutOfQuota"
            :options="contactStatusOptions"
          />
        </VpField>

        <VpField v-if="filters.tagIds.length" label="Tag Status">
          <VpRadioGroup
            name="tags"
            class="vp-flex"
            :options="tagStatuses"
            v-model="filters.tagPreference"
          />
        </VpField>

        <VpField label="Gender">
          <GenderInput class="vp-text-xs" v-model="filters.gender" />
        </VpField>

        <VpField label="Duration">
          <VpDateRangePicker
            stack
            label="Duration"
            :value="[
              filters.duration && filters.duration.start,
              filters.duration && filters.duration.end,
            ]"
            @input="applyDate($event)"
            :max="null"
          />
        </VpField>
      </template>

      <!-- BULK ACTIONS -->
      <template #_bulk="{ count, ids }">
        <VpBulkAction
          label="Delete Contacts"
          :icon="$options.icons.Delete"
          @click="
            checkPermission('CONTACT', 'delete', () => {
              $vayu.modal.open('delete-contact');
            })
          "
        />
        <DeleteContactModal
          @contacts-deleted="onContactsDeleted"
          :selected-contacts-count="count"
          :selected-contacts-id="ids"
        />
      </template>
    </VpList>
  </div>
</template>

<script>
import ColorButton from "../color-button.vue";
import GenderInput from "../form/gender-input.vue";
import TagsDisplay from "../tags/display.vue";
import TagsInput from "../tags/input.vue";
import FunnelDisplay from "../funnel/display.vue";
import FunnelInput from "../funnel/input.vue";

import { Delete, Lock, Whatsapp, Add } from "icons/icons.js";
import { mapGetters } from "vuex";

import getTiers from "graph/tier/collection.gql";
import feature from "../feature/get.gql";

import AddContact from "./_AddContact.vue";

import DeleteContactModal from "../contacts/_delete-modal.vue";
export default {
  icons: {
    Lock,
    Whatsapp,
    Delete,
    Add,
  },
  props: {
    type: String,
    isAction: {
      type: Boolean,
      default: true,
    },
    endpoint: String,
    exportEndpoint: String,
    requestPayload: Object,
    id: [Number, String],

    listParams: {
      type: Object,
      default: () => {},
    },
    exportFeatureKey: String,
    feature: Object,
  },
  components: {
    AddContact,
    GenderInput,
    ColorButton,
    TagsDisplay,
    TagsInput,
    FunnelDisplay,
    FunnelInput,
    DeleteContactModal,
  },

  mounted() {
    this.usage = this.feature.usage;
    this.getTiers();
  },

  data() {
    return {
      usage: null,
      selectedContactIds: null,
      selectedContactsCount: null,
      filters: {
        funnelId: null,
        tagIds: [],
        gender: null,
        name: null,
        email: null,
        mobileNo: null,
        tagPreference: "any",
        duration: null,
        isOutOfQuota: null,
        tierId: null,
      },
      contactStatusOptions: [
        { label: "All", value: null },
        { label: "Locked", value: true },
        { label: "Unlocked", value: false },
      ],
      columns: [
        { name: "profilePicture", label: "Photo", fix: true, type: "string" },
        { name: "name", type: "string" },
        { name: "mobile", type: "string" },
        { name: "email", rowClick: false, type: "string" },
        { name: "funnel", fix: true, type: "string" },
        { name: "tags", type: "string" },
        { name: "actions", rowClick: false },
        { name: "createdAt", label: "Created", fix: true, type: "date" },
      ],
      tierOptions: [],
    };
  },

  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
      isSubscriptionLimitExceeded: "user/isSubscriptionLimitExceeded",
      isUserPermitted: "user/isUserPermitted",
    }),

    params() {
      return {
        ...this.listParams,
      };
    },

    tagStatuses() {
      return [
        { label: "Any", value: "any" },
        { label: "Only", value: "only" },
      ];
    },
  },

  methods: {
    checkBulkAction() {
      if (this.isUserPermitted("CONTACT_BULK_UPLOAD", "upsert")) {
        this.$router.push({ name: "contacts-bulk" });
      } else {
        this.$vayu.modal.open("unauthorized-access-modal");
      }
    },
    openAddContactModal() {
      this.$vayu.modal.open("add-contact");
    },
    setUsage(event) {
      this.usage = event;
    },
    link(contact) {
      return {
        name: "contact",
        params: {
          id: contact.id,
        },
      };
    },

    getTiers() {
      this.$query(getTiers, { page: 1, perPage: -1 }).then((res) => {
        this.tierOptions = res.data.tiers.data.map((opt) => {
          return {
            label: opt.name,
            value: opt.id,
            color: opt.color,
            id: opt.id,
          };
        });
      });
    },

    onContactsDeleted() {
      this.$refs.list.refresh();
      this.$cache.evict({
        id: "ROOT_QUERY",
        fieldName: "features",
      });
      this.$query(feature, {
        key: "CONTACT",
      }).then(({ data }) => {
        this.usage = data.features.data[0].usage;
        this.$refs.addContact.usage = this.usage;
      });
    },
    applyDate(dates) {
      this.filters.duration = {
        start: dates[0] ?? String(Math.floor(new Date().getTime() / 1000)),
        end: dates[1] ?? String(Math.floor(new Date().getTime() / 1000)),
        preset: "custom",
      };
    },
    rowClass(row) {
      if (row.isOutOfQuota) {
        return ["row-disabled"];
      }
    },
  },
};
</script>
