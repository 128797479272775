<template>
  <VpListLayout add-route="ticket" feature="TICKET">
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="tickets"
      :request-payload="{ query: $options.tickets }"
      cache="tickets"
      :filters.sync="filters"
      class="vp-h-full"
      ref="list"
      bulk
      title="Tickets"
      @rowClick="
        $router.push({
          name: 'ticket',
          params: {
            id: $event.id,
          },
        })
      "
      export-endpoint="ticket"
      export-feature="TICKET_EXPORT"
      export-history-route="exports"
      feature="TICKET"
    >
      <template #empty>
        <VpListEmpty :feature="feature" route="ticket" />
      </template>
      <template #filters>
        <VpField label="Contact">
          <ContactSelector v-model="filters.contactId" />
        </VpField>
        <VpField label="Created Date">
          <VpDateRangePicker
            stack
            label="Created Date"
            :value="[
              filters.createdAtDuration && filters.createdAtDuration.start,
              filters.createdAtDuration && filters.createdAtDuration.end,
            ]"
            @input="applyDate($event, 'createdAtDuration')"
            :max="null"
          />
        </VpField>
        <VpField label="Due Date">
          <VpDateRangePicker
            stack
            label="Order Date"
            :value="[
              filters.dueDateDuration && filters.dueDateDuration.start,
              filters.dueDateDuration && filters.dueDateDuration.end,
            ]"
            @input="applyDate($event, 'dueDateDuration')"
            :max="null"
          />
        </VpField>
        <VpField label="Priority">
          <TicketPriority v-model="filters.priority" />
        </VpField>

        <VpField label="Status">
          <TicketStatusInput v-model="filters.status" unselect />
        </VpField>
      </template>

      <template #id="{ item }">
        <p class="vp-text-gray-500">#{{ item.id }}</p>
      </template>
      <template #priority="{ item }">
        <TicketPriority :value="item.priority" readonly />
      </template>
      <template #contact="{ item }">
        <VpConnection type="contact" :data="item.contact" />
      </template>
      <template #status="{ item }">
        <TicketStatus :data="item.status" />
      </template>
      <template #createdAt="{ item }">
        <VpDatetime
          class="vp-text-gray-500 vp-text-sm"
          :value="item.createdAt"
        />
      </template>
      <template #dueDate="{ item }">
        <VpDate class="vp-text-warning-500 vp-text-sm" :value="item.dueDate" />
      </template>
      <template #subject="{ item }">
        <div>
          <p>{{ item.subject }}</p>
          <p
            class="vp-text-xs vp-text-gray-500 vp-whitespace-pre-line vp-line-clamp-3 vp-overflow-hidden"
          >
            {{ item.description }}
          </p>
        </div>
      </template>

      <template #_bulk="{ count, ids }">
        <Priority :ids="ids" :count="count" @refresh="$refs.list.refresh()" />
        <Status :ids="ids" :count="count" @refresh="$refs.list.refresh()" />
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import TicketPriority from "components/src/ticket-priority.vue";
import TicketStatus from "components/src/ticket-status.vue";
import TicketStatusInput from "components/src/ticket-status/input.vue";
import tickets from "@/views/crm/tickets/collection.gql";

import ContactSelector from "@/components/contact-selector.vue";
import Priority from "./_Priority.vue";
import Status from "./_Status.vue";

export default {
  tickets,
  props: {
    feature: Object,
  },
  components: {
    ContactSelector,
    Priority,
    Status,
    TicketStatus,
    TicketPriority,
    TicketStatusInput,
  },

  data() {
    return {
      filters: {
        contactId: null,
        dueDateDuration: null,
        createdAtDuration: null,
        priority: null,
        status: null,
      },
      columns: [
        { name: "id", fix: true, label: "ID" },
        { name: "priority", sortable: true, fix: true, type: "string" },
        { name: "subject", sortable: true, type: "string" },
        { name: "contact", fix: true, width: "240px", type: "string" },
        { name: "status", fix: true, type: "string" },
        { name: "dueDate", sortable: true, type: "string" },
        { name: "createdAt", sortable: true, type: "string" },
      ],
    };
  },
  methods: {
    applyDate(dates, dateKeyName) {
      this.filters[dateKeyName] = {
        start: dates[0] ?? String(Math.floor(new Date().getTime() / 1000)),
        end: dates[1] ?? String(Math.floor(new Date().getTime() / 1000)),
        preset: "custom",
      };
    },
  },
};
</script>
