<template>
  <VpVueForm
    :fields="[]"
    :id="id"
    class="poll vp-p-6"
    :get="get"
    :del="move"
    :archive="move"
    collection-cache="polls"
    :track="$options.track"
    track-prefix="Poll"
    feature="POLL"
  >
    <template #default="{ response }">
      <VpShouldDisplayOnStoreAlert
        v-if="response && !response.shouldDisplayOnStore"
        title="Poll Disabled"
        desc="This poll is disabled."
      />
      <VpField label="Question">
        <h4 class="vp-text-lg vp-font-bold">{{ response?.question }}</h4>
      </VpField>

      <div class="vp-grid vp-grid-cols-12 vp-mt-4 vp-gap-12">
        <div class="vp-col-span-6">
          <div class="">
            <div
              class="vp-border-t vp-py-6"
              v-for="(choice, index) in response?.options"
              :key="`choice-${index}`"
            >
              <div class="vp-flex vp-flex-nowrap vp-items-center">
                <div class="vp-flex-auto">
                  <div class="vp-flex vp-items-center vp-font-bold">
                    <p class="vp-text-gray-500-light vp-mr-2">
                      {{ index + 1 }}.
                    </p>
                    <p class="vp-flex-auto">{{ choice.label }}</p>
                  </div>
                  <div class="vp-mt-1 vp-bg-gray-200 vp-h-1 vp-rounded-2xl">
                    <div
                      class="vp-bg-primary-500 vp-h-full"
                      :style="{
                        width: (choice.share > 0 ? choice.share : '0') + '%',
                      }"
                    ></div>
                  </div>

                  <p class="vp-text-gray-500 vp-mt-2">
                    {{ choice.votes }} Votes
                  </p>
                </div>

                <div class="vp-ml-2 vp-w-48 vp-text-right">
                  <h3 class="vp-text-2xl vp-font-bold">
                    {{ choice.share > 0 ? choice.share : "0" }} %
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="vp-col-span-6">
          <div class="vp-grid vp-grid-cols-2 vp-gap-2">
            <div class="vp-p-4 vp-rounded-xl vp-bg-gray-100">
              <p class="vp-text-2xl vp-font-bold">{{ response?.voteCount }}</p>
              <label class="vp-text-gray-500">Total Votes</label>
            </div>
            <div class="vp-p-4 vp-rounded-xl vp-bg-gray-100">
              <p class="vp-text-2xl vp-font-bold">
                {{ privacy[response?.privacy] }}
              </p>
              <label class="vp-text-gray-500">Privacy</label>
            </div>
            <div class="vp-p-4 vp-rounded-xl vp-bg-gray-100">
              <p class="vp-capitalize vp-text-2xl vp-font-bold">
                {{ response?.multipleVotes }}
              </p>
              <label class="vp-text-gray-500">Multiple Votes</label>
            </div>
            <div class="vp-p-4 vp-rounded-xl vp-bg-gray-100">
              <p class="vp-capitalize vp-text-2xl vp-font-bold">
                {{ response?.status }}
              </p>
              <label>Status</label>
            </div>
            <div class="vp-p-4 vp-rounded-xl vp-bg-gray-100 vp-col-span-2">
              <p class="vp-capitalize vp-text-2xl vp-font-bold">
                <VpDatetime :value="response?.createdAt" />
              </p>
              <label class="vp-text-gray-500">Created</label>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #footer>
      <div></div>
    </template>
  </VpVueForm>
</template>

<script>
import get from "./get.gql";
import move from "./move.gql";

export default {
  track: {
    id: "Id",
    name: "Name",
    question: "Question",
    options: {
      key: "Options",
      value: (options) =>
        options.length
          ? options.map((option) => option.label).join(", ")
          : "[]",
    },
    privacy: "Privacy",
    status: "Status",
    multipleVotes: "Multiple Votes",
    isPinned: "Pin in Extension",
  },
  props: {
    id: [Number, String],
  },
  data() {
    return {
      privacy: {
        private: "🔒",
        public: "🌏",
      },
    };
  },
  methods: {
    get(id) {
      return this.$query(get, {
        id: id,
      }).then(({ data }) => {
        this.$emit("poll", data.poll);
        return {
          values: data.poll,
          res: data,
        };
      });
    },
    move(id, action) {
      return this.$mutate(move, {
        id: parseInt(id),
        action,
      }).then(({ data }) => {
        this.$root.$emit("rehydrate");
        this.$router.push({ name: "polls" });
        return data;
      });
    },
  },
};
</script>
