<template>
  <VpVueForm
    id="true"
    :fields="fields"
    class="vp-p-6"
    :get="get"
    width="400px"
    ref="item"
    :save="save"
    #default="{ state, response }"
    :track="$options.track"
    track-prefix="Integration"
    track-event-name="Configured"
    cache="integration"
    collection-cache="integrations"
    feature="INTEGRATION_GOOGLE"
  >
    <VpPageHeader title="Google" />

    <VpStatusInput id="google-status" v-model="state.status" />

    <template>
      <!-- CONNECTED -->
      <VpField label="Connected As">
        <div
          v-if="response.user && !connectingUser"
          class="vp-bg-gray-100 vp-rounded-xl vp-p-6 vp-gap-4"
        >
          <VpField label="Name">
            <p>{{ response.user.name }}</p>
          </VpField>
          <VpField label="Email">
            <p>{{ response.user.email }}</p>
          </VpField>
          <VpField label="Locale">
            <p>{{ response.user.locale }}</p>
          </VpField>

          <div>
            <VyButton
              label="Disconnect"
              :loading="disconnectingUser"
              @click.native="
                checkPermission('INTEGRATION_GOOGLE', 'delete', () => {
                  disconnect(response);
                })
              "
              class="button--warning button--solid button--sm button--rounded"
            />
          </div>
        </div>

        <div v-else>
          <VyButton
            v-if="connectingUser"
            class="button--warning button--solid button--sm button--rounded"
            :loading="connectingUser"
          />
          <button type="button" v-else @click="connect(response.loginEndpoint)">
            <img
              class="vp-w-56"
              src="/img/google-sign-in.png"
              alt="Google Sign In"
              loading="lazy"
            />
          </button>
        </div>
      </VpField>
    </template>
  </VpVueForm>
</template>

<script>
import { mapGetters } from "vuex";
import deleteUser from "./delete-user.gql";
import updateUser from "./update-user.gql";
import get from "./get.gql";
import update from "./update.gql";

export default {
  track: {
    id: {
      key: "Provider",
      value: () => "Google",
    },
  },
  components: {},

  data() {
    return {
      fields: ["status"],
      connectingUser: false,
      disconnectingUser: false,
    };
  },

  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
  },

  methods: {
    connect(url) {
      window.location.href = url;
    },
    async disconnect() {
      try {
        this.disconnectingUser = true;
        await this.$mutate(deleteUser);
        this.$refs.item.refresh();
      } catch (err) {
        console.error(err);
      }
      this.disconnectingUser = false;
    },

    async checkForCode() {
      const code = this.$route.query?.code ?? null;
      if (code) {
        this.connectingUser = true;
        try {
          await this.$mutate(updateUser, {
            input: {
              code,
            },
          });
          this.$refs.item.refresh();
          window.history.replaceState(
            {},
            document.title,
            "/#" + this.$route.path
          );
        } catch (err) {
          console.error(err);
        }
        this.connectingUser = false;
      }
    },

    get() {
      return this.$query(get).then(({ data }) => {
        if (this.checkPermission("INTEGRATION_GOOGLE", "upsert")) {
          this.checkForCode();
        }
        return {
          values: data.integration.google,
          res: data.integration,
        };
      });
    },

    save(id, data) {
      return this.$mutate(update, {
        input: {
          ...data,
        },
      }).then(({ data }) => {
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: "integrations",
        });
        this.$router.push({ name: "integrations" });
        return data;
      });
    },
  },
};
</script>
