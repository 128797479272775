<template>
  <div>
    <DashboardHeader
      :to="{ name: 'home' }"
      :icon="$options.icons.Dashboard"
      title="Dashboard"
      desc="Here’s a snapshot of your latest activity and insights."
    />

    <Url
      class="vp-mt-6"
      url="https://web.whatsapp.com"
      display-url="web.whatsapp.com"
      :edit-domain="false"
      title="Visit Web WhatsApp to start using CRM Features"
      target="web-whatsapp"
    />

    <div
      v-if="checklist.length"
      class="vp-mt-4 vp-grid sm:vp-grid-cols-2 vp-gap-4"
    >
      <template v-for="check in checklist">
        <template v-if="check.title.includes('Chrome Extension')">
          <ActionCard
            v-if="!extensionVersion"
            :key="check.key"
            :href="check.route"
            target="_blank"
            :icon="check.icon"
            :title="check.title"
            :desc="check.desc"
            @click.native="segmentAnalytics(check.key)"
          />
        </template>
        <template v-else-if="check.route.includes('https')">
          <ActionCard
            :key="check.key"
            :href="check.route"
            target="_blank"
            :icon="check.icon"
            :title="check.title"
            :desc="check.desc"
            @click.native="segmentAnalytics(check.key)"
          />
        </template>
        <template v-else>
          <ActionCard
            :key="check.key"
            :icon="check.icon"
            :title="check.title"
            :desc="check.desc"
            @click.native="
              checkPermission(check.feature, 'upsert', () => {
                redirectToDetailPage(check);
              })
            "
          />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { sendMessageToExt } from "@/utils";
import ActionCard from "components/src/action-card.vue";
import Url from "./Url.vue";
import DashboardHeader from "components/src/dashboard/Header.vue";
import { mapGetters } from "vuex";
import { Dashboard } from "icons/icons.js";

export default {
  icons: {
    Dashboard,
  },
  components: {
    ActionCard,
    Url,
    DashboardHeader,
  },
  props: {
    campaign: Object,
    checklist: Array,
  },
  data() {
    return {
      extensionVersion: null,
    };
  },
  created() {
    sendMessageToExt("version", {}, (data) => {
      this.extensionVersion = data;
    });
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
  },
  methods: {
    redirectToDetailPage(check) {
      this.segmentAnalytics(check.key);
      this.$router.push(check.route);
    },

    segmentAnalytics(key) {
      this.$track("Checklist Item Clicked", {
        "Checklist Id": "STORE_ONBOARDING",
        "Checklist Item Id": key,
      });
    },
  },
};
</script>
