<template>
  <Popover :show="query == null || query == '' ? false : true">
    <template #trigger>
      <VpConnection v-if="selected" type="contact" :data="selected">
        <template #actions>
          <VpActionButton
            :icon="$options.icons.Edit"
            @click.native="reset()"
            class="button--sm button--gray"
          />
        </template>
      </VpConnection>

      <VpField v-else>
        <VpInput :after-icon="$options.icons.Search">
          <VpTextBox
            ref="input"
            placeholder="Name, Email, Country Code + Mobile No"
            :value="query"
            @input="search($event)"
          />
        </VpInput>
      </VpField>
    </template>
    <template #default>
      <div class="vp-relative vp-min-h-12">
        <template v-if="loading">
          <ConnectionShimmer v-for="n in 5" :key="`connection-${n}`" />
        </template>
        <div
          class="vp-p-4 vp-text-center vp-text-gray-500"
          v-else-if="results && results.length == 0"
        >
          <h5 class="vp-font-bold">Oops, No Contacts Found!</h5>
          <p class="vp-text-xs">
            You can search with Name, Email or a Mobile Number with Country
            Code.
          </p>
        </div>
        <template v-else>
          <VpConnection
            v-for="contact in results"
            :key="`contact-${contact.id}`"
            type="contact"
            :data="contact"
            class="vp-p-2 vp-border-b vp-border-gray-200 hover:vp-ring-4 vp-ring-inset vp-ring-gray-100 vp-cursor-pointer"
            @click.native="
              selected = contact;
              $emit('input', String(contact.id));
              query = null;
            "
          />
        </template>
      </div>
    </template>
  </Popover>
</template>

<script>
import ConnectionShimmer from "components/src/shimmers/connection.vue";
import { Edit, Search } from "icons/icons.js";
import { debounce } from "lodash-es";

import contactSearch from "@/graph/master/contactSearch.gql";
import Popover from "components/src/popover.vue";
import overview from "graph/contact/overview.gql";

export default {
  icons: {
    Search,
    Edit,
  },

  components: {
    ConnectionShimmer,
    Popover,
  },
  watch: {
    value(newValue) {
      // Reset when vp-list reset button is clicked.
      if (newValue) this.get();
      else this.reset();
    },
  },

  props: {
    value: [String, Number],
  },

  data() {
    return {
      query: null,
      results: null,
      loading: false,
      selected: null,
    };
  },

  methods: {
    search: debounce(async function (query) {
      this.query = query;
      if (!query) {
        this.results = null;
        return;
      }
      this.loading = true;

      try {
        const res = await this.$query(contactSearch, {
          search: this.query,
        });
        this.results = res.data.contacts.data;
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    }, 500),

    reset() {
      this.selected = null;
      this.results = null;
      this.query = null;
      this.$emit("input", null);
      this.$nextTick(() => {
        this.$refs.input.$el.focus();
      });
    },
    get() {
      return this.$query(overview, {
        id: Number(this.value),
      }).then(({ data }) => {
        this.selected = data.contact;
      });
    },
  },
};
</script>
