<template>
  <svg width="1em" height="1em" viewBox="0 0 24 24">
    <path
      d="M10 4v3h2.21l-3.42 8H6v3h8v-3h-2.21l3.42-8H18V4h-8z"
      fill="currentColor"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiFormatItalic",
};
</script>
