import gql from "graphql-tag";

export const TAG = gql`
  fragment tag on Tag {
    id
    name
    color
  }
`;

export const TAGS_GET = gql`
  query tags($filters: TagFilter) {
    tags(filters: $filters, perPage: -1) {
      data {
        ...tag
      }
    }
  }
  ${TAG}
`;

export const CONTACT_TAGS_GET = gql`
  query contactTags($contactId: Int!) {
    contact(id: $contactId) {
      id
      tags {
        ...tag
      }
    }
  }
  ${TAG}
`;

export const CONTACT_TAGS_UPSERT = gql`
  mutation saveContact($contactId: Int, $input: ContactInput!) {
    upsertContact(id: $contactId, input: $input, source: "whatsapp") {
      id
      tags {
        ...tag
      }
    }
  }
  ${TAG}
`;
