<template>
  <div>
    <TheTitle> {{ options.name }} </TheTitle>
    <div v-if="isEmpty" class="vp-w-full vp-h-full vp-m-0 vp-pt-8">
      <VpErrorPage title="No Data Found!" />
    </div>
    <div v-else class="vp-p-4">
      <ApexCharts
        :key="options.name"
        type="bar"
        height="360"
        :options="chartOptions"
        :series="series"
      />
    </div>
  </div>
</template>
<script>
import TheTitle from "./_Title.vue";
import colors from "style/src/colors.json";
import { DonutChart } from "icons/icons.js";

export default {
  icons: {
    DonutChart,
  },
  props: {
    values: Array,
    options: Object,
    isQuickReplyChart: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ApexCharts: () => import("vue-apexcharts"),

    TheTitle,
  },

  computed: {
    chartOptions() {
      return {
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: this.$props.isQuickReplyChart ? false : true,
            columnWidth: "20%",
          },
        },
        legend: {
          show: false,
        },
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: true,
            },
          },
        },
        xaxis: {
          categories: this.xAxis,
          labels: {
            trim: true,
          },
        },
        yaxis: {
          labels: {},
          tickAmount: 5,
          min: 0,
          max: this.max,
        },
        dataLabels: {
          enabled: false,
        },
        colors: this.colors,
        tooltip: {
          y: {
            formatter: this.amount,
          },
        },
      };
    },

    xAxis() {
      return this.values?.map((e) => e.x);
    },

    colors() {
      if (this.$props.isQuickReplyChart) return "#0D47A1";
      else {
        return this.values?.map((e) => {
          return e.color.includes("#") ? e.color : colors[e.color]?.["500"];
        });
      }
    },

    isEmpty() {
      return this.series?.every((item) => item.data.length == 0);
    },

    max() {
      return this.values
        ?.map((order) => order.y)
        .reduce((a, b) => Math.max(a, b), 0);
    },

    series() {
      let seriesValue = this.values?.map((order) => order.y);
      return [{ name: this.options.name, data: seriesValue }];
    },
  },
};
</script>

<style scoped>
.apexcharts-toolbar {
  z-index: 9 !important;
}
</style>
