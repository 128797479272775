<template>
  <VpListLayout add-route="reminder" feature="REMINDER">
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="reminders"
      :params="params"
      class="vp-h-full"
      ref="list"
      cache="reminders"
      title="Reminders"
      :request-payload="{ query: $options.reminders }"
      @rowClick="
        $router.push({
          name: 'reminder',
          params: {
            id: $event.id,
          },
        })
      "
      feature="REMINDER"
    >
      <template #empty>
        <VpListEmpty :feature="feature" route="reminder" />
      </template>
      <template #id="{ item }">
        <p class="vp-text-gray-500">#{{ item.id }}</p>
      </template>
      <template #contact="{ item }">
        <VpConnection type="contact" :data="item.contact" />
      </template>
      <template #remindAt="{ item }">
        <VpDatetime
          class="vp-text-gray-500 vp-text-sm"
          :value="item.remindAt"
        />
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import reminders from "@/views/crm/reminders/collection.gql";

export default {
  reminders,
  props: {
    feature: Object,
  },
  computed: {
    columns() {
      return [
        { name: "id", fix: true, label: "ID", type: "string" },
        { name: "title", label: "Title", fix: true, type: "string" },
        {
          name: "description",
          label: "Description",
          fix: true,
          type: "string",
        },
        { name: "contact", fix: true, width: "140px", type: "string" },
        { name: "remindAt", fix: true, type: "string" },
      ];
    },
  },
};
</script>
