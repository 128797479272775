<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.25rem"
    height="1.25rem"
    viewBox="0 0 24 24"
  >
    <path
      fill="currentColor"
      d="M13 9.5h5v-2h-5v2m0 7h5v-2h-5v2m6 4.5H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2M6 11h5V6H6v5m1-4h3v3H7V7M6 18h5v-5H6v5m1-4h3v3H7v-3Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "MdiBallot",
};
</script>
