<template>
  <VpModal
    title="View Message"
    _class="vp-w-1/2 vp-max-h-[550px]"
    name="saved-modal"
    @open="open"
    @close="close"
  >
    <div v-if="data" class="vp-no-underline vp-bg-white vp-flex vp-flex-row">
      <div class="vp-w-full vp-p-4">
        <!-- TEXT -->
        <template v-if="type == 'text'">
          <p class="vp-whitespace-pre-line">
            {{ data.text }}
          </p>
        </template>

        <!-- IMAGE -->
        <div
          class="vp-w-full vp-h-full"
          v-else-if="type == 'image' && data.media"
        >
          <img
            class="vp-w-full vp-h-full vp-max-[400px] vp-object-contain"
            :src="mediaUrl"
            alt=""
          />
        </div>

        <!-- VIDEO -->
        <div class="vp-h-full vp-w-full" v-else-if="type == 'video'">
          <video
            class="vp-h-full vp-w-full vp-object-contain vp-outline-none"
            controls
          >
            <source :src="mediaUrl" :type="mimetype" />
          </video>
        </div>

        <!-- AUDIO -->
        <div class="vp-w-full" v-else-if="type == 'audio'">
          <audio class="vp-w-full vp-outline-none" controls>
            <source :src="mediaUrl" :type="mimetype" />
          </audio>
        </div>

        <!-- DOCUMENTS -->
        <div class="vp-h-full" v-else-if="type == 'document'">
          <embed :src="mediaUrl" width="100%" height="100%" :type="mimetype" />
        </div>
      </div>
      <div class="vp-w-96 vp-border-l vp-border-gray-200 vp-p-4">
        <div>
          <VpConnection
            has-link
            type="contact"
            :data="data.receiver || data.sender"
          />
          <div class="vp-gap-6 vp-mt-6">
            <VpField label="Tags">
              <TagsInput
                class="vp-m-0"
                :value="localTags || data.tags.map((item) => parseInt(item.id))"
                :type="type"
                @add="toggleTag('attachTag', $event)"
                @remove="toggleTag('detachTag', $event)"
              />
            </VpField>
            <VpField v-if="data.receivedOn" label="Received">
              <VpDatetime tag="span" :value="data.receivedOn" />
            </VpField>
            <VpField v-if="data.savedOn" label="Saved">
              <VpDatetime tag="span" :value="data.savedOn" />
            </VpField>
            <VpField v-if="data.media && data.media.size" label="Size">
              <p>{{ data.media.size | filesize }}</p>
            </VpField>
          </div>
        </div>

        <div class="vp-flex vp-justify-between vp-py-2">
          <VyButton
            v-if="type == 'text'"
            :icon="$options.icons.Copy"
            class="button--primary button--solid button--md button--rounded"
            @click.native="copy"
            label="Copy Message"
          />

          <VyButton
            v-else-if="data.media.media && data.media.media.url"
            :icon="$options.icons.Download"
            @click.native="downloadMessage(data)"
            class="button--primary button--solid button--md button--rounded"
            label="Download"
          />

          <VyButton
            @click.native="checkPermission('MESSAGE', 'delete', del)"
            :icon="$options.icons.Delete"
            class="button--danger button--muted button--square button--md button--rounded"
          />
        </div>
      </div>
    </div>
  </VpModal>
</template>

<script>
import { Copy, Delete, Download } from "icons/icons.js";
import { mapGetters } from "vuex";
import TagsInput from "components/src/tags/input.vue";

import attachTag from "./attach-tag.gql";
import detachTag from "./detach-tag.gql";
import move from "./move.gql";

export default {
  icons: {
    Copy,
    Download,
    Delete,
  },
  props: {
    data: Object,
    type: String,
  },

  components: {
    TagsInput,
  },

  data() {
    return {
      localTags: null,
      tagLoading: false,
      dirty: false,
    };
  },
  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
    mediaUrl() {
      return this.data?.media?.media?.url;
    },
    mimetype() {
      return this.data?.media?.media?.mimetype;
    },
  },
  methods: {
    async open() {
      this.dirty = false;
      this.localTags = null;
      this.tagLoading = false;
    },
    close() {
      if (this.dirty) {
        this.$emit("refresh");
      }
    },
    copy() {
      this.$vayu.copy(this.data.text);
      this.segmentAnalytics(this.data, "Clicked Message Copy");
      this.$vayu.notify({
        state: "info",
        title: "Copied!",
      });
    },
    async del() {
      const confirmMessage = "Are you sure to delete this message?";
      if (confirm(confirmMessage)) {
        try {
          await this.$mutate(move, {
            id: this.data.id,
          }).then(() => {
            this.segmentAnalytics(this.data, "Message Deleted");
          });
          this.$vayu.notify({
            state: "success",
            title: "Deleted.",
          });
        } catch (err) {
          this.$vayu.notify({
            state: "danger",
            title: "Error!",
            message:
              "Failed to delete the message. Please refresh & try again.",
          });
        }
        this.$vayu.modal.close("saved-modal");
        this.$emit("refresh");
      }
    },
    async toggleTag(action, tag) {
      if (this.checkPermission("TAG", "upsert")) {
        this.dirty = true;
        const request = action == "attachTag" ? attachTag : detachTag;
        try {
          this.tagLoading = true;
          const res = await this.$mutate(request, {
            messageId: this.data.id,
            tagId: [tag],
          });
          const response =
            action == "attachTag"
              ? res.data.attachTagsToMessage
              : res.data.detachTagsFromMessage;
          this.localTags = response.tags.map((tag) => parseInt(tag.id));
          this.segmentAnalytics(response, "Message Updated");
          this.$vayu.notify({
            state: "success",
            title: "Tag Updated.",
          });
        } catch (err) {
          this.$vayu.notify({
            state: "danger",
            title: "Error!",
            message: "Failed to update tag. Please refresh & try again.",
          });
        }
        this.tagLoading = false;
      }
    },
    downloadMessage(data) {
      this.segmentAnalytics(data, "Clicked Message Media Download");
      window.open(data.media.media.url, "_blank");
    },

    segmentAnalytics(data, action) {
      this.$track(action, {
        "Message Id": data.id,
        "Message Type": data.type || "",
        "Message Text": data?.text ?? "",
        "Message Direction": data.direction || "",
        "Message Sender":
          data.sender?.name ??
          data.sender?.mobileNumber.internationalFormat ??
          "",
        "Message Receiver":
          data.receiver?.name ??
          data.receiver?.mobileNumber.internationalFormat ??
          "",
        "Message Media": data.media?.media?.url ?? "",
      });
    },
  },
};
</script>
