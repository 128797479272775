<template>
  <VpModal
    name="delete-contact"
    :title="`Delete ${selectedContactsCount} ${
      selectedContactsCount > 1 ? 'Contacts' : 'Contact'
    }?`"
    _class="vp-w-1/3"
    @close="isUserConfirm = false"
  >
    <template #default>
      <div class="vp-p-4">
        If you choose to delete this
        {{ selectedContactsCount > 1 ? "Contacts" : "Contact" }}, please be
        aware that the related data below will also be removed. Kindly confirm
        your decision before proceeding with the deletion:

        <ul class="vp-mt-2 vp-pl-4">
          <li class="vp-list-disc">Reminders</li>
          <li class="vp-list-disc">Tags</li>
          <li class="vp-list-disc">Funnels</li>
          <li class="vp-list-disc">Group Participants</li>
          <li class="vp-list-disc">Shipping Address</li>
          <li class="vp-list-disc">Product Reviews</li>
          <li class="vp-list-disc">Poll Votes</li>
          <li class="vp-list-disc">Chats</li>
          <li class="vp-list-disc">Messages</li>
          <li class="vp-list-disc">Tickets</li>
        </ul>

        <VyCheckbox
          class="vp-mt-4 choice--md"
          v-model="isUserConfirm"
          label="I Agree"
        />
      </div>
    </template>

    <template #footer-actions="{ close }">
      <VyButton
        label="Cancel"
        @click.native="close()"
        class="button--gray button--muted button--md button--rounded"
      />
      <VyButton
        :loading="isDeleting"
        :label="
          selectedContactsCount > 1 ? 'Delete Contacts' : 'Delete Contact'
        "
        :disabled="!isUserConfirm"
        @click.native="deleteContacts"
        class="vp-ml-2 button--danger button--solid button--md button--rounded"
      />
    </template>
  </VpModal>
</template>

<script>
import move from "graph/contact/move.gql";
export default {
  props: {
    selectedContactsCount: Number,
    selectedContactsId: Array,
  },

  data() {
    return {
      isDeleting: false,
      isUserConfirm: false,
    };
  },
  methods: {
    deleteContacts() {
      this.isDeleting = true;
      this.$mutate(move, {
        id: this.selectedContactsId,
        action: "delete",
      })
        .then(() => {
          this.$track("Bulk Action Contact Deleted", {
            "Number Of Contact Deleted": this.selectedContactsId.length,
          });
          this.$vayu.notify({
            title: "Success!",
            state: "success",
          });
          this.$vayu.modal.close("delete-contact");
          this.$emit("contacts-deleted");
        })
        .catch((error) => {
          console.error("🚀 ~ deleteContacts ~ error:", error);
          const errorMessage = error?.message;
          this.$vayu.notify({
            title: "Error!",
            message: errorMessage
              ? errorMessage
              : "Somthing went wrong, please try again",
            state: "danger",
          });
        })
        .finally(() => {
          this.isDeleting = false;
          this.isUserConfirm = false;
        });
    },
  },
};
</script>
