import ContactIndex from "components/src/contacts/Index.vue";
import ContactList from "components/src/contacts/List.vue";
import ContactItem from "@/views/contacts/Item.vue";
import ActivityList from "components/src/contacts/activity/List.vue";
import ContactEdit from "components/src/contacts/Edit.vue";
import ContactStatistics from "components/src/contacts/Statistics.vue";
// import OrderExports from "@/views/store/orders/Exports.vue"
import ContactReviews from "components/src/contacts/Reviews.vue";
import BulkIndex from "components/src/contacts/bulk/Index.vue";
import BulkHistory from "components/src/contacts/bulk/History.vue";
import BulkImport from "components/src/contacts/bulk/Import.vue";
import Bulkupdate from "components/src/contacts/bulk/Update.vue";

export default [
  {
    path: "contacts",
    component: ContactIndex,
    children: [
      {
        path: "",
        name: "contacts",
        component: ContactList,
        meta: {
          label: "Contacts",
          breadcrumbs: ["home", "contacts"],
        },
      },
      {
        path: ":id",
        component: ContactItem,
        props: true,
        children: [
          {
            path: "",
            name: "contact",
            redirect: "profile",
            props: true,
            meta: {
              label: "Contact",
              breadcrumbs: ["home", "contacts", "contact"],
            },
          },
          {
            path: "activity",
            name: "contact-activity",
            component: ActivityList,
            props: true,
            meta: {
              label: "Activity",
              breadcrumbs: [
                "home",

                "crm",
                "contacts",
                "contact",
                "contact-activity",
              ],
            },
          },
          {
            path: "profile",
            name: "contact-profile",
            component: ContactEdit,
            props: true,
            meta: {
              label: "Profile",
              breadcrumbs: ["home", "contacts", "contact", "contact-profile"],
            },
          },
          {
            path: "statistics",
            name: "contact-statistics",
            component: ContactStatistics,
            props: true,
            meta: {
              label: "Statistics",
              breadcrumbs: [
                "home",

                "contacts",
                "contact",
                "contact-statistics",
              ],
            },
          },
          // {
          //   path: "orders/contact-exports",
          //   name: "contact-order-export-history",
          //   component: OrderExports,
          //   meta: {
          //     label: "Contact Order Export History",
          //     breadcrumbs: [
          //
          //       "crm",
          //       "contacts",
          //       "contact",
          //       "contact-order-export-history",
          //     ],
          //   },
          // },
          {
            path: "reviews",
            name: "contact-reviews",
            component: ContactReviews,
            props: true,
            meta: {
              label: "Reviews",
              breadcrumbs: ["home", "contacts", "contact", "contact-reviews"],
            },
          },
        ],
      },
      {
        path: "bulk",
        component: BulkIndex,

        children: [
          {
            path: "",
            name: "contacts-bulk",
            redirect: "history",
            meta: {
              label: "Bulk Actions",
              breadcrumbs: ["home", "crm", "contacts", "contacts-bulk"],
            },
          },
          {
            path: "history",
            name: "contacts-bulk-history",
            component: BulkHistory,
            meta: {
              label: "History",
              breadcrumbs: [
                "home",

                "contacts",
                "contacts-bulk",
                "contacts-bulk-history",
              ],
            },
          },
          {
            path: "import",
            name: "contacts-bulk-import",
            component: BulkImport,
            meta: {
              label: "Import",
              breadcrumbs: [
                "home",

                "crm",
                "contacts",
                "contacts-bulk",
                "contacts-bulk-import",
              ],
            },
          },
          {
            path: "update",
            name: "contacts-bulk-update",
            component: Bulkupdate,
            meta: {
              label: "Update",
              breadcrumbs: [
                "home",

                "crm",
                "contacts",
                "contacts-bulk",
                "contacts-bulk-update",
              ],
            },
          },
        ],
      },
    ],
  },
];
