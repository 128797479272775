<template>
  <div class="tiptap-editor" :class="`tiptap-editor--${size}`">
    <div class="tiptap-editor__topbar">
      <!-- Need to check for null, otherwise got warning for isActive method -->

      <HeaderButton
        :icon="$options.icons.Bold"
        @click.native="editor.chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor !== null && editor.isActive('bold') }"
      />

      <HeaderButton
        :icon="$options.icons.Italic"
        @click.native="editor.chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor !== null && editor.isActive('italic') }"
      />

      <HeaderButton
        :icon="$options.icons.Underline"
        @click.native="editor.chain().focus().toggleUnderline().run()"
        :class="{
          'is-active': editor !== null && editor.isActive('underline'),
        }"
      />

      <HeaderButton
        :icon="$options.icons.BulletList"
        @click.native="editor.chain().focus().toggleBulletList().run()"
        :class="{
          'is-active': editor !== null && editor.isActive('bulletList'),
        }"
      />

      <HeaderButton
        :icon="$options.icons.NumberList"
        @click.native="editor.chain().focus().toggleOrderedList().run()"
        :class="{
          'is-active': editor !== null && editor.isActive('orderedList'),
        }"
      />
    </div>
    <EditorContent :editor="editor" class="tiptap-editor__content" />
    <div class="tiptap-editor__character-count" v-if="editor">
      {{ 1000 - editor.storage.characterCount.characters() }}
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import UnderlineExt from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import CharacterCount from "@tiptap/extension-character-count";
import HeaderButton from "./header-button.vue";
import {
  BulletList,
  Bold,
  Italic,
  Underline,
  NumberList,
} from "icons/icons.js";

export default {
  icons: {
    BulletList,
    Bold,
    Italic,
    Underline,
    NumberList,
  },
  props: {
    value: String,
    size: {
      type: String,
      default: "md",
    },
  },
  components: {
    EditorContent,
    HeaderButton,
  },
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        UnderlineExt,
        Link.configure({
          openOnClick: false,
        }),
        CharacterCount.configure({
          limit: 1000,
        }),
      ],
      onUpdate: ({ editor }) => {
        if (editor.getText() === "") {
          this.$emit("input", null);
        } else {
          this.$emit("input", editor.getHTML());
        }
      },
    });
  },
  watch: {
    value() {
      this.editor.commands.setContent(this.value, false, {
        preserveWhitespace: "full",
      });
    },
  },
  methods: {
    reset() {
      this.editor.commands.clearContent();
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style>
.tiptap-editor {
  @apply vp-bg-gray-200;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  word-break: break-all;
  &__topbar {
    @apply vp-border-b vp-border-gray-300;
    display: flex;
    .button {
      &.is-active {
        @apply vp-text-gray-800;
        &::after {
          content: "";
          position: absolute;
          border: 1px solid;
          bottom: -1px;
          left: 0;
          width: 100%;
        }
      }
    }
  }
  &__character-count {
    @apply vp-text-gray-500;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    padding: 8px;
    user-select: none;
  }
  &__content {
    padding: 8px;
    overflow: auto;
    .ProseMirror {
      height: 100%;
      outline: none !important;
    }
  }
  &--md {
    .tiptap-editor__content {
      height: 150px;
    }
  }
  &--lg {
    .tiptap-editor__content {
      height: 250px;
    }
  }
}

.tiptap-editor__content {
  ul,
  ol {
    padding-inline-start: 15px !important;
    margin: 0 !important;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }
}
</style>
