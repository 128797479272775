<template>
  <VpVueForm
    id="true"
    :fields="fields"
    class="vp-p-6"
    :get="get"
    width="400px"
    ref="item"
    :save="save"
    #default="{ state }"
    :track="$options.track"
    track-prefix="Integration"
    track-event-name="Configured"
    collection-cache="integrations"
    feature="INTEGRATION_WHATSAPP_CLOUD_API"
  >
    <VpPageHeader title="WhatsApp Cloud API" />

    <VpStatusInput
      id="whatsapp-cloud-api-status"
      v-model="state.isEnabled"
      is-enum
    />

    <VpField rules="required" label="Token" name="Token">
      <VpInput>
        <VpTextBox v-model="state.token" />
      </VpInput>
    </VpField>

    <VpField rules="required" label="Phone Number ID" name="Phone Number ID">
      <VpInput>
        <VpTextBox
          type="number"
          v-model="state.phoneNumberId"
          placeholder="e.g. 40XXXXXXXXXXX15"
        />
      </VpInput>
    </VpField>
  </VpVueForm>
</template>

<script>
import get from "./get.gql";
import update from "./upsert.gql";

export default {
  track: {
    token: {
      key: "Provider",
      value: () => "WhatsApp Cloud API",
    },
  },
  components: {},

  data() {
    return {
      fields: ["token", "phoneNumberId", "isEnabled"],
    };
  },

  methods: {
    get() {
      return this.$query(get).then(({ data }) => ({
        values: data.integrationWhatsappCloudApi || {
          token: "",
          phoneNumberId: "",
          isEnabled: true,
        },
        res: data,
      }));
    },

    save(id, data) {
      return this.$mutate(update, {
        input: data,
      }).then(({ data }) => {
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: "integration",
        });
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: "integrationWhatsappCloudApi",
        });
        this.$router.push({ name: "integrations" });
        return data;
      });
    },
  },
};
</script>
