<template>
  <ColorButton
    v-if="value"
    :label="value.name"
    :color="value.color"
    class="button--sm"
  />
</template>

<script>
import ColorButton from "../color-button.vue";

export default {
  props: {
    value: Object,
  },
  components: {
    ColorButton,
  },
};
</script>
