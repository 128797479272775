<template>
  <Reviews :contact-id="$route.params.id" filter-id="contact-reviews" />
</template>

<script>
import Reviews from "../reviews/index.vue";

export default {
  components: {
    Reviews,
  },
};
</script>
