<template>
  <VpLoaderContainer class="vp-py-12" loading v-if="loading" />
  <NotAllowed v-else-if="isApi && !isWhatsAppCloudEnabled" />

  <VpListLayout
    v-else
    :add-route="{
      name: 'whatsapp-broadcast',
      params: { via: $route.params.via, id: '+' },
    }"
    feature="WHATSAPP_BROADCAST"
    permission="upsert"
  >
    <VpList
      :per-page="25"
      :columns="columns"
      :endpoint="
        isApi ? 'whatsappCloudApiBroadcast' : 'whatsappExtensionBroadcast'
      "
      class="vp-h-full"
      ref="list"
      :search="false"
      :request-payload="{
        query: isApi
          ? $options.whatsappCloudApiBroadcast
          : $options.whatsappExtensionBroadcast,
      }"
      :cache="
        isApi ? 'whatsappCloudApiBroadcasts' : 'whatsappExtensionBroadcasts'
      "
      :title="
        isApi ? 'Broadcast With Official API' : 'Broadcast With Extension'
      "
      @rowClick="
        $router.push({
          name: 'whatsapp-broadcast-analytics',
          params: {
            id: $event.id,
          },
        })
      "
      feature="WHATSAPP_BROADCAST"
    >
      <template #empty>
        <VpListEmpty :feature="feature" route="whatsapp-broadcast" />
      </template>
      <template #media="{ item }">
        <VpMedia :value="item.media" />
      </template>

      <template #progress="{ item }">
        <ProgressBar class="vp-w-full" :progress="item.summary.progress" />
      </template>

      <template #status="{ item }">
        <Status :value="item.status" />
      </template>
    </VpList>
  </VpListLayout>
</template>

<script>
import ProgressBar from "@/components/progress-bar.vue";
import whatsappCloudApi from "./cloud-api/integration.gql";

import NotAllowed from "./_NotAllowed.vue";
import Status from "./_Status.vue";

//Marketing
import whatsappCloudApiBroadcast from "@/views/crm/marketing/whatsapp-broadcast/cloud-api/collection.gql";
import whatsappExtensionBroadcast from "@/views/crm/marketing/whatsapp-broadcast/extension/collection.gql";

export default {
  whatsappCloudApiBroadcast,
  whatsappExtensionBroadcast,
  props: {
    feature: Object,
  },
  components: {
    ProgressBar,
    Status,
    NotAllowed,
  },
  data() {
    return {
      loading: false,
      isWhatsAppCloudEnabled: false,
    };
  },
  computed: {
    isApi() {
      return this.$route.params.via == "api";
    },

    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "media", fix: true, type: "string" },
        { name: "name", type: "string" },
        { name: "progress", type: "string", width: "300px" },
        {
          name: "status",
          type: "string",
          fix: true,
        },
      ];
    },
  },
  created() {
    if (this.isApi) {
      this.checkApi();
    }
  },
  methods: {
    checkApi() {
      this.loading = true;
      return this.$query(whatsappCloudApi)
        .then(({ data: { integrationWhatsappCloudApi } }) => {
          if (
            integrationWhatsappCloudApi &&
            integrationWhatsappCloudApi.isEnabled
          ) {
            this.isWhatsAppCloudEnabled = true;
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
