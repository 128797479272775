<template>
  <VpShell :aside="false">
    <VpFeature the-key="TAG" #default="{ feature }">
      <router-view :feature="feature" />
    </VpFeature>
  </VpShell>
</template>

<script>
export default {};
</script>
