import integrations from "./integrations";

import TicketStatusesList from "@/views/crm/ticket-statuses/List.vue";
import TicketStatusesItem from "@/views/crm/ticket-statuses/Item.vue";
import QuickRepliesIndex from "@/views/crm/quick-replies/Index.vue";
import QuickRepliesList from "@/views/crm/quick-replies/List.vue";
import QuickRepliesItem from "@/views/crm/quick-replies/Item.vue";
import FunnelsIndex from "@/views/crm/funnels/Index.vue";
import FunnelsList from "@/views/crm/funnels/List.vue";
import FunnelsItem from "@/views/crm/funnels/Item.vue";
import TagsIndex from "@/views/crm/tags/Index.vue";
import TagsList from "@/views/crm/tags/List.vue";
import TagsItem from "@/views/crm/tags/Item.vue";
import BizInfo from "@/views/settings/BizInfo.vue";
import BizList from "@/views/biz/List.vue";
import TierIndex from "@/views/crm/settings/tiers/Index.vue";
import TierList from "@/views/crm/settings/tiers/List.vue";
import TierItem from "@/views/crm/settings/tiers/Item.vue";

export default [
  {
    path: "about",
    name: "biz-info",
    component: BizInfo,
    meta: {
      label: "About",
      breadcrumbs: ["home", "settings", "biz-info"],
    },
  },
  ...integrations,
  {
    path: "tiers",
    component: TierIndex,
    children: [
      {
        path: "",
        name: "tiers",
        component: TierList,
        meta: {
          label: "Tiers",
          breadcrumbs: ["home", "settings", "tiers"],
        },
      },
      {
        path: ":id",
        name: "tier",
        component: TierItem,
        props: true,
        meta: {
          label: "Tier",
          breadcrumbs: ["home", "settings", "tiers", "tier"],
        },
      },
    ],
  },

  {
    path: "businesses",
    name: "businesses",
    component: BizList,
    meta: {
      label: "My Businesses",
      breadcrumbs: ["home", "settings", "businesses"],
    },
  },
  {
    path: "quick-replies",
    component: QuickRepliesIndex,
    children: [
      {
        path: "",
        name: "quick-replies",
        component: QuickRepliesList,
        props: true,
        meta: {
          label: "Quick Replies",
          breadcrumbs: ["home", "crm", "settings", "quick-replies"],
        },
      },
      {
        path: ":id",
        name: "quick-reply",
        component: QuickRepliesItem,
        props: true,
        meta: {
          label: "Quick Reply",
          breadcrumbs: [
            "home",

            "crm",
            "settings",
            "quick-replies",
            "quick-reply",
          ],
        },
      },
    ],
  },
  {
    path: "funnels",
    component: FunnelsIndex,
    children: [
      {
        path: "",
        name: "funnels",
        component: FunnelsList,
        meta: {
          label: "Funnels",
          breadcrumbs: ["home", "settings", "funnels"],
        },
      },
      {
        path: ":id",
        name: "funnel",
        component: FunnelsItem,
        props: true,
        meta: {
          label: "Funnel",
          breadcrumbs: ["home", "settings", "funnels", "funnel"],
        },
      },
    ],
  },
  {
    path: "tags",
    component: TagsIndex,
    children: [
      {
        path: "",
        name: "tags",
        component: TagsList,
        meta: {
          label: "Tags",
          breadcrumbs: ["home", "settings", "tags"],
        },
      },
      {
        path: ":id",
        name: "tag",
        component: TagsItem,
        props: true,
        meta: {
          label: "Tag",
          breadcrumbs: ["home", "settings", "tags", "tag"],
        },
      },
    ],
  },

  {
    path: "ticket-statuses",
    name: "ticket-statuses",
    component: TicketStatusesList,
    meta: {
      label: "Ticket Status",
      breadcrumbs: ["home", "settings", "ticket-statuses"],
    },
  },
  {
    path: "ticket-statuses/:id",
    name: "ticket-status",
    component: TicketStatusesItem,
    props: true,
    meta: {
      label: "Ticket Status",
      breadcrumbs: ["home", "settings", "ticket-statuses", "ticket-status"],
    },
  },
];
