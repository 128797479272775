<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    class="vp-p-6"
    :save="save"
    :get="get"
    :del="del"
    :archive="del"
    width="400px"
    collection-cache="tiers"
    :track="$options.track"
    track-prefix="tier"
    feature="TIER"
  >
    <template #default="{ state }">
      <VpField rules="required" name="Name" label="Name">
        <VpInput>
          <VpTextBox v-model="state.name" />
        </VpInput>
      </VpField>

      <VpField rules="required" name="color" label="Color">
        <ColorPicker v-model="state.color" />
      </VpField>
    </template>
  </VpVueForm>
</template>

<script>
import ColorPicker from "components/src/color/picker.vue";
import get from "graph/tier/get.gql";
import move from "graph/tier/move.gql";
import upsert from "graph/tier/upsert.gql";

export default {
  track: {
    id: "Id",
    name: "Name",
    color: "Color",
  },
  props: {
    id: [Number, String],
    feature: Object,
  },
  components: {
    ColorPicker,
  },
  data() {
    return {
      fields: ["name", { name: "color", value: "grey" }],
    };
  },
  methods: {
    get(id) {
      return this.$query(get, {
        id: id,
      }).then(({ data }) => {
        return {
          values: data.tier,
          res: data,
        };
      });
    },
    save(id, data) {
      const inputs = {
        input: data,
      };
      if (id) inputs.id = id;
      return this.$mutate(upsert, inputs).then(({ data }) => {
        this.$router.push({ name: "tiers" });
        return data;
      });
    },
    del(id, action) {
      return this.$mutate(move, {
        id: parseInt(id),
        action,
      }).then(({ data }) => {
        this.$router.push({ name: "tiers" });

        return data;
      });
    },
  },
};
</script>
