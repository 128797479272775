<template>
  <VpListLayout class="vp-h-full">
    <!-- LIST -->
    <VpList
      :per-page="10"
      :columns="columns"
      endpoint="whatsAppGroups"
      :filters.sync="filters"
      :request-payload="{ query: $options.whatsAppGroups }"
      :params="params"
      class="vp-h-full"
      title="WhatsApp Groups"
      cache="whatsAppGroups"
      ref="list"
      bulk
      @rowClick="$router.push(link($event))"
      export-endpoint="whatsAppGroup"
      export-feature="WHATSAPP_GROUP_EXPORT"
      export-history-route="exports"
      feature="WHATSAPP_GROUP"
    >
      <!-- FILTERS -->
      <template #filters>
        <VpField label="Name">
          <VpInput>
            <VpTextBox v-model="filters.name" />
          </VpInput>
        </VpField>

        <VpField label="Funnel">
          <FunnelInput v-model="filters.funnelId" />
        </VpField>

        <VpField label="Tags">
          <TagsInput v-model="filters.tagIds" type="contact" />
        </VpField>

        <VpField label="Duration">
          <VpDateRangePicker
            stack
            label="Duration"
            :value="[
              filters.duration && filters.duration.start,
              filters.duration && filters.duration.end,
            ]"
            @input="applyDate($event)"
            :max="null"
          />
        </VpField>
      </template>

      <template #empty>
        <VpFeature the-key="WHATSAPP_GROUP" #default="{ feature }">
          <VpListEmpty :feature="feature" />
        </VpFeature>
      </template>
      <!-- FIELDS -->
      <template #profilePicture="{ item }">
        <VpAvatar
          class="vp-rounded-md"
          :image="item.profilePicture && item.profilePicture.url"
          :name="item.name"
          size="sm"
        />
      </template>

      <template #name="{ item }">
        <p>{{ item.name }}</p>
      </template>

      <template #funnel="{ item }">
        <FunnelDisplay :value="item.funnel" />
      </template>

      <template #tags="{ item }">
        <TagsDisplay :value="item.tags" />
      </template>

      <template #creation="{ item }">
        <VpDatetime class="vp-text-gray-500" :value="item.creation" />
      </template>

      <!-- BULK ACTIONS -->
      <template #_bulk="{ count, ids }">
        <VpBulkAction
          label="Delete Groups"
          :icon="$options.icons.Delete"
          :confirm="`Are you sure you want to delete ${count} groups?`"
          @click="
            checkPermission('CONTACT', 'delete', () => {
              deleteBulkGroups(ids);
            })
          "
        />
      </template>
    </VpList>
  </VpListLayout>
</template>
<script>
import TagsDisplay from "components/src/tags/display.vue";
import TagsInput from "components/src/tags/input.vue";
import FunnelDisplay from "components/src/funnel/display.vue";
import FunnelInput from "components/src/funnel/input.vue";
import whatsAppGroups from "@/graph/crm/whatsAppGroup/collection.gql";

import { mapGetters } from "vuex";
import move from "graph/contact/move.gql";
import { Delete } from "icons/icons.js";

export default {
  whatsAppGroups,
  components: {
    FunnelInput,
    TagsDisplay,
    TagsInput,
    FunnelDisplay,
  },
  icons: {
    Delete,
  },

  props: {
    id: [Number, String],
    listParams: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filters: {
        funnelId: null,
        tagIds: null,
        name: null,
        duration: null,
      },
      columns: [
        { name: "profilePicture", label: "Photo", fix: true, type: "string" },
        { name: "name", label: "Name", type: "string" },
        { name: "funnel", type: "string" },
        { name: "tags", type: "string" },
        {
          name: "participantsCount",
          label: "Members",
          fix: true,
          type: "string",
        },
        { name: "creation", label: "Created", fix: true, type: "date" },
      ],
    };
  },

  computed: {
    ...mapGetters({
      checkPermission: "user/checkPermission",
    }),
  },

  methods: {
    link(group) {
      return {
        name: "whatsapp-group",
        params: {
          id: group.id,
        },
      };
    },
    deleteBulkGroups(ids) {
      this.$mutate(move, {
        id: ids,
        action: "delete",
      }).then(() => this.$refs.list.refresh());
    },
    applyDate(dates) {
      this.filters.duration = {
        start: dates[0] ?? String(Math.floor(new Date().getTime() / 1000)),
        end: dates[1] ?? String(Math.floor(new Date().getTime() / 1000)),
        preset: "custom",
      };
    },
  },
};
</script>
