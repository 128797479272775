<template>
  <div class="vp-h-full">
    <!-- Header Buttons -->
    <VpHeaderActions>
      <VpAddItem feature="CONTACT" @click="onAdd" />
    </VpHeaderActions>
    <VpList
      :columns="columns"
      endpoint="contactActivities"
      :request-payload="{ query: $options.contactActivities }"
      cache="contactActivities"
      :filters.sync="filters"
      :params="{ contactId: id }"
      class="vp-h-full"
      ref="list"
      title="Activity"
      @rowClick="editItem($event.note)"
    >
      <template #filters>
        <VpField label="Type">
          <VpInput>
            <VpSelect v-model="filters.type" :options="$options.typeOptions" />
          </VpInput>
        </VpField>
      </template>
      <template #note="{ item: { note } }">
        <p v-html="note.note" class="vp-line-clamp-5 vp-break-words"></p>
      </template>
      <template #createdAt="{ item: { note } }">
        <VpDatetime
          class="vp-text-gray-500 vp-text-sm"
          :value="note.createdAt"
        />
      </template>
      <template #action="{ item: { note } }">
        <div class="vp-flex vp-flex-nowrap">
          <VpActionButton
            :icon="$options.icons.Copy"
            class="button--primary button--sm"
            @click.native="copyNote(note)"
          />
        </div>
      </template>
    </VpList>
    <ActivityModal @modal-close="$refs.list.refresh()" />
  </div>
</template>

<script>
import { Editor } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import ActivityModal from "./_ActivityModal";
import { Copy } from "icons/icons.js";
import contactActivities from "graph/contact/activity/collection.gql";

export default {
  contactActivities,
  icons: {
    Copy,
  },
  typeOptions: [
    {
      label: "Note",
      value: "note",
    },
  ],
  props: {
    id: [String, Number],
  },
  components: {
    ActivityModal,
  },
  data() {
    return {
      filters: { type: "note" },
    };
  },
  computed: {
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "note", type: "string" },
        { name: "createdAt", type: "string" },
        { name: "action", type: "string", fix: true, rowClick: false },
      ];
    },
  },
  methods: {
    copyNote({ id, note }) {
      const editor = new Editor({
        content: note,
        extensions: [StarterKit],
      });
      this.$vayu.copy(editor.getText());
      this.$track("Clicked Contact Activity Copy", {
        "Contact Activity Id": id,
        "Contact Activity Note": note,
      });
      editor.destroy();
      this.$vayu.notify({
        title: "Copied",
        message: "Note has been copied to your clipboard.",
      });
    },
    onAdd() {
      this.openModal({ id: "+", note: "" });
    },
    editItem({ id, note }) {
      this.openModal({ id, note });
    },
    openModal(data) {
      this.$vayu.modal.open("contact-activity-modal", {
        scope: { ...data, contactId: this.id },
      });
    },
  },
};
</script>
