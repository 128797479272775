import List from "@/views/settings/integrations/List.vue";
import SalesForce from "@/views/settings/integrations/salesforce/item.vue";
import FacebookPixel from "@/views/settings/integrations/facebook-pixel/item.vue";
import GoogleAnalytics from "@/views/settings/integrations/google-analytics/item.vue";
import GoogleAnalyticsFour from "@/views/settings/integrations/google-analytics4/item.vue";
import HubSpot from "@/views/settings/integrations/hubspot/item.vue";
// import Privy from "@/views/settings/integrations/privy/item.vue";
// import TawkTo from "@/views/settings/integrations/tawk-to/item.vue";
// import Drift from "@/views/settings/integrations/drift/item.vue";
// import HelloBar from "@/views/settings/integrations/hello-bar/item.vue";
import WhatsAppCloudApi from "@/views/settings/integrations/whatsapp-cloud-api/item.vue";
import Zapier from "components/src/zapier/item.vue";
import Google from "@/views/settings/integrations/google/item.vue";
import Zoho from "@/views/settings/integrations/zoho/item.vue";
import Odoo from "@/views/settings/integrations/odoo/item.vue";

export default [
  {
    path: "integrations",
    component: List,
    name: "integrations",
    meta: {
      label: "Integrations",
      breadcrumbs: ["home", "settings", "integrations"],
    },
  },
  {
    path: "integrations/salesforce",
    name: "salesforce",
    component: SalesForce,
    meta: {
      label: "SalesForce",
      breadcrumbs: ["home", "settings", "integrations", "salesforce"],
    },
  },
  {
    path: "integrations/facebook-pixel",
    name: "facebook-pixel",
    component: FacebookPixel,
    meta: {
      label: "Facebook Pixel",
      breadcrumbs: ["home", "settings", "integrations", "facebook-pixel"],
    },
  },
  {
    path: "integrations/google-analytics",
    name: "google-analytics",
    component: GoogleAnalytics,
    meta: {
      label: "Google Analytics",
      breadcrumbs: ["home", "settings", "integrations", "google-analytics"],
    },
  },
  {
    path: "integrations/google-analytics-4",
    name: "google-analytics-4",
    component: GoogleAnalyticsFour,
    meta: {
      label: "Google Analytics 4",
      breadcrumbs: ["home", "settings", "integrations", "google-analytics-4"],
    },
  },
  {
    path: "integrations/hubspot",
    name: "hubspot",
    component: HubSpot,
    meta: {
      label: "HubSpot",
      breadcrumbs: ["home", "settings", "integrations", "hubspot"],
    },
  },
  // {
  //   path: "integrations/privy",
  //   name: "privy",
  //   component: Privy,
  //   meta: {
  //     label: "Privy Ecommerce Marketing",
  //     breadcrumbs: ["home", "settings", "integrations", "privy"],
  //   },
  // },
  // {
  //   path: "integrations/tawk",
  //   name: "tawk",
  //   component: TawkTo,
  //   meta: {
  //     label: "Tawk.to: Live Chat",
  //     breadcrumbs: ["home", "settings", "integrations", "tawk"],
  //   },
  // },
  // {
  //   path: "integrations/drift",
  //   name: "drift",
  //   component: Drift,
  //   meta: {
  //     label: "Drift: Live Chat",
  //     breadcrumbs: ["home", "settings", "integrations", "drift"],
  //   },
  // },
  // {
  //   path: "integrations/hellobar",
  //   name: "hellobar",
  //   component: HelloBar,
  //   meta: {
  //     label: "Hello bar",
  //     breadcrumbs: ["home", "settings", "integrations", "hellobar"],
  //   },
  // },
  {
    path: "integrations/whatsapp-cloud-api",
    name: "whatsapp-cloud-api",
    component: WhatsAppCloudApi,
    meta: {
      label: "WhatsApp Cloud API",
      breadcrumbs: ["home", "settings", "integrations", "whatsapp-cloud-api"],
    },
  },
  {
    path: "integrations/zapier",
    name: "zapier",
    component: Zapier,
    meta: {
      label: "Zapier",
      breadcrumbs: ["home", "settings", "integrations", "zapier"],
    },
  },
  {
    path: "integrations/google",
    name: "google",
    component: Google,
    meta: {
      label: "Google",
      breadcrumbs: ["home", "settings", "integrations", "google"],
    },
  },
  {
    path: "integrations/zoho",
    name: "zoho",
    component: Zoho,
    meta: {
      label: "Zoho",
      breadcrumbs: ["home", "settings", "integrations", "zoho"],
    },
  },
  {
    path: "integrations/odoo",
    name: "odoo",
    component: Odoo,
    meta: {
      label: "Odoo",
      breadcrumbs: ["home", "settings", "integrations", "odoo"],
    },
  },
];
