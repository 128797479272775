<template>
  <VpVueForm
    :fields="fields"
    :id="id"
    class="vp-p-6"
    :save="save"
    :get="get"
    :del="handleDelete"
    width="400px"
    collection-cache="contacts"
    :track="$options.track"
    track-prefix="Contact"
    feature="CONTACT"
  >
    <template #default="{ state }">
      <div class="vp-grid vp-grid-cols-12 vp-gap-1">
        <div class="vp-col-span-3">
          <VpField label="Salutation">
            <VpSelectRequest v-model="state.salutation" :request="titles" />
          </VpField>
        </div>
        <div class="vp-col-span-9">
          <VpField label="Name">
            <VpInput>
              <VpTextBox v-model="state.name" />
            </VpInput>
          </VpField>
        </div>
      </div>

      <VpField label="Funnel">
        <FunnelInput v-model="state.funnelId" @onAddNew="onAddNewFunnel" />
      </VpField>

      <VpField label="Tags">
        <TagsInput type="contact" v-model="state.tagIds" />
      </VpField>

      <VpField label="Tier" v-if="tierOptions.length > 0">
        <VpDropdown
          v-bind="$attrs"
          placeholder="Apply Tier"
          :options="tierOptions"
          v-model="state.tierId"
        />
      </VpField>

      <VpField rules="email" type="email" label="Email" name="email">
        <VpInput>
          <VpTextBox v-model="state.email" />
        </VpInput>
      </VpField>

      <VpField label="Company">
        <VpInput>
          <VpTextBox v-model="state.company" />
        </VpInput>
      </VpField>

      <VpField label="Gender">
        <GenderInput class="vp-my-2" v-model="state.gender" />
      </VpField>

      <VpField label="City">
        <VpInput>
          <VpTextBox v-model="state.city" />
        </VpInput>
      </VpField>

      <VpField label="Address">
        <VpTextArea :rows="4" v-model="state.address" />
      </VpField>

      <VpField label="Notes">
        <VpTextArea :rows="4" v-model="state.note" />
      </VpField>

      <DeleteContactModal
        :selected-contacts-count="1"
        :selected-contacts-id="[id]"
        @contacts-deleted="onContactDelete"
      />
    </template>
  </VpVueForm>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import FunnelInput from "../funnel/input.vue";
import TagsInput from "../tags/input.vue";

import get from "graph/contact/get.gql";
import upsert from "graph/contact/upsert.gql";
import getTiers from "graph/tier/collection.gql";
import salutations from "./salutations.gql";
import GenderInput from "../form/gender-input.vue";
import DeleteContactModal from "./_delete-modal.vue";

export default {
  components: {
    GenderInput,
    TagsInput,
    FunnelInput,
    DeleteContactModal,
  },

  track: {
    id: "Id",
    salutation: {
      key: "Salutation",
      value: (item) => item?.label ?? "",
    },
    name: {
      key: "Name",
      value: (item) => item || "",
    },
    funnel: {
      key: "Funnel",
      value: (item) => item?.name ?? "",
    },
    tags: {
      key: "Tags",
      value: (tags) =>
        tags.length ? JSON.stringify(tags.map((tag) => tag.name)) : "[]",
    },
    email: {
      key: "Email",
      value: (item) => item || "",
    },
    company: {
      key: "Company",
      value: (item) => item || "",
    },
    gender: {
      key: "Gender",
      value: (item) => item || "",
    },
    city: {
      key: "City",
      value: (item) => item || "",
    },
    address: {
      key: "Address",
      value: (item) => item || "",
    },
    note: {
      key: "Note",
      value: (item) => item || "",
    },
  },

  props: {
    id: [String, Number],
    refreshFeature: Function,
  },

  data() {
    return {
      fields: [
        "name",
        "salutation",
        "company",
        "funnelId",
        "tagIds",
        "email",
        "gender",
        "address",
        "note",
        "city",
        "tierId",
      ],
      tierOptions: [],
    };
  },
  computed: {
    ...mapGetters({
      contactProfileTimestamp: "user/contactProfileTimestamp",
    }),
  },

  methods: {
    ...mapMutations({
      setContactProfileTimestamp: "user/setContactProfileTimestamp",
    }),

    onAddNewFunnel() {
      this.$router.push({
        name: "funnel",
        params: {
          id: "+",
        },
      });
    },

    handleDelete() {
      return new Promise((resolve) => {
        this.$vayu.modal.open("delete-contact");
        resolve(true);
      });
    },

    titles() {
      return this.$query(salutations, {}).then((res) => {
        return res.data.salutation.data.map((item) => {
          return {
            value: item.id,
            label: item.label,
          };
        });
      });
    },

    get() {
      if (this.contactProfileTimestamp != this.$route.query.timestamp) {
        this.$cache.evict({
          id: "ROOT_QUERY",
          fieldName: "contact",
        });
        this.setContactProfileTimestamp(this.$route.query.timestamp);
      }
      const apis = Promise.all([
        this.$query(get, {
          id: this.id,
        }),
        this.$query(getTiers, { page: -1 }),
      ]);

      return apis.then((res) => {
        this.tierOptions = res[1].data.tiers.data.map((tier) => {
          return {
            id: tier.id,
            label: tier.name,
            color: tier.color,
            value: tier.id,
          };
        });
        const result = res[0].data.contact;
        return {
          values: {
            ...result,
            salutation: result.salutation?.id,
            funnelId: result.funnel?.id,
            tierId: result.tier?.id,
            tagIds: result.tags?.map((item) => item.id),
          },
          res: res[0].data,
        };
      });
    },

    save(id, data) {
      /**
       * TODO:
       * Gender is saved in capital cases for older contacts.
       * Graphql has strict type so need lowercases.
       * Remove once data is migrated.
       */
      if (data.gender) {
        data.gender = data.gender.toLowerCase();
      }

      return this.$mutate(upsert, {
        id,
        input: {
          ...data,
          salutation: parseInt(data.salutation),
          tierId: data.tierId,
        },
      }).then(() => {
        this.$router.push({ name: "contacts" });
      });
    },

    onContactDelete() {
      this.$router.push({ name: "contacts" });
    },
  },
};
</script>
