<template>
  <svg viewBox="0 0 24 24">
    <path fill="currentColor" d="M14 19h4V5h-4M6 19h4V5H6v14Z"></path>
  </svg>
</template>

<script>
export default {
  name: "MdiPause",
};
</script>
